import React, { Component } from 'react';
import { Container, Typography } from '@material-ui/core';
import TitleStyle from '../TitleStyle';



import { PostData } from '../../api/service';
import Gallery from 'react-grid-gallery';



const IMAGES =
[{
        src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
        thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        isSelected: true,
        caption: "After Rain (Jeshu John - designerspics.com)"
},
{
        src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
        thumbnail: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        tags: [{value: "Ocean", title: "Ocean"}, {value: "People", title: "People"}],
        caption: "Boats (Jeshu John - designerspics.com)"
},
{
        src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
        thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
}]


class GalleryPage extends Component {

    state = {
        data: [],
        isLoaded: false
    }


    componentDidMount(){
        this.loadData()
    }

    loadData = () => {
        PostData(`/getgallery`, {
            dept: this.props.data.dept_code,
            type: 'DEPARTMENT'
        }).then((resp) => {
            this.setState({
                data: resp,
                isLoaded: true
            })
        }).catch((err) => {
            console.log(`Error`, err)
        })
    }

    render() {

        const onInit = () => {
            console.log('lightGallery has been initialized');
        };

        const gdata = [
            '/gallery/images/gal1.jpg',
            '/gallery/images/gal2.jpg',
            '/gallery/images/gal3.jpg',
            '/gallery/images/gal4.jpeg',
            '/gallery/images/gal5.jpg',
        ]

        return (
            <div>
                <Container>
                    <TitleStyle
                        title={`Depertmental Gallery`}
                    />

                    <br />

                    <Typography
                        dangerouslySetInnerHTML={{ __html: this.props.data.gallery }}
                        style={{ textAlign: 'justify' }}>

                    </Typography>

                    {!this.state.isLoaded && `Loading Gallery...Please wait`}


                    {this.state.isLoaded && Array.isArray(this.state.data) && this.state.data.length == 0 && `No albums to view`}
                    {this.state.isLoaded && Array.isArray(this.state.data) && this.state.data.length > 0  && <div className="row">
                        {this.state.data.map((el, index) => {
                            let img = [];
                            if(Array.isArray(el.upload_info)){
                                img = el.upload_info.map((el1) => ({
                                    src: el1.url,
                                    thumbnail: el1.url
                                }))
                            }
                            console.log(img)
                            return (
                                <div style={{marginBottom: '40px'}} className="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                                    <h3 style={{textTransform: 'uppercase'}}>{el.caption}</h3>
                                    <Gallery images={img}/>
                                </div>
                                )
                        }
                            
                        )}
                        </div>}

                   

                </Container>
            </div>
        );
    }
}

export default GalleryPage;