import React, { Component } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import BreadCumb from "../component/Department/BreadCumb";
import NotificationDescription from "../component/Notifications/NotificationDescription";
import { PostData } from "../api/service";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Table, TableBody, TableCell, TableHead, TableRow, Container, Typography} from '@material-ui/core'
export default class NccContainer extends Component {

  // state = {
  //   data: [],
  //   isLoaded: false
  // }

  // __loadFile = () => {
  //   let d = {
  //     dept_code: "PRAG",
  //     type: "NIRF"
  //   }

  //   PostData(`/getnoticesbytypedept`, d)
  //     .then((resp) => {
  //       this.setState({
  //         data: resp,
  //         isLoaded: true,
  //       })
  //     })
  // }

  // componentDidMount() {
  //   this.__loadFile();
  // }

  render() {
    return (
      <div>
        <BreadCrumbOwn title="National Cadet Corps" />
        <Container style={{ minHeight: '80vh' }}>
          <br />


         


        </Container>
      </div>
    );
  }
}
