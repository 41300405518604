import React, { Component } from "react";
import { MDBContainer, Badge } from "mdbreact";
import TitleStyle, { SecondaryStyle } from "../TitleStyle";
import { PostData, PostDataExtra, GetData } from "../../api/service";
import {
  Typography,
  Divider,
  Button,
  Grid,
  Container,
  withStyles,
} from "@material-ui/core";
import history from "../../history";

const allStyles = (theme) => ({
  teacherCard: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#f9f9f9",
    marginBottom: "20px",
    padding: "5px",
    // height: "35vh",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "100%",
    },
  },
  leftSide: {
    flexBasis: "85%",
    padding: "20px",
  },
  rightSide: {
    flexBasis: "15%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
});

class TeacherCard extends Component {
  state = {
    dp: "",
    cv: "",
    isLoading: false,
    isLoaded: false,
  };

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  showFile = (blob) => {
    var blob1 = this.b64toBlob(blob.data, blob.mime, 512);
    var blobUrl = URL.createObjectURL(blob1);

    var link = document.createElement("a");
    link.href = blobUrl;
    link.download = `${blob.type}_${Math.random() * 1000}.pdf`;
    link.click();
  };

  componentDidMount() {
    // let data = this.props.dp;
    // if (Array.isArray(data) && data.length > 0) {
    //   PostData(`/getfiledata`, data[0])
    //   .then((resp) => {
    //     //console.log(resp)
    //     this.setState({
    //       dp: resp,
    //       isLoaded: true
    //     })
    //   })

    // }
    let data = this.props.dp;
    console.log(
      "🚀 ~ file: FacultyPage.js ~ line 107 ~ TeacherCard ~ componentDidMount ~ data",
      data
    );
    if (Array.isArray(data) && data.length > 0) {
      this.setState({
        dp: data[0],
        isLoaded: true,
      });
    }
  }

  downloadCv = () => {
    let cv = this.props.cv;
    this.setState({
      isLoading: true,
    });
    if (Array.isArray(cv) && cv.length > 0) {
      PostData(`/getfiledata`, cv[0]).then((resp) => {
        //console.log(resp)
        this.showFile(resp);
        this.setState({
          isLoading: false,
        });
      });
    }
  };

  render() {
    const { styles } = this.props;
    //  let i = '';
    //  if (this.state.isLoaded) {

    //    i = `data:${this.state.dp.mime};base64,${this.state.dp.data}`;

    //  }

    return (
      <div className={styles.teacherCard}>
        <div className={styles.rightSide}>
          {this.state.isLoaded ? (
            <img
              alt=""
              src={this.state.dp}
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
                boxShadow: "1px 2px 10px rgba(0,0,0,0.3)",
              }}
            />
          ) : (
            <img
              alt=""
              src={"/images/faculty/default.jpg"}
              style={{
                width: "100%",
                boxShadow: "1px 2px 10px rgba(0,0,0,0.3)",
              }}
            />
          )}
        </div>
        <div className={styles.leftSide}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12} sm={12} md={12}>
              <Typography variant="h6">
                <i>{this.props.name}</i>
              </Typography>
              <Typography variant="subtitle2">
                {this.props.designation}
              </Typography>
              
              <Typography variant="caption">
                {this.props.experience != ""
                  ? `Experience: ${this.props.experience}`
                  : null}{" "}
                <br />
                {this.props.date_of_joining != ""
                  ? `Service Period: ${this.props.date_of_joining}`
                  : null}
                {this.props.date_of_leaving != "" &&
                  this.props.date_of_leaving != null &&
                  ` - ${this.props.date_of_leaving}`}
              </Typography>
              
              
            </Grid>
            
          </Grid>

          
        </div>
      </div>
    );
  }
}

// To directly call from MIS
// class FacultyPage extends Component {

//   state = {
//     facultyData: [],
//     isLoaded: false,
//     dept_code: this.props.data.dept_code
//   }

//   componentDidMount() {

//     let d = {
//       dept_code: this.state.dept_code,
//       type: 'FACULTY'
//     }

//     PostDataExtra(`/getfacultyforwebsite`, d)
//       .then((resp) => {
//         this.setState({
//           facultyData: resp,
//           isLoaded: true
//         })
//       })
//   }

//   render() {
//     return (
//       <div>

//         <Container >
//           <TitleStyle
//             title={`Faculty`}
//           />
//           <br />

//           {this.state.isLoaded ?
//             <div>
//               {this.state.facultyData.map((el, index) =>
//                 <TeacherCard
//                   key={index}
//                   {...el}
//                   styles={this.props.classes}
//                 />
//               )}
//             </div>
//             : `Loading . . . . `

//           }

//         </Container>

//       </div>
//     )
//   }
// }

// to call from the website itself with Faculty profile
class FormerFacultyPage extends Component {
  state = {
    facultyData: [],
    isLoaded: false,
    dept_code: this.props.data.dept_code,
  };

  componentDidMount() {
    let d = {
      dept_code: this.state.dept_code,
      //type: 'FORMER_FACULTY',
      type: "FORMER_FACULTY",
    };

    PostData(`/getemployeebytype`, d).then((resp) => {
      this.setState({
        facultyData: resp,
        isLoaded: true,
      });
    });
  }

  render() {
    return (
      <div>
        <Container>
          <TitleStyle title={`Former Faculty Members`} />
          <br />

          {this.state.isLoaded ? (
            <div>
              <Grid container spacing={2}>
                {this.state.facultyData.map((el, index) => (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TeacherCard
                      key={index}
                      {...el}
                      styles={this.props.classes}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : (
            `Loading . . . . `
          )}
        </Container>
      </div>
    );
  }
}

export default withStyles(allStyles)(FormerFacultyPage);
