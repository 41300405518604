import React, { Component } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import BreadCumb from "./../Department/BreadCumb";
import NotificationDescription from "../Notifications/NotificationDescription";
import { Container, Card, CardActions, CardContent, Grid, List, ListItem, Typography } from "@material-ui/core";
import { PostData } from "../../api/service";

import ShowImage from "../Department/ShowImage";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";
import { Menu, Button } from "antd";
import queryString from 'query-string'

import Gallery from 'react-grid-gallery';
import TitleStyle from "../TitleStyle";

const list = [
    {
        year: 2023,
    },
    {
        year: 2022,
    },
    {
        year: 2021,
    },
    {
        year: 2020,
    },
    {
        year: 2019,
    },
]

export default class DepartmentSingleEvent extends Component {

    state = {
        data: null,
        isLoaded: false,
        year: '',
        photos: [],
        files: []
    }

    __loadFile = (eventId) => {

        this.setState({
            isLoaded: false
        })

        let d = {
            eventId: eventId,

        }

        PostData(`/getsingleeventbyid`, d)
            .then((resp) => {
                if (resp != false) {

                    let photos = [];
                    let files = [];

                    let upload_info = resp.upload_info;
                    if(Array.isArray(upload_info) && upload_info.map((el) => {
                        if(el.fileType == "image"){
                            photos.push(el)
                        }

                        if(el.fileType == "file"){
                            files.push(el)
                        }
                    }))

                    this.setState({
                        data: resp,
                        photos,
                        files,
                        isLoaded: true
                    })
                } else {
                    this.props.history.push("/events")
                }

            })
    }

    componentDidMount() {
        let eventId = queryString.parse(this.props.history.location.search);
        if (eventId) {
            this.__loadFile(eventId.eventId);
        }


    }

    render() {
        return (
            <Container >
                 <TitleStyle
                        title={`Events`}
                    />
                <div className="my-4" style={{ minHeight: '500px' }}>
                    <Container>

                        

                        {this.state.isLoaded && this.state.data != null && (
                            <div>
                                <Typography
                                    variant="h6"
                                    style={{ fontWeight: 700, color: "#2C3E50" }}
                                    gutterBottom
                                >
                                    {this.state.data.title}
                                </Typography>
                                <ShowImage
                                    files={this.state.data.featured_photo}
                                    nos="1"
                                    height="100%"
                                    width="100%"
                                />

                                <br />

                                <Typography
                                    variant="body2"
                                    dangerouslySetInnerHTML={{ __html: this.state.data.description }}
                                >
                                </Typography>


                                {Array.isArray(this.state.files) && this.state.files.length > 0 && <div style={{marginTop: 20}}>
                                    
                                    <Typography
                                    variant="h6"
                                    style={{ fontWeight: 700, fontSize: 14, color: "#2C3E50" }}
                                    gutterBottom
                                >
                                    Files
                                </Typography>

                                <ul>
                                    {this.state.files.map((el,index ) => <li key={index}><a target="_blank" href={el.url}>{el.path}</a></li>)}
                                </ul>
                                   
                                </div>}


                                {Array.isArray(this.state.photos) && this.state.photos.length > 0 && <div style={{marginBottom: 20, height: 800, width: "100%"}}>
                                    <Typography
                                    variant="h6"
                                    style={{ fontWeight: 700, fontSize: 14, color: "#2C3E50" }}
                                    gutterBottom
                                >
                                    Photos
                                </Typography>
                                    <Gallery  maxRows={10} style={{width: "100%", height: 800}} images={[...this.state.photos.map((el1) => ({
                                        src: el1.url,
                                        thumbnail: el1.url,
                                        width: 300,
                                        height: 450
                                    }))]} />
                                </div>}

                                


                               

                               
                            </div>)}
                        <br />
                       


                </Container>
            </div>
      </Container >
    );
    }
}
