import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardHeader } from "mdbreact";
import HomePageNews from "./News/HomePageNews";
import { GetData } from "../api/service";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { Button, Divider, Modal } from "antd";
import moment from "moment";
import { DownloadOutlined } from "@ant-design/icons";
import { History } from "./PanduAbout/AboutDescription";
import StickyButton from "./StickyButton";
import Announcements from "./Announcements";
import RecentNews from "./RecentNews";
import HomePageEvents from "./News/HomePageEvents";
import VideoHomePage from "./Video/VideoHomePage";
import HomePageCertificates from "./News/HomePageCertificates";
import HomePageFeatured from "./Essentials/HomePageFeatured";
import StudentsCornerHomePage from "./StudentsCorner/StudentsCornerHomePage";
import KnowYourFaculty from "./KnowYourFaculty/KnowYourFaculty";
import Hightlights from "./Hightlights";
import CollegeMap from "./CollegeMap/CollegeMap";
import ProminentSection from "./HomepageSections/ProminentSection";
export default class HomePagePandu extends Component {
  state = {
    events: [],
    isLoaded: false,
    showHoliday: false,
    holidayData: {},
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    GetData(`/getupcomingevents`).then((resp) => {
      console.log("events", resp);
      this.setState((prevState) => {
        return {
          ...prevState,
          events: resp,
          isLoaded: true,
        };
      });
    });

    GetData(`/checkiftodayisholiday`).then((resp) => {
      if (resp != null) {
        this.setState(
          (prevState) => {
            return {
              ...prevState,
              showHoliday: resp.status,
              holidayData: {
                ...resp.data,
              },
            };
          },
          () => {
            this.showHoliday();
          }
        );
      }
    });
  };

  showHoliday() {
    if (this.state.showHoliday) {
      Modal.info({
        title: this.state.holidayData?.title,
        content: (
          <div>
            <div>
              {Array.isArray(this.state.holidayData.upload_info) &&
                this.state.holidayData.upload_info.map((el) => (
                  <img src={el.url} style={{ width: "100%" }} />
                ))}
            </div>
            From :{" "}
            {moment(this.state.holidayData?.start_date).format("DD/MM/YYYY")} to{" "}
            {moment(this.state.holidayData?.end_date).format("DD/MM/YYYY")}
            <div
              style={{ margin: 0 }}
              dangerouslySetInnerHTML={{
                __html: this.state.holidayData?.description,
              }}
            ></div>
            {/* <br />
          <br />
          <br />
          {this.state.holidayData?.upload_info?.map((el, index) => <>
            <Button type="primary" icon={<DownloadOutlined />} size="small" onClick={() => window.open(`${el.url}}`)}>File {++index}</Button>&nbsp;
          </>
          )} */}
          </div>
        ),
        onOk() {},
        centered: true,
      });
    }
  }
  render() {
    return (
      <div className="fontFix">
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <RecentNews />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid container>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <div style={{ padding: 20 }}>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: 700, fontSize: 24 }}
                    gutterBottom
                  >
                    About The College
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ textAlign: "justify", fontSize: 17 }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pragjyotish College, founded on 1st September 1954, is the first college established in Guwahati, the largest metropolis in Northeast India, after Independence. Guided by the motto “तेजस्विनावधीतमस्तु” (May our study make us illumined) over several decades, the institute, at present, is a well-known premier postgraduate college imparting higher education in as many as 27 departments which includes 7 postgraduate programmes and has around 4000 regular students making it one of the largest educational institutes of the region in terms of number of admissions. The college believes in all-round development of its students...{" "}
                    <Link to="/aboutus/about">Read More</Link>
                    <VideoHomePage viewTitle={false} />
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <div style={{ padding: 10 }}>
                  <HomePageCertificates viewTitle={false} />

                  <div>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3580.8906108239025!2d91.72716561109507!3d26.16769179161447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5bb3f423e39b%3A0x1adac45f6116e482!2sPragjyotish%20College!5e0!3m2!1sen!2sin!4v1700839661359!5m2!1sen!2sin"
                      width="100%"
                      height="200"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        <div>
          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div style={{ paddingLeft: 10, paddingRight: 10, marginTop: '-10px' }}>
               
                <Divider
                  orientation="left"
                  style={{
                    fontWeight: 700,
                    fontSize: 20,
                    borderColor: "#ABB2B9",
                  }}
                >
                  Principal's Message
                </Divider>
                <div style={{ width: "100%", position: "relative" }}>
                  <img
                    src="/images/principal-prag-new-image.jpeg"
                    className="img-wrap-image"
                  />

                  <div className="p-warp-image">
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{ textAlign: "justify", padding: 10 }}
                    >
                     It is a matter of utmost privilege and pleasure to extend a warm and heartfelt welcome to all students aspiring to pursue their education across various programs offered by this premier higher educational institution in the Northeast region. Pragjyotish College began with a humble initiative by a group of visionary leaders from the area to educate and generate learned citizens for the upliftment of the society. Since its inception, the college has made remarkable progress and stands today as one of the most distinguished co-educational institution in the entire Northeast region.The institution today is a post graduate college with different programs ranging from higher secondary education to postgraduate studies along with professional studies like mass communication & journalism, tourism management and business management.....
                    </Typography>

         

                    <div style={{ textAlign: "right" }}>
                      <Link to="/administrations/principal">
                        <Button type="primary">Know More</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={4}
                  md={4}
                  style={{ backgroundColor: "#E67E27" }}
                >
                  <div style={{ padding: 10 }}>
                    <Divider
                      orientation="left"
                      style={{
                        fontWeight: 700,
                        fontSize: 20,
                        borderColor: "white",
                        color: "white",
                      }}
                    >
                      OUR MOTTO
                    </Divider>
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{ textAlign: "center", color: "white" }}
                    >
                     तेजस्विनावधीतमस्तु <br />
                      Tejasvinavadhitamastu <br />
                      (May our study make us illumined)
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={4}
                  md={4}
                  style={{ backgroundColor: "#3598DB" }}
                >
                  <div style={{ padding: 10 }}>
                    <Divider
                      orientation="left"
                      style={{
                        fontWeight: 700,
                        fontSize: 20,
                        borderColor: "white",
                        color: "white",
                      }}
                    >
                      OUR MISSION
                    </Divider>
                    <Typography variant="body2" style={{ color: "white" }}>
                      <ul style={{ marginLeft: "-25px" }}>
                        <li>
                          To make teachers and the taught partners in the
                          learning process.
                        </li>
                        <li>
                          To promote a student friendly atmosphere for
                          encouraging them to be self-reliant and
                          self-employable.
                        </li>
                      </ul>
                    </Typography>
                    <div style={{ textAlign: "right" }}>
                      <Link to="/aboutus/vision">
                        <Button size="small" type="dashed">
                          Know More
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={4}
                  md={4}
                  style={{ backgroundColor: "#E74C3C" }}
                >
                  <div style={{ padding: 10 }}>
                    <Divider
                      orientation="left"
                      style={{
                        fontWeight: 700,
                        fontSize: 20,
                        borderColor: "white",
                        color: "white",
                      }}
                    >
                      OUR VISION
                    </Divider>
                    <Typography variant="body2" style={{ color: "white" }}>
                      To fulfill the visionary aspirations of the regional youth
                      segment through a process of vibrant and continuous
                      innovations and initiatives in multiple spheres of
                      academic as well as professional development, leading to
                      the fullest realisation of the potential of the students.
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div style={{ padding: 20 }}>
                <Divider
                  orientation="left"
                  style={{
                    fontWeight: 700,
                    fontSize: 20,
                    borderColor: "#ABB2B9",
                    marginTop: "-20px",
                  }}
                >
                  Latest Notifications
                </Divider>
                <HomePageNews
                  type="COLLEGE_ACADEMIC_NOTICE"
                  link="/notifications/academic"
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <div style={{ minHeight: 400 }}>
          <br />
          <HomePageEvents />

          <br />
          <br />
        </div>

        <div style={{ backgroundColor: "#EEEEEE" }}>
          <div style={{ padding: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Divider
                  orientation="left"
                  style={{
                    fontWeight: 700,
                    fontSize: 20,
                    borderColor: "#ABB2B9",
                  }}
                >
                  STUDENT NOTICES
                </Divider>
                <HomePageNews
                  type="COLLEGE_ACADEMIC_NOTICE"
                  link="/notifications/academic"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Divider
                  orientation="left"
                  style={{
                    fontWeight: 700,
                    fontSize: 20,
                    borderColor: "#ABB2B9",
                  }}
                >
                  ADMINISTRATIVE NOTICES
                </Divider>
                <HomePageNews
                  type="COLLEGE_ADMIN_NOTICE"
                  link="/notifications/administrative"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Divider
                  orientation="left"
                  style={{
                    fontWeight: 700,
                    fontSize: 20,
                    borderColor: "#ABB2B9",
                  }}
                >
                  TENDERS
                </Divider>
                <HomePageNews
                  type="COLLEGE_TENDER"
                  link="/notifications/tender"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Divider
                  orientation="left"
                  style={{
                    fontWeight: 700,
                    fontSize: 20,
                    borderColor: "#ABB2B9",
                  }}
                >
                  DOWNLOADS
                </Divider>
                <HomePageNews
                  type="COLLEGE_DOWNLOADS"
                  link="/notifications/downloads"
                />
              </Grid>
            </Grid>
          </div>
        </div>

        <div></div>

        {/* <div
          style={{
            width: "100%",
            height: "85vh",
            background:
              "url('/images/bg2.png')",
            backgroundRepeat: "no repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {this.state.isLoaded && <div
            style={{
              background: "linear-gradient(180deg,#000A,#0005,#000A)",
              height: "100%",
              width: "100%",
              // display: "flex",
              // justifyContent: "center",
              padding: "2em 0",
              alignItems: 'center',
            }}
          >
            {Array.isArray(this.state.events) && this.state.events.length > 0 && <div style={{

            }}>
              <StickyButton />
              <AliceCarousel
                items={this.state.events.map((el, index) => <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  marginTop: 40
                }} key={index}>
                  <p
                    style={{
                      color: "white",
                      fontSize: "1.7em",
                      fontWeight: "600",
                      textAlign: "center",
                      marginBottom: "1em",
                    }}
                  >
                    Upcoming Events
                  </p>
                  <br />
                  <br />

                  <div className="hr" style={{ width: '50%' }}></div>
                  <div style={{ margin: "4em 0" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "2em 0",
                      }}
                    >
                      <div className="round-date">{el.date_no} {el.month}</div>
                    </div>
                    <div
                      className=""
                      style={{
                        textAlign: "center",
                        color: "white",
                        fontSize: "1.5em",
                        fontWeight: "600",
                      }}
                    >
                      {el.title}
                    </div>

                  </div>
                  <div className="hr" style={{ marginTop: "1em", width: '50%' }}>&nbsp;</div>
                </div>)}
                responsive={{
                  0: { items: 1 },
                  1024: { items: 1 }
                }}
                autoPlayInterval={2000}
                autoPlayDirection="rtl"
                autoPlay={true}
                //fadeOutAnimation={true}
                mouseDragEnabled={true}
                playButtonEnabled={false}
                keysControlDisabled={true}
                dotsDisabled={true}
                buttonsDisabled={true}
              />


            </div>}
          </div>}
        </div> */}

        {/* <MDBRow>
          <RecentNews />
        </MDBRow> */}

        {/* <div style={{width: '100%', height:'20%', backgroundColor: 'white', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <button style={{display: 'inline-block'}}>Announcements</button>
          <hr style={{display: 'inline-block', backgroundColor: 'black'}} />
          </div> */}
        {/* <Container>
          <div
            style={{
              minHeight: 200,
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            
            <Announcements />
          </div>
        </Container>
    

        <div className="paper-home-page-div">
          <Container>
            <Paper className="paper-home-page-about">
              <div
                style={{
                  flexBasis: "40%",
                  display: "block",
                  minHeight: "100%",
                }}
              >
                <img
                  src="/images/prag-small-overview.jpg"
                  style={{ width: "100%" }}
                />
              </div>
              <div style={{ flexBasis: "60%", padding: 20 }}>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 700 }}
                  gutterBottom
                >
                  About The College
                </Typography>
                <Typography variant="body2" style={{ textAlign: "justify" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pragjyotish
                  College, established on 1st September 1954, seven years after
                  Independence, became a beacon of learning and a symbol of
                  aspirations for the common people of Assam, raring to build a
                  new nation. Pandit Tirthanath Sarma, eminent scholar and
                  litterateur, responded to and actively participated in the
                  nation building by taking charge as the founder Principal of
                  Pragjyotish College. From its modest inception as an arts
                  college, Pragjyotish College has now developed into one of the
                  premier institutions of higher education in Guwahati. At
                  present, it is a well-known full-fledged under-graduate
                  college imparting higher education in multiple streams
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Situated on
                  the western bank of the Bharalu, a rivulet, in the western
                  part of Guwahati, Pragjyotish College . . .{" "}
                  <Link to="/aboutus/about">Read More</Link>
                </Typography>
              </div>
            </Paper>
          </Container>
        </div> */}

        <br />
        <br />

        <div
          className="div-container-icon-shortcut"
        >
          <div >
            <div className="paper-adm-homepage">
              <div className="paper-adm-homepage-div">
                <Link
                  to="/administrations/gb"
                  className="paper-adm-homepage-link"
                >
                  <div className="verticle-card-home">
                    <div>
                      <img
                        src="/images/icons/committee-icon.png"
                        style={{ width: 50, backgroundColor: "blue" }}
                      />
                    </div>
                    <div>Administration</div>
                  </div>
                </Link>
              </div>
              <div className="paper-adm-homepage-div">
                <Link to="/departments" className="paper-adm-homepage-link">
                  <div className="verticle-card-home">
                    <div>
                      <img
                        src="/images/icons/department-icon.png"
                        style={{ width: 50, backgroundColor: "blue" }}
                      />
                    </div>
                    <div>Departments</div>
                  </div>
                </Link>
              </div>
              <div className="paper-adm-homepage-div">
                <Link to="/iqac/strategies" className="paper-adm-homepage-link">
                  <div className="verticle-card-home">
                    <div>
                      <img
                        src="/images/icons/iqac-icon.png"
                        style={{ width: 50, backgroundColor: "blue" }}
                      />
                    </div>
                    <div>IQAC</div>
                  </div>
                </Link>
              </div>
              <div className="paper-adm-homepage-div">
                <Link to="/committees" className="paper-adm-homepage-link">
                  <div className="verticle-card-home">
                    <div>
                      <img
                        src="/images/icons/committee-new.png"
                        style={{ width: 50, backgroundColor: "blue" }}
                      />
                    </div>
                    <div>Committees</div>
                  </div>
                </Link>
              </div>
              <div className="paper-adm-homepage-div">
                <Link to="/student-wings" className="paper-adm-homepage-link">
                  <div className="verticle-card-home">
                    <div>
                      <img
                        src="/images/icons/student-wings.png"
                        style={{ width: 50, backgroundColor: "blue" }}
                      />
                    </div>
                    <div>Student Wings</div>
                  </div>
                </Link>
              </div>
              
            </div>
          </div>

          {/* <br />
          <Container>
            <div className="paper-prince-homepage">
              <div className="paper-prince-div-1">
                <img
                  src="/images/principal-prag.jpeg"
                  style={{ width: "95%" }}
                />
              </div>
              <div className="paper-prince-div-2">
                <div style={{ width: "100%" }}>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: 700 }}
                    gutterBottom
                  >
                    Principal's Message
                  </Typography>

                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ textAlign: "justify" }}
                  >
                    It is a matter of utmost privilege and pleasure to extend a
                    warm and heartiest welcome to all the students desiring to
                    take admission in different programs being offered in this
                    premier higher educational institution of the NE region.
                    Pragjyotish College had made its humble beginning, under the
                    initiative of a group of visionary leaders of the region.
                    The college has made exemplary strides from its inception
                    till date. Today, it is not only one of the most
                    distinguished undergraduate co-educational institutions in
                    the entire NE region but is also the college of first choice
                    by many, with programs ranging from higher secondary up to
                    post-graduate.......
                  </Typography>

                  <br />

                  <div style={{ textAlign: "right" }}>
                    <Link to="/administrations/principal">
                      <Button type="primary">Know More</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Container> */}
        </div>

        {/* <div
          style={{
            backgroundColor: "#EEEEEE",
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Container>
            <div
              className="paper-homepage-motto"
              style={{ backgroundColor: "#E67E22" }}
            >
              <div
                className="paper-homepage-motto-div"
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  height: "100%",
                  paddingLeft: 10,
                  color: "white",
                  textAlign: "center",
                }}
              >
                OUR MOTTO
              </div>
              <div
                className="paper-homepage-motto-div-1"
                style={{
                  fontWeight: 500,
                  borderRight: "solid 3px #E67E22",
                  padding: 15,
                  backgroundColor: "white",
                }}
              >
                <Typography variant="body2">
                  तेजस्विनावधीतमस्तु
                  <br />
                  Tejasvinavadhitamastu
                  <br />
                  (May our study make us illumined)
                </Typography>
              </div>
            </div>

            <br />

            <div
              className="paper-homepage-motto"
              style={{ backgroundColor: "#3498DB" }}
            >
              <div
                className="paper-homepage-motto-div"
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  height: "100%",
                  paddingLeft: 10,
                  color: "white",
                  textAlign: "center",
                }}
              >
                OUR MISSION
              </div>
              <div
                className="paper-homepage-motto-div-1"
                style={{
                  fontWeight: 500,
                  borderRight: "solid 3px #3498DB",
                  padding: 15,
                  backgroundColor: "white",
                }}
              >
                <Typography variant="body2">
                  <ul style={{ marginLeft: "-25px" }}>
                    <li>
                      To make teachers and the taught partners in the learning
                      process.
                    </li>
                    <li>
                      To promote a student friendly atmosphere for encouraging
                      them to be self-reliant and self-employable.
                    </li>
                    <li>
                      To promote extra-curricular activities simultaneously with
                      curricular activities.
                    </li>
                    <li>
                      To mould the students into socially conscious human
                      beings.
                    </li>
                    <li>
                      To encourage students to think globally and act locally as
                      productive citizens, through the promotion of scientific
                      temper and action.
                    </li>
                    <li>
                      To foster and inculcate moral and spiritual
                      accomplishments amongst the students.
                    </li>
                    <li>
                      To develop a transparent and responsive administration.
                    </li>
                    <li>
                      To undergo self-analysis and self-discovery leading to
                      elimination of bottlenecks in the context of a holistic
                      framework.
                    </li>
                  </ul>
                </Typography>
              </div>
            </div>

            <br />

            <div
              className="paper-homepage-motto"
              style={{ backgroundColor: "#E74C3C" }}
            >
              <div
                className="paper-homepage-motto-div"
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  height: "100%",
                  paddingLeft: 10,
                  color: "white",
                  textAlign: "center",
                }}
              >
                OUR VISION
              </div>
              <div
                className="paper-homepage-motto-div-1"
                style={{
                  fontWeight: 500,
                  borderRight: "solid 3px #E74C3C",
                  padding: 30,
                  backgroundColor: "white",
                }}
              >
                <Typography variant="body2">
                  To fulfill the visionary aspirations of the regional youth
                  segment through a process of vibrant and continuous
                  innovations and initiatives in multiple spheres of academic as
                  well as professional development, leading to the fullest
                  realisation of the potential of the students.
                </Typography>
              </div>
            </div>
          </Container>
        </div> */}

        {/* <div style={{ backgroundColor: '#f9f9f9' }}>

          <Container>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} lg={4} md={4}>
                <div style={{ height: 180, display: 'flex', justifyContent: 'flex-start', backgroundColor: 'none' }}>
                  <div style={{ flexBasis: '40%', backgroundColor: '#2054CD', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                      src="/images/icons/department-icon.png"
                      style={{ width: '100%', }}
                    />
                  </div>
                  <div style={{ padding: 10, paddingLeft: 20, flexBasis: '60%', position: 'relative' }}>
                    <Typography component="h5" variant="h5" style={{ fontWeight: 700 }}>
                      Our Departments
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Our college contains 8 Departments.
                    </Typography>
                    <br />
                    <div style={{ textAlign: 'right', position: 'absolute', bottom: '10px', right: '10px' }}>
                      <Button href="/departments" type="primary" style={{ fontWeight: 700, backgroundColor: '#2054CD', borderColor: '#2054CD' }}>Visit Departments</Button>
                    </div>
                  </div>
                </div>
              </Grid>


              <Grid item xs={12} sm={12} lg={4} md={4}>
                <div style={{ height: 180, display: 'flex', justifyContent: 'flex-start', }}>
                  <div style={{ flexBasis: '40%', backgroundColor: '#2054CD', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                      src="/images/icons/iqac-icon.png"
                      style={{ width: `90%` }}
                    />
                  </div>
                  <div style={{ padding: 10, paddingLeft: 20, flexBasis: '60%', position: 'relative' }}>
                    <Typography component="h5" variant="h5" style={{ fontWeight: 700 }}>
                      IQAC
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Internal Quality Assesment Committee
                    </Typography>
                    <br />
                    <div style={{ textAlign: 'right', position: 'absolute', bottom: '10px', right: '10px' }}>
                      <Button href="/iqac/streategies" type="primary" style={{ fontWeight: 700, backgroundColor: '#2054CD', borderColor: '#2054CD' }}>Visit IQAC</Button>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} lg={4} md={4}>
                <div style={{ height: 180, display: 'flex', justifyContent: 'flex-start', }}>
                  <div style={{ flexBasis: '40%', backgroundColor: '#2054CD', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                      src="/images/icons/committee-icon.png"
                      style={{ width: `90%` }}
                    />
                  </div>
                  <div style={{ padding: 10, paddingLeft: 20, flexBasis: '60%', position: 'relative' }}>
                    <Typography component="h5" variant="h5" style={{ fontWeight: 700 }}>
                      Committees
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Committees associated within this college
                    </Typography>
                    <br />
                    <div style={{ textAlign: 'right', position: 'absolute', bottom: '10px', right: '10px' }}>
                      <Button href="/committees" type="primary" style={{ fontWeight: 700, backgroundColor: '#2054CD', borderColor: '#2054CD' }}>Visit Committees</Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div> */}

        {/* <div className=" py-4" style={{ backgroundColor: '#f9f9f9' }}>
          <Container>
            <p
              style={{
                color: "#b94735",
                fontSize: "2.7em",
                fontWeight: "600",
                textAlign: "center",
                margin: "2em 0 1em 0",
              }}
            >
              Notifications
            </p>

            <div className="row news-container">
              <div className="col-md-4 p-4" >

                <div className="new-card-head">
                  <h2 className="subhead">Student Notification</h2>
                  <Link to="/notifications/academic">
                    <Button>View All</Button>
                  </Link>
                </div>
                <div className="noticecard">
                  <HomePageNews
                    type="COLLEGE_ACADEMIC_NOTICE"
                  />

                </div>

              </div>

              <div className="col-md-4 p-4">

                <div className="new-card-head">
                  <h2 className="subhead">Tenders</h2>
                  <Link to="/notifications/tender">
                    <Button>View All</Button>
                  </Link>
                </div>

                <div className="noticecard">

                  <HomePageNews
                    type="COLLEGE_TENDER"
                  />

                </div>

              </div>

              <div className="col-md-4 p-4">

                <div className="new-card-head">
                  <h2 className="subhead">Announcements</h2>
                  <Link to="/notifications/administrative">
                    <Button>View All</Button>
                  </Link>
                </div>

                <div className="noticecard">

                  <HomePageNews
                    type="COLLEGE_ADMIN_NOTICE"
                  />

                </div>

              </div>

            </div>
          </Container>
        </div> */}

        <br />

        {/* <div style={{ backgroundColor: "#2054CD" }}>
          <Container>
            <br />
            <div className="row my-4">
              <div className="col-md-4 col-sm-12 my-2">
                <Link to="/importantfile">
                  <div
                    className="card p-4 text-center text-dark"
                    style={{ background: "white", cursor: "pointer" }}
                  >
                    <div className="d-flex justify-content-center">
                      <img
                        src="/images/icons/importantfiles.png"
                        style={{
                          height: "100px",
                          width: "100px",
                          margin: "10px",
                        }}
                      />
                    </div>
                    <Typography
                      variant="h6"
                      style={{ color: "#133280", fontSize: 20 }}
                    >
                      Important Informations
                    </Typography>
                    <Typography variant="body2">
                      Including all the relevent files of the College in various
                      contexts...
                    </Typography>
                  </div>
                </Link>
              </div>

              <div className="col-md-4 col-sm-12 my-2">
                <Link to="/organo">
                  <div
                    className="card p-4 text-center text-dark"
                    style={{ background: "white", cursor: "pointer" }}
                  >
                    <div className="d-flex justify-content-center">
                      <img
                        src="/images/icons/diagram.png"
                        style={{
                          height: "100px",
                          width: "100px",
                          margin: "10px",
                        }}
                      />
                    </div>
                    <Typography
                      variant="h6"
                      style={{ color: "#133280", fontSize: 20 }}
                    >
                      Organogram
                    </Typography>
                    <Typography variant="body2">
                      The organogram is an administrative diagram of College
                      ....{" "}
                    </Typography>
                  </div>
                </Link>
              </div>

              <div className="col-md-4 col-sm-12 my-2">
                <Link to="/aboutus/emblem">
                  <div
                    className="card p-4 text-center text-dark"
                    style={{ background: "white", cursor: "pointer" }}
                  >
                    <div className="d-flex justify-content-center">
                      <img
                        src="/images/prag-logo-small.png"
                        style={{
                          height: "100px",
                          width: "100px",
                          margin: "10px",
                        }}
                      />
                    </div>
                    <Typography
                      variant="h6"
                      style={{ color: "#133280", fontSize: 20 }}
                    >
                      The Emblem
                    </Typography>
                    <Typography variant="body2">
                      The conch on the eight-petaloid full-bloomed graceful
                      lotus .....{" "}
                    </Typography>
                  </div>
                </Link>
              </div>
            </div>

            <br />
          </Container>
        </div> */}

        {/* <div style={{ minHeight: 400 }}>
          <br />
          <HomePageEvents />

          <br />
          <br />
        </div> */}

        {/* <div style={{ minHeight: 400 }}>
          <br />
          <StudentsCornerHomePage />

          <br />
          <br />
        </div> */}

        <div style={{ minHeight: 400 }}>
          <br />
          <KnowYourFaculty />

          <br />
          <br />
        </div>


        <div style={{ minHeight: 400 }}>
   
          <CollegeMap />

          <br />
          <br />
        </div>

        <div style={{ backgroundColor: "#f9f9f9", padding: 10 }}>
          {/* Event Slider */}
          
            <Hightlights />
        
        </div>

        <div style={{ minHeight: 400 }}>
   
          <ProminentSection />

          <br />
          <br />
        </div>


        {/* <div style={{ minHeight: 400 }}>
          <br />
          <VideoHomePage />

          <br />
          <br />
        </div> */}

        <div style={{ backgroundColor: "#192F59" }}>
          <Container>
            <div className="paper-homepage-awards-div">
              <div style={{ flexBasis: "50%" }}>
                <Typography
                  variant="h6"
                  style={{ color: "white", fontSize: 25, fontWeight: 700 }}
                >
                  Awards and Accreditations
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexBasis: "50%",
                  alignItems: "center",
                }}
              >
                <a href="http://naac.gov.in/index.php/en/" target="_blank">
                  <div
                    style={{
                      minHeight: 100,
                      width: 100,
                      backgroundColor: "white",
                      borderRadius: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: 700,
                      flexDirection: "column",
                      marginRight: 20,
                      padding: 10,
                    }}
                  >
                    <img
                      src="/images/icons/naac-logo.png"
                      style={{ width: 60 }}
                    />
                    NAAC
                  </div>
                </a>
                <a href="https://www.ugc.ac.in/" target="_blank">
                  <div
                    style={{
                      minHeight: 100,
                      width: 100,
                      backgroundColor: "white",
                      borderRadius: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: 700,
                      flexDirection: "column",
                      marginRight: 20,
                      padding: 10,
                    }}
                  >
                    <img src="/images/icons/ugc.jpeg" style={{ width: 60 }} />
                    UGC
                  </div>
                </a>
                <a href="https://www.gauhati.ac.in/" target="_blank">
                  <div
                    style={{
                      minHeight: 100,
                      width: 100,
                      backgroundColor: "white",
                      borderRadius: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: 700,
                      flexDirection: "column",
                      marginRight: 20,
                      padding: 10,
                    }}
                  >
                    <img src="/images/icons/idol.jpeg" style={{ width: 60 }} />
                    GU
                  </div>
                </a>
              </div>
            </div>
          </Container>
        </div>

        <div style={{ backgroundColor: "#2455CB" }}>
          <Container>
            <div className="paper-homepage-awards-div">
              <div stye={{ flexBasis: "50%" }}>
                <Typography
                  variant="h6"
                  style={{ color: "white", fontSize: 25, fontWeight: 700 }}
                >
                  Distance Learning & Online Courses
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexBasis: "50%",
                  alignItems: "center",
                }}
              >
                <Link to="/kkhsou">
                  <div
                    style={{
                      minHeight: 100,
                      width: 100,
                      backgroundColor: "white",
                      borderRadius: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: 700,
                      flexDirection: "column",
                      marginRight: 20,
                      padding: 10,
                    }}
                  >
                    <img src="/images/icons/kkhou.jpg" style={{ width: 60 }} />
                    KKHSOU
                  </div>
                </Link>
                <Link to="/gucdoe">
                  <div
                    style={{
                      minHeight: 100,
                      width: 100,
                      backgroundColor: "white",
                      borderRadius: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: 700,
                      flexDirection: "column",
                      marginRight: 20,
                      padding: 10,
                    }}
                  >
                    <img src="/images/icons/idol.jpeg" style={{ width: 60 }} />
                    GUCDOE
                  </div>
                </Link>
                <Link to="/ignou">
                  <div
                    style={{
                      minHeight: 100,
                      width: 100,
                      backgroundColor: "white",
                      borderRadius: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: 700,
                      flexDirection: "column",
                      marginRight: 20,
                      padding: 10,
                    }}
                  >
                    <img src="/images/icons/ignou.jpg" style={{ width: 100 }} />
                    IGNOU
                  </div>
                </Link>
                <Link to="/nptel">
                  <div
                    style={{
                      minHeight: 100,
                      width: 100,
                      backgroundColor: "white",
                      borderRadius: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: 700,
                      flexDirection: "column",
                      marginRight: 20,
                      padding: 10,
                    }}
                  >
                    <img src="/images/icons/swayam-123.png" style={{ width: 65 }} />
                    SWAYAM
                  </div>
                </Link>
              </div>
            </div>
          </Container>
        </div>



        

        {/* <div className="news py-4">
          <MDBContainer>
            <p
              style={{
                color: "#b94735",
                fontSize: "2.7em",
                fontWeight: "600",
                textAlign: "center",
                margin: "2em 0 0 0",
              }}
            >
              Take A Tour Of the College
            </p>
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8 my-4 vdamaz">
                <iframe
                  width="100%"
                  style={{ aspectRatio: "1.6410" }}
                  src="https://www.youtube.com/embed/BtDCX335d9E"
                  title="YouTube video player"
                  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe>
              </div>
              <div className="col-md-2"></div>
            </div>
          </MDBContainer>

        </div> */}
        <HomePageFeatured />
      </div>
    );
  }
}
