import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const style = {
    cursor: 'pointer',
    paddingTop: '5px'
}

const SecondaryStyleCss = {

    container: {

        backgroundColor: '#f9f9f9',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '20px',
        cursor: 'pointer'
    },
    leftSide: {
        height: 'auto',
        width: '5px',
        backgroundColor: '#A52A2A'
    },
    rightSide: {
        padding: '10px',
        paddingLeft: '25px'
    },
    newText: {
        fontWeight: 300
    }


}

class TitleStyle extends Component {
    state = {
        width: 20
    }

    onMouseOver = () => {
        this.setState({
            width: 120
        })
    }

    onMouseOut = () => {
        this.setState({
            width: 20
        })
    }
    render() {
        let title = this.props.title;
        let title_head = '';
        let title_tail = '';

        if (title !== undefined) {
            let title_arr = title.split(' ');
            if (title_arr.length === 1) {
                title_head = title_arr[0];
            } else {
                title_head = title_arr[0];
                title_arr.splice(0, 1);
                title_tail = title_arr.join(' ');
            }
        }

        return (
            
                <div className="">
                    <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                        <span style={{ color: '#1F53CB' }}>{title_head}</span> {title_tail}
                    </Typography>
                   
                </div>
            
        )
    }
}

TitleStyle.propTypes = {
    title: PropTypes.string
}

export default TitleStyle;


export const SecondaryStyle = (props) => {
    return (
        <div style={SecondaryStyleCss.container}>
            <div style={SecondaryStyleCss.leftSide}>

            </div>
            <div style={SecondaryStyleCss.rightSide}>
                <h3 style={SecondaryStyleCss.newText}>
                    {props.title}
                </h3>
            </div>

        </div>
    )
}
