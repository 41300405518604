import React, { Component } from "react";
import BreadCumb from "./../component/Department/BreadCumb";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Grid, Container, Typography } from "@material-ui/core";

export default class ContactUsContainer extends Component {
  render() {
    return (
      <div>

        <BreadCrumbOwn title="Contact Us" />
        <Container style={{ minHeight: '600px' }}>
          <br></br>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <ul>
                  <li style={{ paddingBottom: "", listStyle: 'none', fontSize: '1.2em', marginLeft: "-40px" }}>
                    <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                      Postal Address
                    </Typography>
                    <Typography variant="body1">
                      Pragjyotish College
                      <br />
                      Santipur,Kamrup(M),Assam (India)
                      <br />
                      Pin 781009
                      <br />
                      Email:{" "}
                      <a href={`mailto:principal@pragjyotishcollege.ac.in`}>
                      principal@pragjyotishcollege.ac.in
                      </a>
                      <br />
                      Webmail:{" "}
                      <a href={`mailto:principal@pragjyotishcollege.ac.in`}>
                      principal@pragjyotishcollege.ac.in
                      </a>
                      <br />
                      Website:{" "}
                      <a href={`https://pragjyotishcollege.ac.in/`}>
                        https://pragjyotishcollege.ac.in/
                      </a>{" "}
                      <br />
                      
                    </Typography>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3580.890758478787!2d91.7275572154687!3d26.16768698345411!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5bb3f423e39b%3A0x1adac45f6116e482!2sPragjyotish%20College!5e0!3m2!1sen!2sin!4v1674133655693!5m2!1sen!2sin"
                  width="600"
                  height="400"
                  style={{ border: 0, maxWidth: '100%' }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>

              </Grid>
            </Grid>

          </div>
        </Container>
      </div>
    );
  }
}
