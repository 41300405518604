import React, { Component } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import BreadCumb from "./../component/Department/BreadCumb";
import NotificationDescription from "../component/Notifications/NotificationDescription";
import { PostData } from "../api/service";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Table, TableBody, TableCell, TableHead, TableRow, Container, Typography } from '@material-ui/core'
import { Alert, Button } from "antd";
export default class RecruitmentContainer extends Component {
    render() {
        return (
            <div>
                <BreadCrumbOwn title="Recruitment" />
                <Container style={{ minHeight: '80vh' }}>
                    <br />


               
                    <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                        Recruitment
                    </Typography>


                    <Alert
                        type="info"
                        banner
                        message={<>Note: Hardcopy of DHE format and photocopy of other relevant documents must be submitted to the college.
                        
                        <br />
                        <br />

                        Follow all the guidelines mentioned in the recruitment portal. Click on "Visit Recruitment Portal" to access the site. for any queries you can email us at <a href="mailto:recruitment@pragjyotishcollege.ac.in">recruitment@pragjyotishcollege.ac.in</a>
                        
                        
                        </>}
                    />
                    
                    <br />

                    <Button type="primary" target="_blank" href="https://recruitment.pragjyotishcollege.ac.in/">Visit Recruitment Portal</Button>


                </Container>
            </div>
        );
    }
}
