import { Modal } from 'antd'
import React from 'react'
import { GetData } from '../../api/service';

export default function HomePageFeatured() {

    const [visible, setIsVisible] = React.useState(false)

    const [data, setData] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => loadData(), 5000)
    }, [])

    function loadData(){
        GetData(`/getfeaturednotificationwebsite`)
            .then((resp) => {
                if (resp.length > 0) {
                    setData(resp[0])

                    setIsLoaded(true)
                    setIsVisible(true)
                }

            })
    }

    return (
        <div>

            {isLoaded && <Modal destroyOnClose={true} width="60%" visible={visible} onOk={() => null} footer={null} onCancel={() => setIsVisible(false)}>
                {data?.upload_info_url && data?.upload_info_url.length > 0 && <><img
                    src={data?.upload_info_url[0]}
                    style={{ width: "100%" }}
                /></>}
                <p dangerouslySetInnerHTML={{ __html: data?.description }}></p>

            </Modal>}
        </div>
    )
}
