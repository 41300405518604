import React from 'react'
import {Container, Typography} from '@material-ui/core'
import { Alert, Button } from "antd";

export default function OnlineCertificates() {
  return (
    <div>
        <Container style={{ minHeight: '80vh' }}>
                    <br />


               
                    <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                        Apply for certificates online
                    </Typography>


                    <Alert
                        type="info"
                        banner
                        message={<>

                        You can apply for various online certificates like - Bonafide Certificate, Provisional Pass Certificate, Transfer Certificate, Charecter Certificate through the following link. The steps to apply these certificate:

                        <ul>
                            <li>Step 1: Visit the link: <a href="certificate.pragjyotishcollege.ac.in" target="_blank">certificate.pragjyotishcollege.ac.in</a></li>
                            <li>Step 2: Fillup the form with proper values and a valid email. and click on "Submit". You will be redirected to payment portal where you need to make the payment.</li>
                            <li>Step 3: After the payment, a receipt will be generated and your certificate will be processed by the office.</li>
                            <li>Step 4: You will be notified on successful generation of the certificate via email.</li>
                        </ul>



                        
                        
                        </>}
                    />
                    
                    <br />

                    <Button type="primary" target="_blank" href="https://certificate.pragjyotishcollege.ac.in/">Visit Certificate Portal</Button>


                </Container>
    </div>
  )
}
