import React, { Component } from "react";
import BreadCumb from "./../component/Department/BreadCumb";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Grid, Container, Typography } from "@material-ui/core";
import { PostData } from "../api/service";

export default class AdmissionProcedureContainer extends Component {
  state = {
    data: "",
    isLoaded: false,
  };

  componentDidMount() {
    let d = {
      dept_code: "PRAG",
      type: "ADMISSION_PROCEDURE",
    };

    PostData(`/getnoticesbytypedept`, d).then((resp) => {
      if (resp.length > 0) {
        this.setState({
          data: resp[0],
          isLoaded: true,
        });
      }
    });
  }
  render() {
    return (
      <div>
        <BreadCrumbOwn title="Admission Procedure" />
        <Container style={{ minHeight: "600px" }} className="mb-5">
          <br></br>
          {this.state.isLoaded && this.state.data && <div className="prose" dangerouslySetInnerHTML={{__html: this.state.data.description}}></div>}
          {/* <div>
            <Typography
              variant="h6"
              style={{ margin: "1em 0", fontWeight: 700 }}
              gutterBottom
            >
              General Information for Admission
            </Typography>
            <Typography variant="body2">
              <ul>
                <ul>
                  <li>
                    Admission in all programs is strictly in order of merit and
                    is governed by the college admission rules, implemented
                    through the Admission Committee (2020-21 Session). All
                    decisions of the Admission Committee regarding admission
                    matters will be final and binding.
                  </li>
                </ul>
              </ul>

              <ul>
                <ul>
                  <li>
                    <strong>Admission Procedure (Govt. Guidelines):</strong>
                    &nbsp;In accordance with the Office Memorandum No. AHE.
                    639/2018/17, dated 10.06.2020, of the Higher Education
                    Department, Govt. of Assam, there shall be no face
                    interview, document verification etc. for admission during
                    the academic session 2020-21. Mark-sheets, Certificates and
                    all other relevant documents will be scanned and uploaded
                    for the admission process by the students. All students will
                    have to upload bank details, including IFSC, Bank Name,
                    Branch and Mobile Number while applying for admission to
                    avail the benefit of schemes like scholarship, reimbursement
                    of hostel mess dues, textbook grants, etc. from the Govt.
                  </li>
                </ul>
              </ul>

              <ul>
                <ul>
                  <li>
                    Admission against Reserved Categories: This is done as per
                    rules and guidelines stipulated by the Govt. However, it
                    will not be applicable in the allocation of Honours
                    subjects. Reservation of seats for the different programs
                    will be done as per the break-up given below:
                    <br />
                    <br />
                    <table
                      class="responsive"
                      width="100%"
                      cellspacing="0"
                      cellpadding="7"
                    >
                      <colgroup>
                        <col width="36*" />
                        <col width="37*" />
                        <col width="37*" />
                        <col width="66*" />
                        <col width="43*" />
                        <col width="38*" />
                      </colgroup>
                      <tbody>
                        <tr valign="top">
                          <td bgcolor="#fde9d9" width="14%">
                            <p lang="en-US" align="center">
                              <strong>Scheduled Caste</strong>
                            </p>
                            <p lang="en-US" align="center">
                              <strong>[SC]</strong>
                            </p>
                          </td>
                          <td bgcolor="#fde9d9" width="14%">
                            <p lang="en-US" align="center">
                              <strong>Scheduled Tribes</strong>
                            </p>
                            <p lang="en-US" align="center">
                              <strong>(Hills)</strong>
                            </p>
                            <p lang="en-US" align="center">
                              <strong>[ST(H)]</strong>
                            </p>
                          </td>
                          <td bgcolor="#fde9d9" width="14%">
                            <p lang="en-US" align="center">
                              <strong>Scheduled</strong>
                            </p>
                            <p lang="en-US" align="center">
                              <strong>Tribes</strong>
                            </p>
                            <p lang="en-US" align="center">
                              <strong>(Plains)</strong>
                            </p>
                            <p lang="en-US" align="center">
                              <strong>[ST(P)]</strong>
                            </p>
                          </td>
                          <td bgcolor="#fde9d9" width="26%">
                            <p lang="en-US" align="center">
                              <strong>Other Backward Classes/</strong>
                            </p>
                            <p lang="en-US" align="center">
                              <strong>More Other Backward Classes</strong>
                            </p>
                            <p lang="en-US" align="center">
                              <strong>[OBC/ MOBC]</strong>
                            </p>
                          </td>
                          <td bgcolor="#fde9d9" width="17%">
                            <p lang="en-US" align="center">
                              <strong>Person</strong>
                            </p>
                            <p lang="en-US" align="center">
                              <strong>with Disabilities</strong>
                            </p>
                            <p lang="en-US" align="center">
                              <strong>[PwD]</strong>
                            </p>
                          </td>
                          <td bgcolor="#fde9d9" width="15%">
                            <p lang="en-US" align="center">
                              <strong>Extra-Curricular Activities</strong>
                            </p>
                            <p lang="en-US" align="center">
                              <strong>[ECA]</strong>
                            </p>
                          </td>
                        </tr>
                        <tr valign="top">
                          <td bgcolor="#eaf1dd" width="14%">
                            <p lang="en-US" align="center">
                              <strong>7 %</strong>
                            </p>
                          </td>
                          <td bgcolor="#eaf1dd" width="14%">
                            <p lang="en-US" align="center">
                              <strong>5 %</strong>
                            </p>
                          </td>
                          <td bgcolor="#eaf1dd" width="14%">
                            <p lang="en-US" align="center">
                              <strong>10 %</strong>
                            </p>
                          </td>
                          <td bgcolor="#eaf1dd" width="26%">
                            <p lang="en-US" align="center">
                              <strong>27 %</strong>
                            </p>
                          </td>
                          <td bgcolor="#eaf1dd" width="17%">
                            <p lang="en-US" align="center">
                              <strong>3 %</strong>
                            </p>
                          </td>
                          <td bgcolor="#eaf1dd" width="15%">
                            <p lang="en-US" align="center">
                              <strong>2 %</strong>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    <strong>
                      Reservation against Extra-curricular Activities:
                    </strong>
                    &nbsp;For securing admission against this category, a
                    candidate should provide testimonials to establish that he/
                    she have participated in any sports/ games/ cultural etc.
                    event not below State level.
                  </li>
                </ul>
              </ul>

              <ul>
                <ul>
                  <li>
                    <strong>Standardization of Marks:</strong>&nbsp;To
                    standardize the marks obtained by candidates of various
                    boards, the Admission Committee shall follow the following
                    procedure for preparing merit lists in various programs.
                    <br />
                    <ol>
                      <ol>
                        <li>
                          <strong>B.A./ B.Sc./ B.Com Programs:</strong>&nbsp;Any
                          four subjects (excepting Physical Education/
                          Entrepreneurship/ Vocational** subjects) in which the
                          candidate has obtained better marks in the 12th
                          standard examination
                        </li>
                      </ol>
                    </ol>
                    <br />
                    <ol>
                      <li>
                        <strong>H.S. Programs:</strong>&nbsp;As per aggregate
                        marks
                        <br />
                        <center>
                          <strong>
                            (**N.B.: A subject that contains 30% Theory + 70%
                            Practical is considered as a Vocational subject)
                          </strong>
                        </center>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>College Prospectus:</strong>&nbsp;College Prospectus
                    will be available in the college website and can be
                    downloaded FREE of cost. College Prospectus/ Admission Forms
                    in hard copy format will NOT be issued from the college
                    office.
                  </li>
                </ul>
              </ul>

              <ul>
                <ul>
                  <li>
                    <strong>Payment of Online Registration Fees :</strong>
                    &nbsp;For details see Table on Fees Structure
                  </li>
                </ul>
              </ul>

              <ul>
                <ul>
                  <li>
                    <strong>
                      Payment of Admission Fees (Govt. Guidelines):
                    </strong>
                    &nbsp;In accordance with the Office Memorandum No. AHE.
                    639/2018/16, dated 10.06.2020, of the Higher Education
                    Department, Govt. of Assam, all fees of students relating to
                    admission and all other categories for the academic session
                    2020-21 has been waived, irrespective of the income or the
                    economic status of their parents.. However, fee waiver will
                    NOT include Self-Financing Courses and Hostel Admission
                    Fees. This fee waiver scheme shall be applicable to the
                    students domicile from Assam only. For details see Table on
                    Fees Structure.
                  </li>
                </ul>
              </ul>

              <ul>
                <ul>
                  <li>
                    <strong>Filling up of Online Admission Forms:</strong>
                    &nbsp;All candidates will have to fill up the Admission
                    Forms through ONLINE MODE ONLY through the link provided in
                    the college website
                    <a href="https://pragjyotishcollege.ac.in" target="_blank">
                      &nbsp;[www.pragjyotishcollege.ac.in]
                    </a>
                    &nbsp;on or before the stipulated dates mentioned in the
                    Admission Notice
                  </li>
                </ul>
              </ul>

              <ul>
                <ul>
                  <li>
                    <strong>Online Document Upload:</strong>&nbsp;Candidates
                    will have to upload SCANNED COPIES of relevant documents,
                    photographs, signatures, etc. as instructed in the
                    Guidelines for Filling-up the Online Admission Form
                  </li>
                </ul>
              </ul>

              <p>
                <br />
                <strong>Gap Certificate:</strong>&nbsp;To be provided by
                candidates whose year of passing the qualifying exam is not the
                current year, i.e. 2020. Gap Certificates (if necessary) must be
                either from the Head of the Institution last attended or from in
                the form of an Affidavit from any Hon&rsquo;ble Court of Law.
                Gaps of more than 3 (Three Years) shall NOT be considered for
                admission in any of the programs. Medical Certificates will NOT
                be accepted in lieu of gap certificates.
                <br />
                <br />
                <strong>
                  Domicile Certificate (only by candidates whose State of
                  Domicile is ASSAM):
                </strong>
                &nbsp;In accordance with the Office Memorandum No. PC/HE/SOPD/
                11/2020/12, dated 18.06.2020, of the Director Higher Education,
                Govt. of Assam, any one of the following documents must be
                provided-
                <br />
                <br />
              </p>
              <ol>
                <li>PERMANENT RESIDENCE CERTIFICATE (PRC)</li>
                <li>VOTER IDENTITY CARD of self or parent</li>
                <li>NRC of self or parent</li>
                <li>AADHAR CARD of self or parent</li>
                <li>BPL CARD of family</li>
                <li>
                  BIRTH CERTIFICATE issued by Registrar of Birth and Death
                </li>
                <li>PASSPORT of self or parent</li>
              </ol>
              <p>
                <br />
                <strong>Verification of Original Documents:</strong>&nbsp;All
                original documents related to admission purpose will have to be
                produced by the admitted students as and when asked for by the
                college authorities at a later date.
                <br />
                <br />
                <strong>Helpline No.:</strong>&nbsp;Candidates may contact the
                Helpline No. (Mentioned in the Admission Notice) if he/ she
                finds any difficulty during filling up and submission of the
                Online Admission Form.
                <br />
                <br />
                <strong>
                  Reservation against Allocation of Honours Subject:
                </strong>
                &nbsp;Reservation policy DOES NOT exists in the matter of
                allocating Honours in different subjects.
                <br />
                <br />
                <strong>IMPORTANT POINTS TO BE NOTED</strong>
                <br />
                <br />
                1. NO FALSE DATA should be provided while filling up of the
                Online Admission Form. If any instances of commission of forgery
                or providing wrong data are detected on a later date,
                appropriate disciplinary action will be taken.
                <br />
                <br />
                2. After securing admission, students must ensure that the
                following (as provided in the Online Admission Form) continues
                to&nbsp;<strong>remain same</strong>&nbsp;during their entire
                period of study in the college.
                <br />
                <br />
                <strong>Bank Account Details</strong>
                <br />
                <strong>Mobile Phone Numbers</strong>
                <br />
                <strong>E-Mails</strong>
                <br />
                <br />
                Otherwise, the admitted students will be deprived from the Govt.
                beneficiary schemes.
                <br />
                <br />
                An UNDERTAKING will have to be provided by the students at the
                time of admission in a standard format to be provided by the
                college.
                <br />
                <br />
                3. Subject Combinations allotted at the time of admission will
                NOT be changed later
                <br />
                <br />
                <strong>FEES STRUCTURE</strong>
                <br />
                <br />
              </p>
              <table
                id="fees"
                class="table"
                width="100%"
                cellspacing="0"
                cellpadding="7"
              >
                <colgroup>
                  <col width="86*" />
                  <col width="37*" />
                  <col width="36*" />
                  <col width="11*" />
                  <col width="37*" />
                  <col width="49*" />
                </colgroup>
                <tbody>
                  <tr>
                    <td colspan="6" valign="top" bgcolor="#ffff00" width="100%">
                      <p class="western" lang="en-US" align="center">
                        <strong>REGULAR PROGRAMS</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" bgcolor="#ffc000" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Fee Heads</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffc000" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>H.S.</strong>
                      </p>
                      <p class="western" lang="en-US" align="center">
                        <strong>(Arts</strong>)
                      </p>
                    </td>
                    <td bgcolor="#ffc000" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>H.S.</strong>
                      </p>
                      <p class="western" lang="en-US" align="center">
                        <strong>(Science)</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ffc000" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>B.A.</strong>
                      </p>
                      <p class="western" lang="en-US" align="center">
                        <strong>(Honours)</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffc000" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>B.Sc.</strong>
                      </p>
                      <p class="western" lang="en-US" align="center">
                        <strong>(Honours)</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Admission</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>400</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>400</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>400</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>400</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Tuition</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>600</strong>
                        <strong>**</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>720</strong>
                        <strong>**</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>840</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>1020</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Establishment</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>900</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>900</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>900</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>900</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Laboratories</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>400&nbsp;</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>200</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>1000</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Electricity</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>600</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>600</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>600</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>600</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Contingency</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>400</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>400</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>400</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>400</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Council/ University Registration</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>200</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>200</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>620</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>620</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>College Identity Card</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Development</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>500</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>500</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>500</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>500</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Library</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>200</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>200</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>200</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>200</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>College Examination</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>200</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>200</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>200</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>200</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>NCC</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>NSS</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Bharat Scouts &amp; Guides</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>College Magazine</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>150</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>150</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>150</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>150</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Students&rsquo; Union</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Games and Sports</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Festival</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Co-curricular</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Cultural/ Music</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Debating/ Literature</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Student Welfare</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>ICT</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Municipality Tax</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Red Ribbon Club</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Youth Red Cross Society</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>10</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Security</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>150</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>150</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>150</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>150</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Student Insurance</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>50</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Extension Education Programme</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>200</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>200</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>200</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>200</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Youth Festival</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>0&nbsp;</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>0&nbsp;</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                    <td bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>100</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td rowspan="2" bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>TOTAL</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffffcc" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>5450</strong>
                      </p>
                    </td>
                    <td bgcolor="#ffccff" width="14%">
                      <p class="western" lang="en-US" align="center">
                        <strong>5920</strong>
                      </p>
                    </td>
                    <td colspan="2" rowspan="2" bgcolor="#ccffff" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>6360</strong>
                      </p>
                    </td>
                    <td rowspan="2" bgcolor="#99ff99" width="19%">
                      <p class="western" lang="en-US" align="center">
                        <strong>7340</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="bottom">
                    <td colspan="2" bgcolor="#ccffcc" width="28%">
                      <p class="western" lang="en-US" align="center">
                        <strong>**&nbsp;</strong>
                        <strong>No Tuition Fees for Girls</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6" valign="top" bgcolor="#ffff00" width="100%">
                      <p class="western" lang="en-US" align="center">
                        <strong>SELF-SUSTAINING PROGRAMS</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td colspan="4" bgcolor="#dbe5f1" width="66%">
                      <p class="western" lang="en-US" align="center">
                        <strong>H.S. (Commerce)</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#dbe5f1" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>6300 per year</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td colspan="4" bgcolor="#f2dbdb" width="66%">
                      <p class="western" lang="en-US" align="center">
                        <strong>B.Com.</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#f2dbdb" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>8000 per semester</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td colspan="4" bgcolor="#fde9d9" width="66%">
                      <p class="western" lang="en-US" align="center">
                        <strong>B.B.A.</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#fde9d9" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>12000 per semester</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td colspan="4" bgcolor="#e5dfec" width="66%">
                      <p class="western" lang="en-US" align="center">
                        <strong>B.C.A.</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#e5dfec" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>12000 per semester</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td colspan="4" bgcolor="#f2f2f2" width="66%">
                      <p class="western" lang="en-US" align="center">
                        <strong>M.A. (Assamese)</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#f2f2f2" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>12000 per semester</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td colspan="4" bgcolor="#daeef3" width="66%">
                      <p class="western" lang="en-US" align="center">
                        <strong>M.T.M.</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#daeef3" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>12000 per semester</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td colspan="4" bgcolor="#fde9d9" width="66%">
                      <p class="western" lang="en-US" align="center">
                        <strong>M.A./ M.Sc. (Economics)</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#fde9d9" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>12000 per semester</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td colspan="4" bgcolor="#00ff99" width="66%">
                      <p class="western" lang="en-US" align="center">
                        <strong>M.Sc. (Zoology)</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#00ff99" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>20000 per semester</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td colspan="4" bgcolor="#ffcccc" width="66%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Diploma in Performing Arts</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ffcccc" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>3000 per year</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td colspan="4" bgcolor="#ffcc00" width="66%">
                      <p class="western" lang="en-US" align="center">
                        <strong>Diploma in Fine Arts</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ffcc00" width="34%">
                      <p class="western" lang="en-US" align="center">
                        <strong>3000 per year</strong>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <br />
                <br />
                <strong>MISCELLANEOUS FEES</strong>
                <br />
                <br />
              </p>
              <table class="table" width="712" cellspacing="0" cellpadding="7">
                <colgroup>
                  <col width="389" />
                  <col width="293" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td bgcolor="#fde9d9" width="389">
                      <p lang="en-US" align="center">
                        <strong>
                          Eligibility Certificate Fees (for migrated students)
                        </strong>
                      </p>
                    </td>
                    <td rowspan="5" bgcolor="#f2dbdb" width="293">
                      <p lang="en-US" align="justify">
                        Payment of these categories of fees by the admitted
                        students will be notified at a later date, as per
                        direction/ instruction/ notification received from the
                        State/ Central Govt./ other authorities
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#fde9d9" width="389">
                      <p lang="en-US" align="center">
                        <strong>Fees for offering Vocational Subject</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#fde9d9" width="389">
                      <p lang="en-US" align="center">
                        <strong>Fees for offering Honours</strong>
                      </p>
                      <p lang="en-US" align="center">
                        <strong>(for all students)</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#fde9d9" width="389">
                      <p lang="en-US" align="center">
                        <strong>Additional Fees for offering Honours</strong>
                      </p>
                      <p lang="en-US" align="center">
                        <strong>
                          (only for students of Anthropology, Statistics and
                          Geography)
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#fde9d9" width="389">
                      <p lang="en-US" align="center">
                        <strong>
                          Fees for Field Works/ Educational Excursions/ etc.
                        </strong>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <br />
                <br />
                <strong>ADMISSION FEES PAYMENT STATUS AT A GLANCE</strong>
                <br />
                <br />
              </p>
              <table class="table" width="100%" cellspacing="0" cellpadding="7">
                <tbody>
                  <tr valign="top">
                    <td rowspan="2" bgcolor="#fde9d9">
                      <p lang="en-US" align="center">
                        <strong>Name of Program</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ffcc99" width="204">
                      <p lang="en-US" align="center">
                        <strong>Online Registration Fees</strong>
                      </p>
                    </td>
                    <td colspan="3" bgcolor="#ccffcc" width="284">
                      <p lang="en-US" align="center">
                        <strong>Admission Fees</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#ffcc99" width="99">
                      <p lang="en-US" align="center">
                        <strong>Students domicile from Assam</strong>
                      </p>
                    </td>
                    <td colspan="2" bgcolor="#ffcc99" width="91">
                      <p lang="en-US" align="center">
                        <strong>Others</strong>
                      </p>
                    </td>
                    <td bgcolor="#ccffcc" width="118">
                      <p lang="en-US" align="center">
                        <strong>Students domicile from Assam</strong>
                      </p>
                    </td>
                    <td bgcolor="#ccffcc" width="152">
                      <p lang="en-US" align="center">
                        <strong>Others</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#ffffcc" width="191">
                      <p lang="en-US">
                        <strong>H.S. (Arts)</strong>
                      </p>
                    </td>
                    <td width="99">
                      <p lang="en-US" align="center">
                        <strong>FREE</strong>
                      </p>
                    </td>
                    <td colspan="2" width="91">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td width="118">
                      <p lang="en-US" align="center">
                        <strong>FREE</strong>
                      </p>
                    </td>
                    <td width="152">
                      <p lang="en-US">Rs. 5450.00 (for Boys)</p>
                      <p lang="en-US">Rs. 4850.00 (for Girls)</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#ffffcc" width="191">
                      <p lang="en-US">
                        <strong>H.S. (Science)</strong>
                      </p>
                    </td>
                    <td width="99">
                      <p lang="en-US" align="center">
                        <strong>FREE</strong>
                      </p>
                    </td>
                    <td colspan="2" width="91">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td width="118">
                      <p lang="en-US" align="center">
                        <strong>FREE</strong>
                      </p>
                    </td>
                    <td width="152">
                      <p lang="en-US">Rs. 5920.00 (for Boys)</p>
                      <p lang="en-US">Rs. 5200.00 (for Girls)</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#ffffcc" width="191">
                      <p lang="en-US">
                        <strong>H.S. (Commerce)</strong>
                      </p>
                    </td>
                    <td width="99">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td colspan="2" width="91">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td width="118">
                      <p lang="en-US">Rs. 6300.00</p>
                    </td>
                    <td width="152">
                      <p lang="en-US">Rs. 6300.00</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#ffffcc" width="191">
                      <p lang="en-US">
                        <strong>B.A. (Honours)</strong>
                      </p>
                    </td>
                    <td width="99">
                      <p lang="en-US" align="center">
                        <strong>FREE</strong>
                      </p>
                    </td>
                    <td colspan="2" width="91">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td width="118">
                      <p lang="en-US" align="center">
                        <strong>FREE</strong>
                      </p>
                    </td>
                    <td width="152">
                      <p lang="en-US">Rs. 6360.00</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#ffffcc" width="191">
                      <p lang="en-US">
                        <strong>B.Sc. (Honours)</strong>
                      </p>
                    </td>
                    <td width="99">
                      <p lang="en-US" align="center">
                        <strong>FREE</strong>
                      </p>
                    </td>
                    <td colspan="2" width="91">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td width="118">
                      <p lang="en-US" align="center">
                        <strong>FREE</strong>
                      </p>
                    </td>
                    <td width="152">
                      <p lang="en-US">Rs. 7340.00</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#ffffcc" width="191">
                      <p lang="en-US">
                        <strong>B.Com. (Honours)</strong>
                      </p>
                    </td>
                    <td width="99">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td colspan="2" width="91">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td width="118">
                      <p lang="en-US">Rs. 8000.00</p>
                    </td>
                    <td width="152">
                      <p lang="en-US">Rs. 8000.00</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#ffffcc" width="191">
                      <p lang="en-US">
                        <strong>B.B.A.</strong>
                      </p>
                    </td>
                    <td width="99">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td colspan="2" width="91">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td width="118">
                      <p lang="en-US">Rs. 12000.00</p>
                    </td>
                    <td width="152">
                      <p lang="en-US">Rs. 12000.00</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#ffffcc" width="191">
                      <p lang="en-US">
                        <strong>B.C.A.</strong>
                      </p>
                    </td>
                    <td width="99">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td colspan="2" width="91">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td width="118">
                      <p lang="en-US">Rs. 12000.00</p>
                    </td>
                    <td width="152">
                      <p lang="en-US">Rs. 12000.00</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#ffffcc" width="191">
                      <p lang="en-US">
                        <strong>M.A. (Assamese)</strong>
                      </p>
                    </td>
                    <td width="99">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td colspan="2" width="91">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td width="118">
                      <p lang="en-US">Rs. 12000.00</p>
                    </td>
                    <td width="152">
                      <p lang="en-US">Rs. 12000.00</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#ffffcc" width="191">
                      <p lang="en-US">
                        <strong>M.T.M.</strong>
                      </p>
                    </td>
                    <td width="99">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td colspan="2" width="91">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td width="118">
                      <p lang="en-US">Rs. 12000.00</p>
                    </td>
                    <td width="152">
                      <p lang="en-US">Rs. 12500.00</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#ffffcc" width="191">
                      <p lang="en-US">
                        <strong>M.A./ M.Sc. (Economics)</strong>
                      </p>
                    </td>
                    <td width="99">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td colspan="2" width="91">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td width="118">
                      <p lang="en-US">Rs. 12000.00</p>
                    </td>
                    <td width="152">
                      <p lang="en-US">Rs. 12000.00</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#ffffcc" width="191">
                      <p lang="en-US">
                        <strong>M.Sc. (Zoology)</strong>
                      </p>
                    </td>
                    <td width="99">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td colspan="2" width="91">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td width="118">
                      <p lang="en-US">Rs. 20000.00</p>
                    </td>
                    <td width="152">
                      <p lang="en-US">Rs. 20000.00</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#ffffcc" width="191">
                      <p lang="en-US">
                        <strong>Diploma (Fine Arts)</strong>
                      </p>
                    </td>
                    <td width="99">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td colspan="2" width="91">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td width="118">
                      <p lang="en-US">Rs. 3000.00</p>
                    </td>
                    <td width="152">
                      <p lang="en-US">Rs. 3000.00</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td bgcolor="#ffffcc" width="191">
                      <p lang="en-US">
                        <strong>Diploma (Performing Arts)</strong>
                      </p>
                    </td>
                    <td width="99">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td colspan="2" width="91">
                      <p lang="en-US">Rs. 300.00</p>
                    </td>
                    <td width="118">
                      <p lang="en-US">Rs. 3000.00</p>
                    </td>
                    <td width="152">
                      <p lang="en-US">Rs. 3000.00</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Typography>
          </div> */}
        </Container>
      </div>
    );
  }
}
