import React from "react";
import { GetData, PostData } from "../../api/service";
import { Divider, Card } from "antd";
import { CardContent, Container, Grid, Typography } from "@material-ui/core";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
const handleDragStart = (e) => e.preventDefault();

export default function ProminentSection() {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <AlumniList />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <MessageList />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div style={{ backgroundColor: "#F9F9F9" }}>
            <Divider
              orientation="center"
              style={{ fontWeight: 700, fontSize: 20, borderColor: "#ABB2B9" }}
            >
              INSTITUTIONAL SWOC
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <SwocList
                  type="COLLEGE_SWOC_S"
                  otherType="COLLEGE_SWOC_O"
                  title={"INSTITUTIONAL STRENGTH & OPPORTUNITY"}
                />
              </Grid>

              {/* <Grid item xs={12} sm={12} md={3} lg={3}>
              <SwocList
                type="COLLEGE_SWOC_W"
                title={"INSTITUTIONAL WEAKNESS"}
              />
            </Grid> */}

              {/* <Grid item xs={12} sm={12} md={3} lg={3}>
              <SwocList
                type="COLLEGE_SWOC_O"
                title={"INSTITUTIONAL OPPORTUNITY"}
              />
            </Grid> */}

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <SwocList
                  type="COLLEGE_SWOC_C"
                  otherType="COLLEGE_SWOC_W"
                  title={"INSTITUTIONAL CHALLENGES & WEAKNESS"}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export function AlumniList() {
  const [data, setData] = React.useState([]);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  function getDepartment() {
    PostData(`/getalumnibydept`, {
      dept_code: "PRAG",
    }).then((resp) => {
      setData(resp);
      setIsDataLoaded(true);
    });
  }

  React.useEffect(() => {
    getDepartment();
  }, []);
  return (
    <div>
      <Divider
        orientation="center"
        style={{ fontWeight: 700, fontSize: 20, borderColor: "#ABB2B9" }}
      >
        PROMINENT ALUMNI
      </Divider>
      <AliceCarousel
        responsive={{
          0: {
            items: 1,
          },
          1024: {
            items: 1,
            itemsFit: "contain",
          },
        }}
        autoPlay={true}
        autoWidth={true}
        autoHeight={true}
        autoPlayInterval={2000}
        dotsDisabled={true}
        mouseTracking
        items={data.map((el, index) => {
          return (
            <div>
              {Array.isArray(el.dp_url) && el.dp_url.length > 0 && (
                <img
                  src={el.dp_url[0]}
                  onDragStart={handleDragStart}
                  role="presentation"
                  style={{
                    width: "100%",
                    height: "400px",
                    objectFit: "contain",
                  }}
                />
              )}
              <br />
              <br />
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: 16,
                  marginTop: 20,
                  textAlign: "center",
                }}
              >
                {el.name}
              </Typography>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: 14,
                  marginTop: 20,
                  textAlign: "center",
                }}
              >
                {el.current_profession}
              </Typography>
              {el.qualification && (
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: 14,
                    marginTop: 20,
                    textAlign: "center",
                  }}
                >
                  Qualification: {el.qualification}
                </Typography>
              )}
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: 14,
                  marginTop: 20,
                  textAlign: "center",
                }}
              >
                Batch: {el.batch}
              </Typography>
            </div>
          );
        })}
      />
    </div>
  );
}

export function MessageList() {
  const [data, setData] = React.useState([]);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  function getDepartment() {
    GetData(`/getmessage-all`).then((resp) => {
      setData(resp);
      setIsDataLoaded(true);
    });
  }

  React.useEffect(() => {
    getDepartment();
  }, []);
  return (
    <div style={{ marginTop: 30 }}>
      <AliceCarousel
        responsive={{
          0: {
            items: 1,
          },
          1024: {
            items: 1,
            itemsFit: "contain",
          },
        }}
        autoPlay={true}
        autoWidth={true}
        autoHeight={true}
        autoPlayInterval={2000}
        dotsDisabled={true}
        mouseTracking
        items={data.map((el, index) => {
          return (
            <div key={index}>
              <Testimonial
                type={el.type}
                dp={el.dp_url[0]}
                name={el.name}
                message={el.message}
                batch={el.batch}
                current_profession={el.current_profession}
                company_name={el.company_name}
              />
            </div>
          );
        })}
      />
    </div>
  );
}

export function SwocList({ type, otherType, title }) {
  const [data, setData] = React.useState([]);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  function getDepartment() {
    PostData(`/getnoticesbytypedept`, {
      dept_code: "PRAG",
      type: type,
      year: "",
    }).then((resp) => {
      PostData(`/getnoticesbytypedept`, {
        dept_code: "PRAG",
        type: otherType,
        year: "",
      }).then((resp1) => {
        setData([...resp, ...resp1]);
        setIsDataLoaded(true);
      });
    });
  }

  React.useEffect(() => {
    getDepartment();
  }, []);
  return (
    <div>
      {data.length > 0 && (
        <>
          <Divider
            orientation="center"
            style={{ fontWeight: 700, fontSize: 14, borderColor: "#ABB2B9" }}
          >
            {title}
          </Divider>
          <AliceCarousel
            responsive={{
              0: {
                items: 1,
              },
              1024: {
                items: 1,
                itemsFit: "contain",
              },
            }}
            autoPlay={true}
            autoWidth={true}
            autoHeight={true}
            autoPlayInterval={2000}
            dotsDisabled={true}
            mouseTracking
            items={data.map((el, index) => {
              return (
                <div>
                  {Array.isArray(el.upload_info) &&
                    el.upload_info.length > 0 && (
                      <img
                        src={el.upload_info[0].url}
                        onDragStart={handleDragStart}
                        role="presentation"
                        style={{
                          width: "100%",
                          height: "400px",
                          objectFit: "contain",
                        }}
                      />
                    )}
                  <br />
                  <br />
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      marginTop: 20,
                      textAlign: "center",
                    }}
                  >
                    {el.title}
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: 12,
                      marginTop: 20,
                      textAlign: "center",
                    }}
                    dangerouslySetInnerHTML={{ __html: el.description }}
                  ></Typography>
                </div>
              );
            })}
          />
        </>
      )}
    </div>
  );
}

const Testimonial = ({
  type,
  dp,
  name,
  message,
  batch,
  current_profession,
  company_name,
}) => {
  const styles = {
    container: {
      maxWidth: "400px",
      backgroundColor: "#184EA0",
      borderRadius: "10px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      padding: "20px",
      margin: "20px",
      textAlign: "center",
      color: "white",
      position: "relative", // To position the overlay correctly
      overflow: "hidden", // Hide any overflow
      backgroundImage: `url("https://webbackend.pragjyotishcollege.ac.in/src/uploads/BANNER_IMAGES/1_b09PQCRCqn.jpg")`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(24, 78, 160, 0.8)", // Semi-transparent overlay
      zIndex: 1, // Ensure it appears above the background
    },
    content: {
      position: "relative", // So it appears above the overlay
      zIndex: 2,
    },
    image: {
      width: "80px",
      height: "80px",
      borderRadius: "50%",
      objectFit: "cover",
      marginBottom: "15px",
    },
    message: {
      fontStyle: "italic",
      marginBottom: "15px",
      minHeight: "300px",
      fontWeight: "700"
    },
    name: {
      fontWeight: "bold",
      fontSize: "18px",
      marginBottom: "5px",
    },
    batch: {
      fontSize: "14px",
      color: "#E0E0E0",
    },
    header: {
      fontWeight: "700",
      fontSize: "20px",
      marginBottom: "12px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.overlay}></div>
      <div style={styles.content}>
        <p style={styles.header}>MESSAGE FROM {type}</p>
        <img src={dp} alt="Person Image" style={styles.image} />
        <p style={styles.message}>"{message}"</p>
        <p style={styles.name}>{name}</p>
        <p style={styles.batch}>{batch}</p>
        <p style={styles.batch}>
          {current_profession} {company_name}
        </p>
      </div>
    </div>
  );
};
