import React from "react";
import { GetData } from "../api/service";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Container } from "@mui/material";

export default function NewsletterContainer() {
  const [data, setData] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    GetData(`/getdepartments`).then((resp) => {
      setData(resp);
      setIsLoaded(true);
    });
  }, []);
  return (
    <div>
      <BreadCrumbOwn title="Newsletter" />
      <Container style={{ minHeight: "600px" }} className="mb-5">
        <br></br>
        {isLoaded && (
          <div>
            <ul>
              {Array.isArray(data) &&
                data.map((el, index) => (
                  <li key={index}>
                    <a
                      style={{ fontSize: 14 }}
                      targte="_blank"
                      href={`/department/${el.dept_code}/journal`}
                    >
                      Newsletter of {el.dept_name}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </Container>
    </div>
  );
}
