import React, { Component } from 'react'
import { Container, Grid, Typography } from '@material-ui/core'
import TitleStyle from '../TitleStyle'
import { PostData } from '../../api/service'
import history from '../../history'

import queryString from 'query-string'
import { BackTop, Card, Tabs } from 'antd'
import FacultyView from './FacultyView'

import {
  UserOutlined,
  BoxPlotOutlined,
  BankOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  GoogleOutlined,
  DribbbleOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import QuarterlyReportView from './QuarterlyReportView'

const { TabPane } = Tabs

const smallTab = [
  // {
  //     title: 'Home',
  //     active: 0
  // },
  {
    title: 'Quarterly Report',
    value: 'courses_covered',
    active: 1,
  },
  // {
  //   title: 'Extension Activities',
  //   value: 'social_activity',
  //   active: 2,
  // },
  // {
  //   title: 'Student Related Activity',
  //   value: 'student_related_activity',
  //   active: 3,
  // },
  // {
  //   title: 'In-Campus Activity',
  //   value: 'involvement_in_campus',
  //   active: 4,
  // },
  // {
  //   title: 'Academic Works',
  //   value: 'research',
  //   active: 5,
  // },
  
]

class FacultyQuarterlyReportLanding extends Component {
  state = {
    data: {},
    id: '',
    dept_code: this.props.match.params.dept_code,
    dept_name: '',
    isLoaded: false,
    active: 1,
    activeData: '',
    activeTitle: '',
    sameDepartment: [],
    isSameDepartmentLoaded: false,
    rr: [],
    qr: [],
    selectedQr: 'ALL'
  }

  myRef = React.createRef([])

  executeScroll = (active) => {
    console.log(this.state.rr)
    this.state.rr[active].scrollIntoView({ behavior: 'smooth' })
  }

  getMyProfileData = () => {
    PostData(`/getmyprofiledataqreport`, {
      id: this.state.id,
      qr: this.state.selectedQr
    })
      .then((resp) => {
        
        if (resp != false) {
          this.setState({
            data: resp,
            isLoaded: true,
            active: 1,
            dept_code: this.props.dept_code,
            dept_name: resp.dept_name,
            activeData: resp.basic,
            activeTitle: 'Basic Details',
            qr: resp.activities?.qr
          }, () => {
//  // we will filter all the available quarters for this faculty

//  let qr = [];
//  // courses_covered
//  // social_activities
//  // student_related_activity
//  // college_activities
//  // research research_paper other_publication text_book magazine popular_book news_paper econtent

//  // map through every data to filter the quarter value

// //  if(resp.econtent != null){
// //  resp.econtent.forEach(el => {
// //    if(!qr.includes(el.quarter)){
// //      if(el.quarter != "" && el.quarter != undefined){
// //        qr.push(el.quarter)
// //      }
// //    }
// //  })
// //  }

// //  if(resp.news_paper != null){
// //  resp.news_paper.forEach(el => {
// //    if(!qr.includes(el.quarter)){
// //      if(el.quarter != "" && el.quarter != undefined){
// //        qr.push(el.quarter)
// //      }
// //    }
// //  })
// // }

// // if(resp.popular_book != null){
// //  resp.popular_book.forEach(el => {
// //    if(!qr.includes(el.quarter)){
// //      if(el.quarter != "" && el.quarter != undefined){
// //        qr.push(el.quarter)
// //      }
// //    }
// //  })
// // }

// // if(resp.magazine != null){
// //  resp.magazine.forEach(el => {
// //    if(!qr.includes(el.quarter)){
// //      if(el.quarter != "" && el.quarter != undefined){
// //        qr.push(el.quarter)
// //      }
// //    }
// //  })
// // }
// // if(resp.text_book != null){
// //  resp.text_book.forEach(el => {
// //    if(!qr.includes(el.quarter)){
// //      if(el.quarter != "" && el.quarter != undefined){
// //        qr.push(el.quarter)
// //      }
// //    }
// //  })
// // }
// // if(resp.research_paper != null){
// //  resp.research_paper.forEach(el => {
// //    if(!qr.includes(el.quarter)){
// //      if(el.quarter != "" && el.quarter != undefined){
// //        qr.push(el.quarter)
// //      }
// //    }
// //  })
// // }
// // if(resp.other_publication != null){
// //  resp.other_publication.forEach(el => {
// //    if(!qr.includes(el.quarter)){
// //      if(el.quarter != "" && el.quarter != undefined){
// //        qr.push(el.quarter)
// //      }
// //    }
// //  })
// // }
// if(resp.courses_covered != null){
//  resp.courses_covered.forEach(el => {
//    if(!qr.includes(el.quarter)){
//      if(el.quarter != "" && el.quarter != undefined){
//        qr.push(el.quarter)
//      }
//    }
//  })
// }
// if(resp.social_activities != null){
//  resp.social_activities.forEach(el => {
//    if(!qr.includes(el.quarter)){
//      if(el.quarter != "" && el.quarter != undefined){
//        qr.push(el.quarter)
//      }
     
//    }
//  })
// }
// if(resp.student_related_activity != null){
//  resp.student_related_activity.forEach(el => {
//    if(!qr.includes(el.quarter)){
//      if(el.quarter != "" && el.quarter != undefined){
//        qr.push(el.quarter)
//      }
//    }
//  })
// }
// if(resp.college_activities != null){
//  resp.college_activities.forEach(el => {
//    if(!qr.includes(el.quarter)){
//      if(el.quarter != "" && el.quarter != undefined){
//        qr.push(el.quarter)
//      }
//    }
//  })
// }
// if(resp.research != null){
//  resp.research.forEach(el => {
//    if(!qr.includes(el.quarter)){
//      if(el.quarter != "" && el.quarter != undefined){
//        qr.push(el.quarter)
//      }
//    }
//  })
// }

//  qr.reverse()
// this.setState({
//   qr
// })
          })


         

         

        } else {
          history.push(`/department/${this.props.dept_code}/faculty`)
        }
      })
      .catch((err) => {
        console.log('Error', err)
      })
  }

  componentDidMount() {
    let search = this.props.location.search

    let searchObj = queryString.parse(search)

    if (searchObj.id !== undefined) {
      this.setState({
        id: searchObj.id
      }, () => this.getMyProfileData())
      this.loadOtherData()
    } else {
      history.push(`/department/${this.props.dept_code}/faculty`)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.selectedQr != this.state.selectedQr){
      this.getMyProfileData()
    }
  }

  callback = (key) => {
    this.executeScroll(key)

    let kk = smallTab.filter((el) => el.active == key)

    if (kk.length > 0) {
      this.setState({
        active: key,
        //activeData: this.state.data[kk[0].value],
        activeData: this.state.data,
        activeTitle: kk[0].title,
      })
    }
  }

  loadOtherData = () => {
    let d = {
      dept_code: this.state.dept_code,
      type: 'FACULTY',
    }

    PostData(`/getemployeebytype`, d).then((resp) => {
      let search = this.props.location.search
      let searchObj = queryString.parse(search)
      let resp1 = resp.filter((el) => el.id != searchObj.id)

      this.setState({
        sameDepartment: resp1,
        isSameDepartmentLoaded: true,
      })
    })
  }

  callback1 = (key) => {
  
    this.setState({
      selectedQr: key
    })
  }

  render() {
    return (
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <BackTop />
        {this.state.isLoaded && (
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3} sm={12} md={3}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {Array.isArray(this.state.data.dp) &&
                  this.state.data.dp.length > 0 ? (
                    <React.Fragment>
                      <img
                        alt=""
                        src={this.state.data.dpurl[0]}
                        style={{
                          width: '200px',
                          boxShadow: '1px 2px 10px rgba(0,0,0,0.3)',
                        }}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <img
                        alt=""
                        src={'/images/faculty/default.jpg'}
                        style={{
                          width: '100px',
                          boxShadow: '1px 2px 10px rgba(0,0,0,0.3)',
                        }}
                      />
                    </React.Fragment>
                  )}

                  <br />
                </div>

                <div style={{ marginTop: 10 }}>
                  <Tabs
                    defaultActiveKey="0"
                    type="card"
                    tabPosition="left"
                    onChange={this.callback}
                  >
                    {smallTab.map((el, index) => (
                      <TabPane tab={el.title} key={index} />
                    ))}
                  </Tabs>
                </div>

                <br />

                <div>
                  {/* Links Tab */}

                  {this.state.data.others != null && (
                    <div style={{ listStyleType: 'none' }}>
                      {this.state.data.others.facebook_link != undefined && (
                        <li style={{marginBottom: 10, backgroundColor: 'blue', padding: 10}}>
                          <a
                            href={this.state.data.others.facebook_link}
                            target="_blank"
                            style={{ display: 'flex', alignItems: 'center', color: 'white', fontWeight: 700 }}
                          >
                            <FacebookOutlined /> &nbsp;&nbsp;Facebook Link
                          </a>
                        </li>
                      )}

                      {this.state.data.others.linkedin_link != '' && (
                        <li style={{marginBottom: 10, backgroundColor: 'blue', padding: 10}}>
                          <a
                            href={this.state.data.others.linkedin_link}
                            target="_blank"
                            style={{ display: 'flex', alignItems: 'center', color: 'white', fontWeight: 700 }}
                          >
                            <LinkedinOutlined /> &nbsp;&nbsp;LinkedIn Link
                          </a>
                        </li>
                      )}

                      {this.state.data.others.google_scholar != '' && (
                        <li style={{marginBottom: 10, backgroundColor: 'blue', padding: 10}}>
                          <a
                            href={this.state.data.others.google_scholar}
                            target="_blank"
                            style={{ display: 'flex', alignItems: 'center', color: 'white', fontWeight: 700 }}
                          >
                            <GoogleOutlined /> &nbsp;&nbsp;Google Scholars' Link
                          </a>
                        </li>
                      )}
                      {this.state.data.others.orchid_link != '' && (
                        <li style={{marginBottom: 10, backgroundColor: 'blue', padding: 10}}>
                          <a
                            href={this.state.data.others.orchid_link}
                            target="_blank"
                            
                          >
                            <div style={{ display: 'flex', alignItems: 'center', color: 'white', fontWeight: 700 }}>
                            <img src="/images/icons/orchid-logo.png" style={{width: 25, height: 25}} /> &nbsp;&nbsp;Orcid ID
                            </div>
                            
                            <a style={{color: 'white', fontWeight: 700, paddingLeft: 30}}>{this.state.data.others.orchid_link}</a>
                          </a>
                        </li>
                      )}
                    </div>
                  )}
                </div>


                <br />

                <div>
                  <div
                    style={{
                      borderLeft: 'solid 1px black',
                      paddingLeft: 10,
                      padding: 10,
                      marginBottom: 10,
                      backgroundColor: '#f9f9f9',
                    }}
                  >
                    Department
                  </div>
                  <Link to={`/department/${this.props.dept_code}`}>
                    <div
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 12,
                        fontWeight: 700,
                      }}
                    >
                      <BankOutlined /> &nbsp;&nbsp; {this.state.dept_name}
                    </div>
                  </Link>

                  <br />
                  <br />

                  <div
                    style={{
                      borderLeft: 'solid 1px black',
                      paddingLeft: 10,
                      padding: 10,
                      marginBottom: 10,
                      backgroundColor: '#f9f9f9',
                    }}
                  >
                   Reports of Same Department ({this.state.sameDepartment.length})
                  </div>
                  {Array.isArray(this.state.sameDepartment) &&
                    this.state.sameDepartment.map((el, index) => (
                      <a
                        href={`/department/${this.props.dept_code}/faculty/quarterlyreport?id=${el.id}&name=${el.name}`}
                      >
                        <div
                          key={index}
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 12,
                            fontWeight: 700,
                          }}
                        >
                          <UserOutlined /> &nbsp;&nbsp; {el.name}
                        </div>
                      </a>
                    ))}
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                lg={8}
                sm={12}
                md={8}
                style={{ textAlign: 'left', backgroundColor: '#f9f9f9' }}
              >
                <div>
                  <Card>
                  <Typography
                      variant="subtitle1"
                      style={{
                        fontWeight: 700,
                        fontSize: 24,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      Quarterly Report of
                    </Typography>


                    <Typography
                      variant="subtitle1"
                      style={{
                        fontWeight: 700,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <UserOutlined /> &nbsp;&nbsp;{this.state.data.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <BoxPlotOutlined /> &nbsp;&nbsp;
                      {this.state.data.designation}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <BankOutlined /> &nbsp;&nbsp;{this.state.dept_name}
                    </Typography>
                  </Card>
                </div>

                <br />

                <Card style={{ marginBottom: 10 }}>
                  <Typography variant="h6">Select the quarter</Typography>
                <Tabs   defaultActiveKey="ALL" onChange={this.callback1}>
    <TabPane tab="All" key="ALL" />
    {Array.isArray(this.state.qr) && this.state.qr.map((el,index) => <TabPane tab={el} key={el} />)}
    
  </Tabs>
  </Card>

                {smallTab.map((el, index) => (
                  <div
                    key={index}
                    ref={(ref) => {
                      this.state.rr[index] = ref
                    }}
                  >
                    <Card key={index} style={{ marginBottom: 10 }}>
                      <QuarterlyReportView
                        title={el.title}
                        {...this.state.data}
                        active={el.active}
                        qr={this.state.selectedQr}
                        id={this.state.id}
                      />
                    </Card>
                  </div>
                ))}
              </Grid>
              {/* <Grid
                item
                xs={12}
                lg={2}
                sm={12}
                md={2}
                style={{ textAlign: 'left' }}
              >
                
              </Grid> */}
            </Grid>
          </Container>
        )}

        {!this.state.isLoaded && (
          <Container>
            <div style={{ textAlign: 'center' }}>Loading....</div>
          </Container>
        )}
      </div>
    )
  }
}

export default FacultyQuarterlyReportLanding
