import { Container } from '@material-ui/core'
import { Divider } from 'antd'
import React from 'react'
import YouTube from 'react-youtube';


export default function VideoHomePage({viewTitle = true}) {

    const opts = {
        height: '250',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            loop: 1,
            controls: 1,
            mute: 1,
            iv_load_policy: 0,
            modestbranding: 1,
            rel: 0
        },
    }

    function _onReady(event){
        event.target.playVideo();
    }

    return (
        <div>
           
                {viewTitle && <Divider orientation="left" style={{ fontWeight: 700, fontSize: 20, borderColor: "#ABB2B9" }}>A Quick Tour of Our College</Divider>}

                <YouTube videoId="1vjReNpcE1c" opts={opts} onReady={_onReady}/>
            
        </div>
    )
}
