import React, { Component } from 'react'
import { Input } from 'reactstrap';
import './StickyButton.css'

export default class StickyButton extends Component {
  state = {
    isFormVisible: false
  };

  toggleForm = () => {
    this.setState(prevState => ({
        isFormVisible: !prevState.isFormVisible
    }));
  }

  closeForm = () => {
    this.setState({ isFormVisible: false });
  }

  render() {
    return (
      <div>
        <input type="button" value="Enquiry Form" className='enquiryBtn' onClick={this.toggleForm}/>

        {this.state.isFormVisible && (<div style={{width:'22%', height: '100%', position:'fixed', backgroundColor:'white', zIndex:1, top:0, display: 'flex', justifyContent:'center', boxShadow:'2px 0px 8px #87908D'}}>
        <div style={{width:'90%', display:'flex', flexDirection:'column'}}>
          <div style={{width: '100%', height:'10%'}}>
          <a style={{ position: 'absolute', top: 0, right: 30, fontSize: '3em', color: 'grey'}} onClick={this.closeForm}>
              &times;
            </a>
            </div>
            <div style={{width:'100%', height:'90%'}}>
            <form>
            <h1 style={{fontSize: '1.7em', color: '#6FC20B', fontWeight:'bold'}}>Request For Information</h1>
            <br/>
           <label style={{fontSize:'1.1em', color:'grey', fontWeight:'bold'}}>
            Name: <br/>
            <Input type="text" name="name" style={{width: '100% !important'}}/>
           </label>
           <br/>
           <label style={{fontSize:'1.1em', color:'grey', fontWeight:'bold'}}>
            Email:<br/>
            <Input type="email" name="email"/>
           </label>
           <br/>
           <label style={{fontSize:'1.1em', color:'grey', fontWeight:'bold'}}>
            Contact No:<br/>
            <Input type="text" name="contact"/>
           </label>
           <br/>
           <label style={{fontSize:'1.1em', color:'grey', fontWeight:'bold', borderRadius: '15px'}}>
            Message:<br/>
            <textarea name="message"/>
           </label>
           <br/>
           <input type='button' value="SUBMIT" style={{fontSize:'1em', backgroundColor:'DCE3E1', color:'#6FC20B', border:'2px solid #6FC20B', borderRadius:'20px', padding:'8px', fontWeight:'bold'}}/>
        </form> </div></div></div>)}
      </div>
    )
  }
}
