import React, { Component } from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import { PostData } from '../../api/service';

class GalleryDescription extends Component {
    render() {
        return (
            <div>
                {this.props.type == 'photo' ? (<SiteMap1 title={`Photo Gallery`} link="COLLEGE_PHOTO_GALLERY" />) : null}
                {this.props.type == 'document' ? (<SiteMap1 title={`Document Gallery`} link="COLLEGE_DOWNLOADS_GALLERY" />) : null}
                {this.props.type == 'video' ? (<SiteMap2 title={`Video Gallery`} link="COLLEGE_VIDEO_GALLERY" />) : null}
            </div>
        );
    }
}

export default GalleryDescription;

function SiteMap({ title }) {
    const [data, setData] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false);

    React.useEffect(() => {
        fetch(`/gallery/gallery.json`)
            .then(resp => resp.json())
            .then((resp) => {
                setData(resp);
                setIsLoaded(true)
            })
    }, [])


    return (
        <div>
            <Typography
                variant="h5"
                gutterBottom
                style={{ color: 'blue', fontWeight: 700 }}
            >
                {title}
            </Typography>

            <br />

            {isLoaded ? (<ImageGallery items={data} />) : <Typography>Loading . . . </Typography>}
        </div>
    )
}



function SiteMap1({ title, link }) {
    const [data, setData] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false);


    function fetchList() {
        let d = {
            type: link,
            dept_code: 'PRAG'
        }

        PostData(`/getnoticesbytypedept`, d)
            .then((resp) => {

                setData(resp);
                setIsLoaded(true)

            })
    }


    React.useState(() => {
        fetchList();
    }, [])


    function showImages(upload_info) {
        let i = [];
        return <Grid container spacing={2}>

            {upload_info.map((el, index) =>
                <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                    {el.fileType === 'image' && <img
                        alt="image"
                        src={el.url}
                        style={{ width: '100%' }}
                    />}

                    {el.fileType === 'file' && <a href={el.url} target="_blank">Download File</a>}
                </Grid>
            )}

        </Grid>

    }



    return (
        <div>
            <Typography
                variant="h5"
                gutterBottom
                style={{ color: 'blue', fontWeight: 700 }}
            >
                {title}
            </Typography>

            <br />

            {isLoaded ? <React.Fragment>

                <Grid container spacing={2}>

                    {Array.isArray(data) && data.map((el, index) =>

                        <Grid item key={index} xs={12} sm={12} lg={12} md={12}>

                            <Typography
                                variant="h6"

                            >
                                {el.title}
                            </Typography>


                            {Array.isArray(el.upload_info) ? showImages(el.upload_info) : null}

                            <Divider />
                        </Grid>

                    )}

                </Grid>

            </React.Fragment> : <Typography>Loading . . . .</Typography>}


        </div>
    )
}


function SiteMap2({ title, link }) {
    const [data, setData] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false);


    function fetchList() {
        let d = {
            type: link,
            dept_code: 'PRAG'
        }

        PostData(`/getnoticesbytypedept`, d)
            .then((resp) => {

                setData(resp);
                setIsLoaded(true)

            })
    }


    React.useState(() => {
        fetchList();
    }, [])


    function showImages(upload_info) {
        let i = [];
        return <Grid container spacing={2}>

            {upload_info.map((el, index) =>
                <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                    {el.fileType === 'image' && <img
                        alt="image"
                        src={el.url}
                        style={{ width: '100%' }}
                    />}

                    {el.fileType === 'file' && <a href={el.url} target="_blank">Download File</a>}
                </Grid>
            )}

        </Grid>

    }



    return (
        <div>
            <Typography
                variant="h5"
                gutterBottom
                style={{ color: 'blue', fontWeight: 700 }}
            >
                {title}
            </Typography>

            <br />

            {isLoaded ? <React.Fragment>

                <Grid container spacing={2}>

                    {Array.isArray(data) && data.map((el, index) =>

                        <Grid item key={index} xs={12} sm={12} lg={12} md={12}>

                            <Typography
                                variant="h6"

                            >
                                {el.title}
                            </Typography>

                          

                          <div dangerouslySetInnerHTML={{ __html: el.description}}></div>

                            <Divider />
                        </Grid>

                    )}

                </Grid>

            </React.Fragment> : <Typography>Loading . . . .</Typography>}
        </div>
    )
}

