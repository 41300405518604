import React, { Component, useState, useEffect } from "react";

import { MDBContainer } from "mdbreact";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Container, Typography, Grid } from "@material-ui/core";
import BreadCumb from "../Department/BreadCumb";
import { GetData } from "../../api/service";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";

export default class GoverningBody extends Component {
  state = {
    data: [],
    isLoaded: false,
  };

  componentDidMount() {
    GetData(`/getgbmembers`).then((resp) => {
      this.setState({
        data: resp,
        isLoaded: true,
      });
    });
  }

  render() {
    return (
      <div>
        <BreadCrumbOwn title="Governing Body" />

        <Container className="mb-5">
          <br />

          <div>
            <Typography
              variant="h5"
              style={{ margin: "1em 0", fontWeight: 700 }}
              gutterBottom
            >
              Governing Body
            </Typography>

            <br />

            <div>
              {this.state.isLoaded && (
                <>
                  <Grid container spacing={2}>
                    {this.state.data.map((el, index) => (
                      <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                        <Card>
                          <CardBody
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {Array.isArray(el.dp_url) &&
                                el.dp_url.map((el1) => (
                                  <img src={el1} style={{ width: 150 }} />
                                ))}
                            </div>
                            <div
                              style={{
                                paddingLeft: 20,
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="h6"
                                style={{ fontWeight: 700 }}
                                gutterBottom
                              >
                                {el.name}
                              </Typography>
                              <Typography variant="overline">
                                DESIGNATION
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 700 }}
                                gutterBottom
                              >
                                {el.designation}
                              </Typography>
                              <br />
                              <Typography variant="overline">
                                CONTACT
                              </Typography>
                              <Typography variant="body1">
                                <a href={`mailto:${el.email.toLowerCase()}`}>
                                  {el.email.toLowerCase()}
                                </a>
                              </Typography>
                            </div>
                          </CardBody>
                        </Card>
                        <br />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
