import React from "react";
import { CardActionArea, Container, Grid, Typography } from "@material-ui/core";
import SubscriptionModal from "../Essentials/SubscriptionModal";
import { Divider } from "antd";
import { Link } from "react-router-dom";
import { GetData } from "../../api/service";
import { Counter } from "../Essentials/WebCounter";
import {
  FacebookFilled,
  YoutubeFilled,
  InstagramFilled,
  MailFilled,
  TwitterOutlined,
} from "@ant-design/icons";

export default function PanduFooter() {
  const [comData, setComData] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    GetData(`/getcommittee`).then((resp) => {
      setComData(resp);
      setIsLoaded(true);
    });
  }, []);

  return (
    <div>
      <div className="footer p-4">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography variant="h6" style={{ color: "white", fontSize: 16 }}>
                Statutory Bodies
              </Typography>
              <div
                style={{
                  height: 2,
                  backgroundColor: "#52B267",
                  width: "60%",
                  marginTop: 10,
                }}
              ></div>
              <br />

              <ul style={{ listStyleType: "none" }}>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="https://www.gauhati.ac.in/"
                  >
                    Gauhati University
                  </a>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="https://dibru.ac.in/"
                  >
                    Dibrugarh university
                  </a>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="http://www.tezu.ernet.in/"
                  >
                    Tezpur University
                  </a>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="http://rusa.nic.in/"
                  >
                    RUSA
                  </a>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="https://www.ugc.ac.in/"
                  >
                    UGC
                  </a>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="https://dheonlineadmission.amtron.in/sp/"
                  >
                    DHE
                  </a>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="http://naac.gov.in/index.php/en/"
                  >
                    NAAC
                  </a>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="http://www.kkhsou.in/web_new/"
                  >
                    KKHSOU
                  </a>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="http://www.ignou.ac.in/"
                  >
                    IGNOU
                  </a>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="http://www.gucdoe.in/"
                  >
                    GUCDOE
                  </a>
                </li>
              </ul>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography variant="h6" style={{ color: 'white', fontSize: 16 }}>Committees and Cells</Typography>
              <div style={{ height: 2, backgroundColor: "#52B267", width: '60%', marginTop: 10 }}></div>
              <br />

              {isLoaded && <ul style={{ listStyleType: "none" }}>
                {Array.isArray(comData) && comData.map((el, index) => <li key={index} style={{ marginLeft: "-40px", color: 'white', fontSize: 14 }}><Link to="/committees" style={{ color: "yellow" }}>{el.name}</Link></li>)}


              </ul>}
            </Grid> */}
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography variant="h6" style={{ color: "white", fontSize: 16 }}>
                Admission & Portals
              </Typography>
              <div
                style={{
                  height: 2,
                  backgroundColor: "#52B267",
                  width: "60%",
                  marginTop: 10,
                }}
              ></div>
              <br />

              <ul style={{ listStyleType: "none" }}>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="http://admission.pragjyotishcollege.ac.in/"
                  >
                    Admission Portal
                  </a>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="http://user.pragjyotishcollege.ac.in/"
                  >
                    Teacher's Portal
                  </a>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="http://student.pragjyotishcollege.ac.in/"
                  >
                    Student's Portal
                  </a>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="http://admin.pragjyotishcollege.ac.in/"
                  >
                    Admin Portal
                  </a>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="https://dheonlineadmission.amtron.in/sp/"
                  >
                    DHE Unique ID Portal
                  </a>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="https://guportal.in/"
                  >
                    GU Portal
                  </a>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography variant="h6" style={{ color: "white", fontSize: 16 }}>
                Quick Links
              </Typography>
              <div
                style={{
                  height: 2,
                  backgroundColor: "#52B267",
                  width: "60%",
                  marginTop: 10,
                }}
              ></div>
              <br />

              <ul style={{ listStyleType: "none" }}>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <Link style={{ color: "yellow" }} to="/recruitment">
                    Recruitment
                  </Link>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <Link style={{ color: "yellow" }} to="/nirf">
                    NIRF
                  </Link>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <Link style={{ color: "yellow" }} to="/rti/home">
                    RTI
                  </Link>
                </li>

                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <Link style={{ color: "yellow" }} to="/rti/officers">
                    RTI officers
                  </Link>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <Link style={{ color: "yellow" }} to="/contactus">
                    Contact Us
                  </Link>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <Link style={{ color: "yellow" }} to="/notifications/tender">
                    Tender
                  </Link>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <Link style={{ color: "yellow" }} to="/gallery/document">
                    Documents Gallery
                  </Link>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <Link style={{ color: "yellow" }} to="/gallery/photo">
                    Photo Gallery
                  </Link>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <Link style={{ color: "yellow" }} to="/gallery/video">
                    Video Gallery
                  </Link>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <a
                    style={{ color: "yellow" }}
                    target={"_blank"}
                    href="http://grievance.pragjyotishcollege.ac.in/"
                  >
                    Grievance Redressel
                  </a>
                </li>
              </ul>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography variant="h6" style={{ color: "white", fontSize: 16 }}>
                Information
              </Typography>
              <div
                style={{
                  height: 2,
                  backgroundColor: "#52B267",
                  width: "60%",
                  marginTop: 10,
                }}
              ></div>
              <br />

              <ul style={{ listStyleType: "none" }}>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <Link style={{ color: "yellow" }} to="/contactus">
                    Contact Us
                  </Link>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <Link style={{ color: "yellow" }} to="/students/results">
                    Exam Results
                  </Link>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <Link style={{ color: "yellow" }} to="/students/routines">
                    Class Routines
                  </Link>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <Link
                    style={{ color: "yellow" }}
                    to="/students/exam-schedule"
                  >
                    Exam Schedules
                  </Link>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <Link style={{ color: "yellow" }} to="/students/downloads">
                    Downloads
                  </Link>
                </li>
                <li
                  style={{ marginLeft: "-40px", color: "white", fontSize: 14 }}
                >
                  <Link style={{ color: "yellow" }} to="/importantfile">
                    Important Information
                  </Link>
                </li>
              </ul>
            </Grid>
          </Grid>
          <br />

          <Divider
            orientation="left"
            style={{ color: "white", borderColor: "white" }}
          >
            CONTACT
          </Divider>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography variant="h6" style={{ color: "white", fontSize: 16 }}>
                Office Address
              </Typography>
              <div
                style={{
                  height: 2,
                  backgroundColor: "#52B267",
                  width: "60%",
                  marginTop: 10,
                }}
              ></div>
              <br />
              <Typography style={{ color: "white", fontSize: 14 }}>
                Dr. Manoj Kumar Mahanta,
                <br />
                Principal,Pragjyotish College
                <br />
                Santipur,Guwahati-781009
                <br />
                Assam (India)
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography variant="h6" style={{ color: "white", fontSize: 16 }}>
                RTI (Appellate Authority)
              </Typography>
              <div
                style={{
                  height: 2,
                  backgroundColor: "#52B267",
                  width: "60%",
                  marginTop: 10,
                }}
              ></div>
              <br />
              <Typography style={{ color: "white", fontSize: 14 }}>
                Appellate Authority: Dr. Manoj Kumar Mahanta <br />
                Designation: Principal
                <br />
                Email: principal@pragjyotishcollege.ac.in
                <br />
              </Typography>
              <br />
              <Typography style={{ color: "white", fontSize: 14 }}>
                First Information Officer: Dr. Shampa Choudhury
                <br />
                Designation: Vice-Principal
                <br />
                Email: viceprincipal@pragjyotishcollege.ac.in
                <br />
              </Typography>
              <br />
              <Typography style={{ color: "white", fontSize: 14 }}>
                Second Information Officer: Dr. Amar Jyoti Dutta
                <br />
                Designation: Assistant Professor
                <br />
                Phone No: 9864305333
                <br />
                Email: amar@pragjyotishcollege.ac.in
                <br />
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography variant="h6" style={{ color: "white", fontSize: 16 }}>
                Distance & Online Learning Centers
              </Typography>
              <div
                style={{
                  height: 2,
                  backgroundColor: "#52B267",
                  width: "60%",
                  marginTop: 10,
                }}
              ></div>
              <br />
              <Typography variant="overline"><a href="/kkhsou" style={{color: "yellow"}}>KKHSOU</a></Typography>
              <Typography style={{ color: "white", fontSize: 14 }}>
                Dr. Manjit Kr. Mazumdar
                <br />
                Associate Professor
                <br />
                Geology Department
                <br />
                Phone: 9706433118
              </Typography>
              <Typography variant="overline"><a href="/gucdoe" style={{color: "yellow"}}>GUCDOE</a></Typography>
              <Typography style={{ color: "white", fontSize: 14 }}>
                Pradip Chandra Kalita
                <br />
                Assistant Professor
                <br />
                Geography Department
                <br />
                Phone: 98640 42577
              </Typography>
              <Typography variant="overline"><a href="/ignou" style={{color: "yellow"}}>IGNOU</a></Typography>
              <Typography style={{ color: "white", fontSize: 14 }}>
                Dr. Amarjyoti Dutta
                <br />
                Assistant Professor
                <br />
                Mathematics Department
                <br />
                Phone: 9864305333
                <br />
                Email: amar@pragjyotishcollege.ac.in
              </Typography>
              <Typography variant="overline"><a href="/nptel" style={{color: "yellow"}}>SWAYAM-NPTEL LOCAL CHAPTER</a></Typography>
              <Typography style={{ color: "white", fontSize: 14 }}>
                Dr. Amit Kumar Pradhan
                <br />
                Assistant Professor
                <br />
                Botany Department
                <br />
                Phone: 8761864952
                <br />
                Email: akpradhan@pragjyotishcollege.ac.in
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography variant="h6" style={{ color: "white", fontSize: 16 }}>
                Page Statistics
              </Typography>
              <div
                style={{
                  height: 2,
                  backgroundColor: "#52B267",
                  width: "60%",
                  marginTop: 10,
                }}
              ></div>
              <br />

              <Counter />
              <br />

              <Link to="/visitors-note">
                <div
                  style={{
                    border: "solid 2px yellow",
                    width: "50%",
                    padding: 4,
                    textAlign: "center",
                  }}
                >
                  <span style={{ color: "yellow", fontWeight: 700 }}>Visitor's Note</span>
                </div>
              </Link>
              <br />

              <Typography variant="h6" style={{ color: "white", fontSize: 16 }}>
                Social Links
              </Typography>
              <div
                style={{
                  height: 2,
                  backgroundColor: "#52B267",
                  width: "60%",
                  marginTop: 10,
                }}
              ></div>
              <br />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <a href="https://www.facebook.com/pragjyotishcollege" target="_blank" style={{ color: "yellow" }} href="#">
                  <FacebookFilled style={{ fontSize: 25 }} />
                </a>
                &nbsp;
                <a
                  target="_blank"
                  style={{ color: "yellow" }}
                  href={
                    "https://www.instagram.com/pragjyotish_college_?igsh=cjV6d3psNm1pY3Vt"
                  }
                >
                  <InstagramFilled style={{ fontSize: 25 }} />
                </a>
                &nbsp;
                <a
                  target="_blank"
                  style={{ color: "yellow" }}
                  href={"mailto:principal@pragjyotishcollege.ac.in"}
                >
                  <MailFilled style={{ fontSize: 25 }} />
                </a>
                &nbsp;
                {/* <a target="_blank" style={{ color: "yellow" }} href={"#"}>
                  <YoutubeFilled style={{ fontSize: 25 }} />
                </a>
                &nbsp; */}
                <a
                  target="_blank"
                  style={{ color: "yellow" }}
                  href={
                    "https://x.com/pragjyotish2023"
                  }
                >
                  <TwitterOutlined style={{ fontSize: 25 }} />
                </a>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          backgroundColor: "#202020",
          minHeight: 60,
          display: "flex",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        <Container
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="text-white font-weight-light">
            ©{new Date().getFullYear()} Pragjyotish College
          </div>
          <div className="text-white font-weight-light">
            Designed & Developed With &#x2764; by Corexx
          </div>
        </Container>
      </div>
    </div>
  );
}
