import React, { Component } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Container,
} from "@material-ui/core";
import { MDBContainer } from "mdbreact";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { GetData } from "../api/service";

class SyllabusContainer extends Component {
  state = {
    data: [],
    isLoaded: false,
  };

  componentDidMount() {
    GetData(`/getdepartments`).then((resp) => {
      this.setState({
        data: resp,
        isLoaded: true,
      });
    });
  }
  render() {
    return (
      <div>
        <BreadCrumbOwn title="Syllabi & Courses" />
        <Container style={{ paddingTop: "40px", minHeight: "700px" }}>
          {this.state.isLoaded && (
            <div>
              <Typography variant="h6" gutterBottom>
                Click on the links below to visit
              </Typography>
              <ul>
                {Array.isArray(this.state.data) &&
                  this.state.data.map((el, index) => (
                    <li key={index}>
                      <a
                        style={{ fontSize: 14 }}
                        targte="_blank"
                        href={`/department/${el.dept_code}/course`}
                      >
                        Syllabi & Course of {el.dept_name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </Container>
      </div>
    );
  }
}

export default SyllabusContainer;
