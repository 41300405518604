import React, { Component } from 'react'
import { Container, Grid, Typography } from '@material-ui/core'
import { MDBContainer } from 'mdbreact'
import BreadCrumbOwn from '../Essentials/BreadCrumbOwn'

class RtiDescription extends Component {
  render() {
    return (
      <div style={{ marginTop: '' }}>
        {this.props.type == 'home' ? (
          <History title="RIGHT TO INFORMATION" />
        ) : null}
        {this.props.type == 'officers' ? (
          <Officers title="INFORMATION OFFICERS" />
        ) : null}
        {/* {this.props.type == 'code' ? <Code title="CODE OF CONDUCT" /> : null}
        {this.props.type == 'selfdec' ? <SelfDec title="Self Declaration" /> : null}
        {this.props.type == 'act' ? <Act title="RIGHT TO INFORMATION ACT, 2005" /> : null}
        {this.props.type == 'ief' ? <Ief title="INFORMATION IN ELECTRONIC FORM" /> : null}
        {this.props.type == 'pac' ? <Pac title="PARTICULARS AVAILALE TO THE CITIZENS" /> : null} */}
      </div>
    )
  }
}

export default RtiDescription

export const History = (props) => {
  return (
    <div>
      <BreadCrumbOwn title="Right to Information" />
      <Container className='mb-5'>
        <Typography variant="h5" style={{ margin: '1em 0', fontWeight: 700 }} gutterBottom>
          Right to Information
        </Typography>


        <Typography variant="body1" gutterBottom style={{ textAlign: "justify" }}>
          Right to Information (RTI) Act, 2005 mandates timely response to citizens’ requests for government information. The basic object of the Right to Information Act is to empower the citizens, promote transparency and accountability in the working of the government. Keeping in line with the RTI objectives, Pragjyotish College has also constituted the RTI Cell to enable the students, citizens and the stakeholders to have the necessary access to the RTI related information/disclosures. Pragjyotish College RTI Cell makes speedy and timely disposal of the queries and grievances lodged by individuals or stakeholders from time to time. We assert in enabling the general public to have greater access to the domain of information systematically preserved by our office.
        </Typography>
        <br />
        <Typography variant="h5" style={{ margin: '1em 0', fontWeight: 700 }} gutterBottom>
          Fees
        </Typography>
        <Typography variant="body1" gutterBottom style={{ textAlign: "justify" }}>

          A citizen who desires to seek some information from The PIO (Public Information Officer) is required to send, along with the application (a Postal order or DD (Demand draft) or a bankers cheque) payable to The Principal, Pragjyotish College, Guwahati as fee prescribed for seeking information. If the person is from a disadvantaged community, he/she need not pay. The applicant may also be required to pay further fees towards the cost of providing the information, details of which shall be intimated to the applicant by the PIO as prescribed by the RTI ACT. 2005.
        </Typography>
      </Container>
    </div>
  )
}

export const Officers = (props) => {
  return (
    <div>
      <BreadCrumbOwn title="Information Officers" />
      <Container className='mb-5'>

        <Typography variant="h5" style={{ margin: '1em 0', fontWeight: 700 }} gutterBottom>
          Information Officers
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="h6" style={{ margin: '1em 0', fontWeight: 700 }} gutterBottom>
              First Appellate Authority
            </Typography>
            <Typography variant="body1">
              Name: <strong>Dr. Manoj Kumar Mahanta</strong><br />
              Designation: <strong>Principal</strong><br />
              Email: <strong><a href={`mailto:principal@pragjyotishcollege.ac.in`}>principal@pragjyotishcollege.ac.in</a></strong><br />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="h6" style={{ margin: '1em 0', fontWeight: 700 }} gutterBottom>
              First Information Officer
            </Typography>
            <Typography variant="body1">
              Name: <strong>Dr. Shampa Choudhury</strong><br />
              Designation: <strong>Vice-Principal</strong><br />
              Email: <strong><a href={`mailto:viceprincipal@pragjyotishcollege.ac.in`}>viceprincipal@pragjyotishcollege.ac.in</a></strong><br />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="h6" style={{ margin: '1em 0', fontWeight: 700 }} gutterBottom>
              Member
            </Typography>
            <Typography variant="body1">
              Name: <strong>Pradip Chandra Kalita</strong><br />
              Designation: <strong>Assistant Professor</strong><br />
              Email: <strong><a href={`mailto:pkalita73@gmail.com`}>pkalita73@gmail.com</a></strong><br />
              Phone: <strong><a href={`tel:9864042577`}>9864042577</a></strong><br />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="h6" style={{ margin: '1em 0', fontWeight: 700 }} gutterBottom>
              Second Information Officer
            </Typography>
            <Typography variant="body1">
              Name: <strong>Dr. Amar Jyoti Dutta</strong><br />
              Designation: <strong>Assistant Professor</strong><br />
              Email: <strong><a href={`mailto:amar@pragjyotishcollege.ac.in`}>amar@pragjyotishcollege.ac.in</a></strong><br />
              Phone: <strong><a href={`tel:9864305333`}>9864305333</a></strong><br />
            </Typography>
          </Grid>
        </Grid>












      </Container>
    </div>
  )
}

export const Code = (props) => {
  return (
    <div>
      <MDBContainer style={{ paddingTop: '20px' }}>
        <Typography variant="h5" style={{ margin: '1em 0', fontWeight: 700 }} gutterBottom>
          {props.title}
        </Typography>


      </MDBContainer>
    </div>
  )
}



export const SelfDec = (props) => {
  return (
    <div>
      <MDBContainer style={{ paddingTop: '20px' }}>
        <Typography variant="h5" style={{ margin: '1em 0', fontWeight: 700 }} gutterBottom>
          {props.title}
        </Typography>

        <p>This is to declare that Thong Nokbe College, Dokmoka, Karbi Anglong, Assam complies with sec 4 (1) (b) of the RTI Act, 2005 as issued and amended from time to time.</p>

        <br />

        <table className='table table-bordered'>
          <tbody>
            <tr>
              <td colSpan={2}>Information Under Section 4(1)(b)</td>
            </tr>
            <tr>
              <td>The Particulars of its organisation, functions and duties:</td>
              <td>Thong Nokbe College, Dokmoka was established in the year 1986, situated in the Dokmoka, Dist: Karbi Anglong, Assam, affiliated to Assam University with the objective of providing access to students aspiring for Higher Education.</td>
            </tr>
            <tr>
              <td>The powers and Duties of its Officers and Employees:</td>
              <td>The Institution is governed by the rules and regulations of UGC and Govt. Of Assam</td>
            </tr>
            <tr>
              <td>The procedure followed in the decision making process including supervision and accountability:</td>
              <td>G.B is the authority of decision making and the Institution complies with rules and regulations set by the UGC, Assam University and Govt of Assam. Principal is the head of the institution and act as Secretary in the Governing Body.
              </td>
            </tr>
            <tr>
              <td>The norms set by it for the discharge of its function
              </td>
              <td>The Institution follows the guidelines of the UGC, Govt of Assam as well as the rules and regulations of Assam University.
              </td>
            </tr>
            <tr>
              <td>The rules, regulations, instructions, manuals and records held by it or under its control or used by its employees for discharging its function:
              </td>
              <td>UGC Act 1956, The Assam Venture Educational Institution (Provincialisation of Services) Act 2011 of Govt. Of Assam and Rules made by the Governing Body of the Institution.
              </td>
            </tr>
            <tr>
              <td>A statement of the categories of documents that are held by it or under its control:</td>
              <td>Files on administration and academic matters, rules, procedures regarding the functioning of the institutions are held by it or under its control.
              </td>
            </tr>
            <tr>
              <td>The particulars of any arrangement that exists for consultation under or representation by the members of the public in relation to the formulation of its policy or implementation there off:
              </td>
              <td>Governing Body has public representation as member and the members have a say in the formulation of Policy.
              </td>
            </tr>
            <tr>
              <td>A statement of the boards, councils, committees and other bodies consisting of two or more persons constituted as its part or for the purpose of its advise and as to whether meetings of those boards, councils, committees and other bodies are open to the public or the minutes of such meetings are accessible for the public:
              </td>
              <td>The Minutes of the college G.B meetings are made accessible for the public when it is called for as per procedure.
              </td>
            </tr>
            <tr>
              <td>The particulars of facilities available to citizens for obtaining information including the working hours of a library or reading room, if maintained for public:
              </td>
              <td>From website of the Institution or by making request at the Institution’s office or by email particulars of facilities can be obtained.
              </td>
            </tr>
            <tr>
              <td>P.R.O
              </td>
              <td>Principal and Appointed Public Information Officers of the Institution.
              </td>
            </tr>
          </tbody>

        </table>
      </MDBContainer>
    </div>
  )
}


export const Act = (props) => {
  return (
    <div>
      <MDBContainer style={{ paddingTop: '20px' }}>
        <Typography variant="h5" style={{ margin: '1em 0', fontWeight: 700 }} gutterBottom>
          {props.title}
        </Typography>

        {/* <iframe
          src={`/pdf/RTI_15jun05.pdf`}
          width="100%"
          height="800px"
        ></iframe>

        <br />

        <iframe
          src={`/pdf/memorandum_RTI.pdf`}
          width="100%"
          height="800px"
        ></iframe> */}
      </MDBContainer>
    </div>
  )
}


export const Ief = (props) => {
  return (
    <div>
      <MDBContainer style={{ paddingTop: '20px' }}>
        <Typography variant="h5" style={{ margin: '1em 0', fontWeight: 700 }} gutterBottom>
          {props.title}
        </Typography>


        {/* <Typography variant="body1" gutterBottom>
      Following information is available in electronic form in office computers.
<br />
1.     Salary, GPF Accounts, CPS Accounts and Income Tax
<br />
2.     Construction related tender documents
<br />
3.     Library Collection and Issues
      </Typography> */}
      </MDBContainer>
    </div>
  )
}

export const Pac = (props) => {
  return (
    <div>
      <MDBContainer style={{ paddingTop: '20px' }}>
        <Typography variant="h5" style={{ margin: '1em 0', fontWeight: 700 }} gutterBottom>
          {props.title}
        </Typography>


        {/* <Typography style={{textAlign: 'center'}} variant="body1" gutterBottom>
      THE PARTICULARS OF FACILITIES AVAILABLE TO THE CITIZENS FOR OBTAINING INFORMATION DURING WORKING HOURS OF THE COLLEGE
      </Typography>

      <Typography variant="body1" gutterBottom>
      a. Working hour:<br />
      All the teaching departments, administrative office and central library of the college function six days a week from Monday to Saturday. Normal working hours of the college is 9 to 5. Library facility (reading only) is available to the members of the public. Any person may visit the library with the permission of the librarian and use the leading room facility on all working days
      </Typography>

      <Typography variant="body1" gutterBottom>
      b. The Citizens can obtain information from the concern departments and section of the college <br />
      </Typography>  */}

        {/* <table style={{ fontWeight: 500 }} className="table table-sm table-bordered">
  <tbody>
    <tr>
      <td width={57}>
        <p>
          <strong>Sl. No.</strong>
        </p>
      </td>
      <td width={164}>
        <p>
          <strong>Matters</strong>
        </p>
      </td>
      <td width={269}>
        <p>
          <strong>Whom to contact during working hours</strong>
        </p>
      </td>
    </tr>
    <tr>
      <td width={57}>
        <p>1</p>
      </td>
      <td width={164}>
        <p>About courses offered by the college</p>
      </td>
      <td width={269}>
        <p>All the Head of the Departments</p>
      </td>
    </tr>
    <tr>
      <td width={57}>
        <p>2</p>
      </td>
      <td width={164}>
        <p>Admission and admission related matter</p>
      </td>
      <td width={269}>
        <p>
          Coordinator, Admission Committee at the time of admission and College
          Office any time during working hours
        </p>
      </td>
    </tr>
    <tr>
      <td width={57}>
        <p>3</p>
      </td>
      <td width={164}>
        <p>Recruitment</p>
      </td>
      <td width={269}>
        <p>Principal</p>
      </td>
    </tr>
    <tr>
      <td width={57}>
        <p>4</p>
      </td>
      <td width={164}>
        <p>Purchase/ Construction works</p>
      </td>
      <td width={269}>
        <p>Coordinator, Purchase Committee/Construction Committee</p>
      </td>
    </tr>
    <tr>
      <td width={57}>
        <p>5</p>
      </td>
      <td width={164}>
        <p>Financial Matters</p>
      </td>
      <td width={269}>
        <p>College Accountant</p>
      </td>
    </tr>
    <tr>
      <td width={57}>
        <p>6</p>
      </td>
      <td width={164}>
        <p>Hostels</p>
      </td>
      <td width={269}>
        <p>Superintendents of Boys’/Girls’ Hostel</p>
      </td>
    </tr>
    <tr>
      <td width={57}>
        <p>7</p>
      </td>
      <td width={164}>
        <p>Fees Structure/Free Admission</p>
      </td>
      <td width={269}>
        <p>Admission Committee</p>
      </td>
    </tr>
    <tr>
      <td width={57}>
        <p>8</p>
      </td>
      <td width={164}>
        <p>Students’ Data Base</p>
      </td>
      <td width={269}>
        <p>Coordinator, ICGC</p>
      </td>
    </tr>
    <tr>
      <td width={57}>
        <p>9</p>
      </td>
      <td width={164}>
        <p>Student Welfare</p>
      </td>
      <td width={269}>
        <p>Coordinator, Student Welfare Committee</p>
      </td>
    </tr>
  </tbody>
</table> */}

      </MDBContainer>
    </div>
  )
}