import React, { Component } from 'react'

import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, Button } from 'mdbreact';
import { Card, CardHeader } from 'reactstrap';
import BreadCumb from '../Department/BreadCumb';
import { PostData } from '../../api/service';
import { Container, Typography } from '@material-ui/core';
import BreadCrumbOwn from '../Essentials/BreadCrumbOwn';



// const facultyData = [
//     {
//         name: 'Dr. Jogesh Kakati',
//         desg: 'Principal',
//         qualification: 'MA, PhD',
//         email: '',
//         phone: '',
//         mobile: '',
//         specifications: '',
//         cv: '',
//         photo: ''
//     },

// ]


export default class PrincipalContainer extends Component {

    state = {
        facultyData: [],
        isLoaded: false,
        dept_code: 'OFF'
    }

    componentDidMount() {


        let d = {
            dept_code: 'OFF',
            type: 'PRINCIPAL'
        }

        PostData(`/getemployeebytype`, d)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    ...resp[0],
                    facultyData: resp,
                    isLoaded: true
                })
            })
    }
    render() {
        return (
            <div>
                <BreadCrumbOwn title="Principal's Message" />


                <Container className='mb-5'>

                    <br />

                    {this.state.isLoaded && this.state.facultyData.map((el, index) => {
                        return <div>
                            {/* <Card style={{ boxShadow: 'none' }}>
                            <CardHeader style={{ color: 'white', boxShadow: 'none', backgroundColor: '#1E3760', textAlign: 'center' }}> <h3 style={{color: 'inherit'}}>{el.desg}</h3></CardHeader>
                        </Card> */}

                            <div>
                               
                                <div>
                                    <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                                        Principal's Message
                                    </Typography>
                                    <Typography style={{ textAlign: "justify" }} variant="body1" gutterBottom>
                                    It is a matter of utmost privilege and pleasure to extend a warm and heartfelt welcome to all students aspiring to pursue their education across various programs offered by this premier higher educational institution in the Northeast region. Pragjyotish College began with a humble initiative by a group of visionary leaders from the area to educate and generate learned citizens for the upliftment of the society. Since its inception, the college has made remarkable progress and stands today as one of the most distinguished co-educational institution in the entire Northeast region. The institution today is a post graduate college with different programs ranging from higher secondary education to postgraduate studies along with professional studies like mass communication & journalism, tourism management and business management. Our institution is the top choice for many students across the region. Students from across the north-eastern states of India, as well as neighbouring countries, have flourished here, excelling in both academics and extracurricular activities. Pragjyotish College fosters a liberal, vibrant, and competitive environment, enabling our learners to carve out a niche for themselves in their respective fields.
                                    </Typography>
                                    <br />

                                    <Typography style={{ textAlign: "justify" }} variant="body1" gutterBottom>
                                    We believe in the all-round development of our students. Beyond academic pursuits, we place significant emphasis on a range of co-curricular activities, discipline, and character building. Pragjyotish College stands out with its full-fledged National Cadet Corps (NCC) Unit, featuring four wings: Army, Navy, Air, and the Girls' Wing. Additionally, our college offers platforms for social involvement through the National Service Scheme (NSS), Red Ribbon Club, Bharat Scouts and Guides, and the Youth Red Cross.
                                    </Typography>
                                    <br />
                                    <Typography style={{ textAlign: "justify" }} variant="body1" gutterBottom>
                                    Pragjyotish College is dedicated to fostering both online and offline education of international standards. We have established Memorandums of Understanding (MoUs) with premier institutes across India and abroad. Our institute is a recognized NPTEL Local Chapter under the initiative of MHRD. This ensures our students to have access across top-tier online courses and resources.
                                    </Typography>
                                    <br />
                                    <Typography style={{ textAlign: "justify" }} variant="body1" gutterBottom>
                                    Moreover, we provide skill-based and innovative entrepreneurial programs aimed at the upliftment of our students, equipping them with the knowledge and skills needed to succeed in today's competitive world. Our commitment to comprehensive education ensures that students not only excel academically but also develop the character and skills necessary for personal and professional success.

                                    </Typography>

                                    <br />
                                    <Typography style={{ textAlign: "justify" }} variant="body1" gutterBottom>
                                    With these few words, I once again warmly welcome you to this esteemed institution and pray to the Almighty for your resounding success and excellence. I sincerely wish that, after your admission, you become actively involved in the vibrant life of the college and demonstrate exemplary excellence in academics. May you embed your name in the long and illustrious list of proud alumni of Pragjyotish College.
                                    </Typography>

                                    <br />
                                    <div>
                                        <div style={{ textAlign: 'right' }}>
                                            <img
                                                alt=""
                                                src={el.dp.length > 0 ? el.dp[0] : ''}
                                                style={{
                                                    width: '250px',
                                                    boxShadow: '1px 2px 10px rgba(0,0,0,0.1)',
                                                    border: `solid 5px wheat`
                                                }}
                                            />
                                        </div>

                                        <h5 style={{ fontSize: '16px', textAlign: 'right', fontWeight: 700 }}>{el.name}</h5>
                                        <h6 style={{ fontSize: '12px', textAlign: 'right' }}><a href={`mailto:${el.email}`}>{el.email}</a></h6>
                                        <h6 style={{ fontSize: '12px', textAlign: 'right' }}><a href={`tel:${el.phone}`}>{el.phone}</a></h6>
                                        <h6 style={{ fontSize: '12px', textAlign: 'right' }}><a href={`tel:${el.mobile}`}>{el.mobile}</a></h6>
                                        <h6 style={{ fontSize: '12px', textAlign: 'right' }}><a href={`tel:${el.mobile}`}>
                                            
                                            
                                            
                                            
                                            {this.state.isLoaded && this.state.id != undefined && <a style={{ color: '#1892FF', cursor: 'pointer', fontWeight: 700, textAlign: "right" }}
                                            
                                            // href={`/department/${this.state.dept_code}/faculty/single?id=${this.state.id}&name=${this.state.name}`}
                                            href={el.cv.length > 0 ? el.cv[0] : ''}
                                            
                                            >Visit Profile</a>}</a></h6>


                                        
                                    </div>

                                </div>


                            </div>
                        </div>

                    })}






                </Container>

            </div>
        )
    }
}