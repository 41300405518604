import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";
import NotificationDescription from "../Notifications/NotificationDescription";

class ResearchProjects extends Component {
  render() {
    return (
      <div>
        <BreadCrumbOwn title="Research Projects" />
        <Container className="mb-5">
        <NotificationDescription code="COLLEGE_RESEARCH_PROJECTS" title="Research Projects" />
        </Container>
      </div>
    );
  }
}

export default ResearchProjects;
