import React, { Component } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Typography } from "@material-ui/core";
import { Menu } from "antd";
import { MenuOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
const { SubMenu } = Menu;

class DepartmentNavigation extends Component {
  state = {
    current: "",
  };

  handleClick = (e) => {
    if (e.key != 0) {
      this.props.history.push(e.key);
      this.setState({
        current: e.key,
      });
    }
  };
  render() {
    let menu = [
      {
        title: "ABOUT",
        path: `/department/${this.props.dept_code}`,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "PROFILE",
        path: `/department/${this.props.dept_code}/profile`,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "COURSES & PROGRAMMES",
        path: `/department/${this.props.dept_code}/course`,
        hasSubnav: false,
        subnav: [],
      },
      // {
      //     title: 'VISION',
      //     path: `/department/${this.props.dept_code}/vision`,
      //     hasSubnav: false,
      //     subnav: []
      // },
      // {
      //     title: 'DEPARTMENT AT A GLANCE',
      //     path: `/department/${this.props.dept_code}/glance`,
      //     hasSubnav: false,
      //     subnav: []
      // },
      {
        title: "FACULTY",
        path: `/department/${this.props.dept_code}/faculty`,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "FORMER FACULTY",
        path: `/department/${this.props.dept_code}/former-faculty`,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "STAFF",
        path: `/department/${this.props.dept_code}/staff`,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "ALUMNI",
        path: `/department/${this.props.dept_code}/alumni`,
        hasSubnav: false,
        subnav: [],
      },

      // {
      //     title: 'SYLLABUS',
      //     path: `/department/${this.props.dept_code}/syllabus`,
      //     hasSubnav: false,
      //     subnav: []
      // },
      {
        title: "JOURNAL & MAGAZINES",
        path: `/department/${this.props.dept_code}/journal`,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "EVENTS",
        path: `/department/${this.props.dept_code}/events`,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "NEWS & NOTIFICATIONS",
        path: `/department/${this.props.dept_code}/notices`,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "WALL-MAGAZINE",
        path: `/department/${this.props.dept_code}/wall-magazine`,
        hasSubnav: false,
        subnav: [],
      },

      {
        title: "ACHIEVEMENTS",
        path: `/department/${this.props.dept_code}/achievements`,
        hasSubnav: false,
        subnav: [],
      },

      {
        title: "MORE",
        path: `##234`,
        hasSubnav: true,
        subnav: [
          {
            title: "COs & POs",
            path: `/department/${this.props.dept_code}/co_po`,
            hasSubnav: false,
            subnav: [],
          },
          {
            title: "GALLERY",
            path: `/department/${this.props.dept_code}/gallery`,
            hasSubnav: false,
            subnav: [],
          },
          {
            title: "CONTACT DETAILS",
            path: `/department/${this.props.dept_code}/contact`,
            hasSubnav: false,
            subnav: [],
          },
          {
            title: "OPEN EDUCATIONAL RESOURCE (OER)",
            link: `http://103.68.53.214:8080/jspui/library/resource.html`,
            hasSubnav: false,
            isLink: true,
            subnav: [],
          },
          {
            title: "STUDENT LOGIN",
            link: `https://student.pragjyotishcollege.ac.in`,
            hasSubnav: false,
            isLink: true,
            subnav: [],
          },
        ],
      },
    ];
    return (
      <div>
        {/* <div style={{padding: '1px 10px 1px 10px'}}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: '15vh',
                            width: '100%',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}

                        onClick={() => this.props.history.push('/')}
                    >
                        <div
                            style={{
                                align: 'center'
                            }}
                        >
                            <img
                                src={'/images/header/logo.png'}
                                style={{
                                    width: '70px'
                                }}
                            />
                        </div>
                        <div
                            style={{
                                paddingLeft: '2vw'
                            }}
                        >
                            <Typography variant="h6" className={`departmental-header-text`}>{this.props.data.dept_name_as}, বাহনা মহাবিদ্যালয়</Typography>
                            {/* <Typography variant="subtitle2">বাহনা মহাবিদ্যালয়</Typography> *
                            <Typography variant="h6" className={`departmental-header-text`}>{this.props.data.dept_name}, PANDU College</Typography>
                            {/* <Typography variant="subtitle2">PANDU College</Typography> *
                        </div>
                    </div>
                </div> */}
        <Menu
          theme="dark"
          onClick={this.handleClick}
          overflowedIndicator={<MenuOutlined />}
          style={{
            background: "#103261",
            border: "none",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            fontSize: 12,
            fontWeight: 700,
            //  color: "#9DA0B3"
          }}
          selectedKeys={[this.state.current]}
          //triggerSubMenuAction="click"
          // overflowedIndicator={<MenuUnfoldOutlined />}

          mode="horizontal"
        >
          {menu.map((el, index) => {
            if (el.hasSubnav) {
              return (
                <SubMenu title={el.title}>
                  {el.subnav.map((el1, index1) => {
                    if (el1.hasSubnav) {
                      return (
                        <SubMenu title={el1.title}>
                          {el1.subnav.map((el2, index2) => {
                            if (el2.isLink == true) {
                              return (
                                <Menu.Item key={0} style={{ color: "white" }}>
                                  <a
                                    className="navItemPandu1"
                                    target="_blank"
                                    href={el2.link}
                                  >
                                    {el2.title}{" "}
                                  </a>
                                </Menu.Item>
                              );
                            } else {
                              return (
                                <Menu.Item
                                  key={el2.path}
                                  style={{ color: "white" }}
                                >
                                  {el2.title}
                                </Menu.Item>
                              );
                            }
                          })}
                        </SubMenu>
                      );
                    } else {
                      if (el1.isLink == true) {
                        return (
                          <Menu.Item key={0} style={{ color: "white" }}>
                            <a
                              className="navItemPandu1"
                              target="_blank"
                              href={el1.link}
                            >
                              {el1.title}{" "}
                            </a>
                          </Menu.Item>
                        );
                      } else {
                        return (
                          <Menu.Item key={el1.path} style={{ color: "white" }}>
                            {el1.title}
                          </Menu.Item>
                        );
                      }
                    }
                  })}
                </SubMenu>
              );
            } else {
              return (
                <Menu.Item style={{ color: "white" }} key={el.path}>
                  {el.title}
                </Menu.Item>
              );
            }
          })}
        </Menu>
        {/* <Navbar bg="dark" expand="lg" className="justify-content-center" style={{backgroundColor: '#001529 !important'}}>
                  
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{filter: 'invert()'}} />
                    <Navbar.Collapse>
                        <Nav className="mr-auto">

                            {menu.map((el, index) =>
                                <Nav.Link key={index} className="text-white bg-dark"
                                    style={{
                                        fontSize: '1em'
                                    }}

                                    onClick={() => this.props.history.push(el.path)}
                                >{el.title}</Nav.Link>
                            )}

                        </Nav>

                    </Navbar.Collapse>
                
                </Navbar> */}

        {/* <Menu theme="dark" selectedKeys={[this.state.current]} onClick={this.handleClick} mode="horizontal"
    // overflowedIndicator={
    //      <MenuUnfoldOutlined />
    // }
>
            {menu.map((el, index) => {
              if (el.hasSubnav) {
                return <SubMenu title={el.title} key={index}>
                  {el.subnav.map((el1, index1) => {
                    if (el1.hasSubnav) {

                      return <SubMenu title={el1.title} key={index1}>
                        {el1.subnav.map((el2, index2) =>
                          <Menu.Item key={el2.path}>{el2.title}</Menu.Item>
                        )}
                      </SubMenu>


                    } else {
                      return <Menu.Item key={el1.path}>{el1.title}</Menu.Item>
                    }

                  })}



                </SubMenu>
              } else {
                return <Menu.Item key={el.path} style={{fontSize: '11px'}} >
                  {el.title}
                </Menu.Item>
              }
            }

            )}


          </Menu> */}
      </div>
    );
  }
}

export default DepartmentNavigation;
