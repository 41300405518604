import React, { Component } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Tabs } from "antd";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";
import { Container } from "@material-ui/core";
import NotificationDescription from "../Notifications/NotificationDescription";
import NptelEvents from "./NptelEvents";
const { TabPane } = Tabs;

class NptelChapter extends Component {
  render() {
    return (
      <div style={{ marginTop: "" }}>
        <BreadCrumbOwn title="SWAYAM NPTEL LOCAL CHAPTER" />
        <Container className="mb-5">
          <Typography
            variant="h4"
            style={{ margin: "1em 0", fontWeight: 700 }}
            gutterBottom
          >
            ABOUT SWAYAM-NPTEL LOCAL CHAPTER
          </Typography>

          {/* <Typography
                variant="h6"
                gutterBottom
            >
                History
            </Typography> */}

          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flexBasis: "10%" }}>
              <img src={`/images/nptel-spoc.png`} style={{ width: "100%" }} />
            </div>
            <div tyle={{ flexBasis: "90%" }}>
              <ul>
                <li>
                  SPOC Name: <span style={{fontWeight: 700}}>DR. AMIT KUMAR PRADHAN</span>
                </li>
                <li>
                  Designation: <span style={{fontWeight: 700}}>ASSITANT PROFESSOR</span>
                </li>
                <li>
                  Partnering Since: <span style={{fontWeight: 700}}>12-10-2023</span>
                </li>
                <li>
                  College Id: <span style={{fontWeight: 700}}>6132</span>
                </li>
                <li>
                  Institute Type: <span style={{fontWeight: 700}}>ARTS, SCIENCE AND COMMERCE</span>
                </li>
              </ul>
            </div>
          </div>

          <br />

          <Typography variant="body1" gutterBottom>
            SWAYAM is a programme initiated by Government of India and designed
            to achieve the three cardinal principles of Education Policy viz.,
            access, equity and quality. The objective of this effort is to take
            the best teaching learning resources to all, including the most
            disadvantaged. SWAYAM seeks to bridge the digital divide for
            students who have hitherto remained untouched by the digital
            revolution and have not been able to join the mainstream of the
            knowledge economy.
          </Typography>
          <br />
          <Typography variant="body1" gutterBottom>
            The courses hosted on SWAYAM are in 4 quadrants – (1) video lecture,
            (2) specially prepared reading material that can be
            downloaded/printed (3) self-assessment tests through tests and
            quizzes and (4) an online discussion forum for clearing the doubts.
          </Typography>
          <br />
          <Typography variant="body1" gutterBottom>
            Courses delivered through SWAYAM are available free of cost to the
            learners, however learners wanting a SWAYAM certificate should
            register for the final proctored exams that come at a fee and attend
            in-person at designated centers on specified dates. Eligibility for
            the certificate will be announced on the course page and learners
            will get certificates only if this criteria is matched.
          </Typography>
          <br />
          <Typography variant="body1" gutterBottom>
            NPTEL is one of the nine national coordinators of SWAYAM offering
            self-study courses across engineering, humanities and science
            streams for more than a decade. These are available at:{" "}
            <a taregt="_blank" href="http://archive.nptel.ac.in">
              http://archive.nptel.ac.in
            </a>
            ;{" "}
            <a taregt="_blank" href="https://swayam.gov.in/NPTEL">
              https://swayam.gov.in/NPTEL
            </a>
          </Typography>
          <br />
          <Typography variant="body1" gutterBottom>
            SWAYAM-NPTEL chapter in colleges (with the approval of the
            management) under the headship of a faculty member of the college,
            termed as Single Point of Contact (SPOC). NPTEL keeps the SPOC
            updated about all the latest NPTEL initiatives and give him
            information which he can disseminate among the students. He can
            identify suitable mentors for various courses, who can ensure that
            students are active in a course, are submitting their assignments on
            time and also clarify the doubts they may have.
          </Typography>

          <br />

          <Tabs defaultActiveKey="1">
            <TabPane tab="News & Notifications" key="1">
            <NotificationDescription code="COLLEGE_NPTEL_NEWS" title="News & Notifications" />
            </TabPane>
            <TabPane tab="Events" key="2">
              <NptelEvents />
            </TabPane>
          </Tabs>
        </Container>
      </div>
    );
  }
}

export default NptelChapter;
