import React, { Component } from 'react';

class NewMarquee extends Component {
    state = {
        initialSpeed: this.props.initialSpeed,
        x: 0,
        hover: this.props.hover,
        height: this.props.height
    };
    moover = () => {
        let x = this.state.x;
        if (!this.state.hover) {
            if (x < this.props.height) {
                this.setState({
                    x: x + 1
                });
            } else {
                this.setState({
                    x: 0
                });
            }
        } else {
            this.setState({
                x: this.state.x
            });
        }
    };
    componentDidMount() {
        setInterval(() => this.moover(), this.state.initialSpeed);
    }
    setHover = () => {
        this.setState({
            hover: !this.state.hover
        });
    };

    disSetHover = () => {
        this.setState({
            hover: false
        });
    };

    
    render() {
        return (
            <div onMouseOver={this.setHover}
            onMouseLeave={this.disSetHover}
            onMouseMove={this.setHover}
            onMouseOut={this.setHover}>
                <marquee height="280px" scrollamount={this.state.hover ? 0 : 1} direction="up"
                    
                >
                    {this.props.children}
                </marquee>
            </div>
        );
    }
}

export default NewMarquee;