import React, { Component } from 'react';
import {
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from "@material-ui/core";
import { MDBContainer } from 'mdbreact';
import BreadCrumbOwn from '../component/Essentials/BreadCrumbOwn';

class EmblemContainers extends Component {
    render() {
        return (
            <div>
                <BreadCrumbOwn title="Home > Emblem" />
                <MDBContainer style={{ paddingTop: '40px', minHeight: '700px' }}>
                    <div className="d-flex justify-content-center">
                        <img src="/images/tnclogo.png" style={{ height: '100%', width: '10%', margin: '10px' }} />
                    </div>
                    <br />
                    <br />
                    <Typography variant="body1" gutterBottom style={{ textAlign: 'center', fontWeight: 500 }}>
                        <i>Shield and swords signifies an armour of protection to fight against illiteracy prevalent in the district.
                            <br />
                            The lighting lamp in its bottom means removal of darkness through dissemination of Education and Knowledge.</i>
                    </Typography>


                </MDBContainer>
            </div>
        );
    }
}

export default EmblemContainers;