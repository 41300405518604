import React from 'react'
import { Container, Breadcrumbs, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

export default function BreadCrumbOwn({ title }) {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px', backgroundImage: 'url(/images/bread-new-1.png)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundColor: 'black', position: 'relative' }}>
        <div style={{ position: 'absolute', background: `linear-gradient(to bottom, transparent 0%, rgba(0,0,0, .7) 100%)`, width: '100%', minHeight: '100%' }}>&nbsp;</div>

        <div style={{ color: 'white', fontWeight: 700, fontSize: 32, position: 'absolute', bottom: 50, left: 0, width: "100%" }}><Container>{title}</Container>
        </div>


      </div>

      <Container style={{ backgroundColor: "#E9ECEF", marginTop: 20, padding: 20 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Home
          </Link>

          <Typography color="textPrimary">{title}</Typography>
        </Breadcrumbs>
      </Container>
    </>
  )
}
