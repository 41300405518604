import React, { Component } from "react";
import { Card } from "react-bootstrap";
import TitleStyle from "../TitleStyle";
import {
  Container,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import { PostData } from "../../api/service";
import Icon from "@material-ui/core/Icon";
import ShowImage from "./ShowImage";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
export default class Alumni extends Component {
  state = {
    data: [],
    batch: [],
    isLoaded: false,
  };

  componentDidMount() {
    let d = {
      dept_code: this.props.data.dept_code,
    };

    PostData(`/getalumnibydept`, d).then((resp) => {
      let r = [];

      if (Array.isArray(resp)) {
        resp.map((el, index) => {
          if (!r.includes(el.batch)) {
            r.push(el.batch);
          }
        });
      }

      console.log(r);

      const cleanedDateRanges = r.map((range) =>
        range.replace(/\s+/g, "")
      );

      // Sort by the starting year
      cleanedDateRanges.sort((a, b) => {
        const startA = parseInt(a.split("-")[0], 10);
        const startB = parseInt(b.split("-")[0], 10);
        return startA - startB;
      });
      this.setState({
        data: resp,
        batch: cleanedDateRanges,
        isLoaded: true,
      });
    });
  }

  render() {
    return (
      <div>
        <Container>
          <TitleStyle title={`Alumni`} />

          <br />

          {this.state.isLoaded ? (
            <div>
              {Array.isArray(this.state.batch) &&
                this.state.batch.map((el1, index1) => (
                  <ExpansionPanel key={index1} style={{ marginBottom: 20 }}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h6" style={{ fontWeight: 700 }}>
                        {el1}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Batch</TableCell>
                            <TableCell>Current Profession</TableCell>
                            <TableCell>Highest Qualification</TableCell>
                            <TableCell>Contact</TableCell>
                          </TableRow>
                        </TableHead>
                        {this.state.data
                          .filter((el) => el.batch == el1)
                          .map((el, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{el.name}</TableCell>
                              <TableCell>{el.batch}</TableCell>
                              <TableCell>{el.current_profession}</TableCell>
                              <TableCell>{el.qualification}</TableCell>
                              <TableCell>
                                {el.email}
                                <br /> {el.phone}
                              </TableCell>
                            </TableRow>
                          ))}
                      </Table>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
            </div>
          ) : (
            <Typography>Loading . . . </Typography>
          )}
        </Container>
      </div>
    );
  }
}
