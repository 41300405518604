import React, { Component } from 'react';
import {
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Container
} from "@material-ui/core";
import BreadCrumbOwn from '../component/Essentials/BreadCrumbOwn';

class OrganogramContainer extends Component {
    render() {
        return (
            <div>
                <BreadCrumbOwn title="Organogram" />
                <Container className="mb-5">
                    <Typography variant="h4" style={{ margin: "1em 0", fontWeight: 700 }}>
                    Organogram
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        The organogram is an administrative diagram of College describes the decentralized structure of administration.
                            
                            College administration is a cooperative effort of Principal, teaching, non-teaching staff and students with the cooperation and support of all stakeholders in pursuit of common objective. It is necessary that all aspects should be organized in order to attain the desired goals.
                    </Typography>


                </Container>
            </div>
        );
    }
}

export default OrganogramContainer;