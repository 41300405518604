

export const BaseURL = process.env.NODE_ENV === 'production' ? 'https://webbackend.pragjyotishcollege.ac.in/public' : 'http://localhost/prag-website-new/api/public';
export const WebURL = 'https://backend.pragjyotishcollege.ac.in/public';

export const SERVER_UPLOAD = process.env.NODE_ENV === 'production' ? 'https://webbackend.pragjyotishcollege.ac.in/src/uploads' : 'http://localhost/prag-website-new/api/src/uploads';
export const SERVER_UPLOAD_NEW = process.env.NODE_ENV === 'production' ? 'https://backend.pragjyotishcollege.ac.in/src/uploads' : 'http://localhost/prag-website-new/api/src/uploads';

export function PostData(type, userData) {

    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'POST',
            body: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json',
                'Unit': 'website'
            }
        })
            .then((resp) => {
                if (!resp.ok) {
                    throw resp.text();
                } else {
                    return resp.json();
                }
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}





export function PostDataExtra(type, userData) {

    return new Promise((resolve, reject) => {
        fetch(WebURL + type, {
            method: 'POST',
            body: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json',
                'Unit': 'website'
            }
        })
            .then((resp) => {
                if (!resp.ok) {
                    throw resp.text();
                } else {
                    return resp.json();
                }
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export function GetData(type) {


    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Unit': 'website'
            }
        })
            .then((resp) => {
                if (!resp.ok) {

                    throw resp.text();

                } else {
                    return resp.json();
                }
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}


export function GetDataExtra(type) {


    return new Promise((resolve, reject) => {
        fetch(WebURL + type, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Unit': 'website'
            }
        })
            .then((resp) => {
                if (!resp.ok) {

                    throw resp.text();

                } else {
                    return resp.json();
                }
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}


export function DeleteData(type) {

    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Unit': 'admin'
            }
        })
            .then((resp) => {
                if (!resp.ok) {
                    if (resp.status >= 400 && resp.status < 500) {
                        /* return resp.json().then(data => {
                            let err = { errorMessage: data };
                            alert(err.errorMessage);
                            //logout
                            //Auth.logout();
                            throw err;
                        }) */

                        throw resp.text();
                    }
                    else {
                        /* let err = { errorMessage: "Please try again later" };
                        alert(`Something went Wrong!! Status: ${resp.status}`);
                        //logout
                        //Auth.logout();
                        throw err; */
                        throw resp.text();
                    }
                } else {
                    return resp.json();
                }

            }
            )
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                error.then((resp) => {
                    console.log(error);
                })
                reject(error);
            });
    });
}



export function PostFormData(type, userData) {


    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'POST',
            body: userData,
            headers: {
                'Unit': 'website'
            }
        })
            .then((resp) => {
                //console.log(resp);
                if (!resp.ok) {
                    if (resp.status >= 400 && resp.status < 500) {
                        return resp.json().then(data => {
                            let err = { errorMessage: data };
                            alert(err.errorMessage);
                            //logout
                            //Auth.logout();
                            throw err;
                        })
                    }
                    else {
                        let err = { errorMessage: "Please try again later" };
                        alert(`Something went Wrong!! Status: ${resp.status}`);
                        //logout
                        //Auth.logout();
                        throw err;
                    }
                }
                return resp.json();
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

