import React, { Component } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import BreadCumb from "../component/Department/BreadCumb";
import NotificationDescription from "../component/Notifications/NotificationDescription";
import { GetData, PostData } from "../api/service";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Typography,
  Grid,
} from "@material-ui/core";
import {
  Alert,
  Button,
  Input,
  Modal,
  Result,
  message,
  Select,
  Divider,
} from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../Helper/constants";
import moment from "moment";
import FileUpload from "../component/Essentials/FileUpload";

const { Option } = Select;

let years = [];
for (let i = 1954; i <= moment().format("Y"); i++) {
  years.push(i);
}

export default function AlumniRegistrationContainer() {
  const [state, setState] = React.useState({
    name: "",
    contact: "",
    address: "",
    whatsapp_number: "",
    email: "",
    department: "",
    batch_from: "",
    batch_to: "",
    occupation: "",
    highest_qualification: "",
    photo: "",
    isSubmitting: false,
    captcha: false,
  });

  const captchaRef = React.useRef(null);

  const [departments, setDepartments] = React.useState([]);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    GetData(`/getdepartments`).then((resp) => {
      //let data = resp.filter((el)=>el.stream==this.state.stream)
      setDepartments(resp);
    });
  }, []);

  const [error, setError] = React.useState({
    name: "",
    contact: "",
    address: "",
    whatsapp_number: "",
    email: "",
    department: "",
    batch_from: "",
    batch_to: "",
    occupation: "",
    photo: "",
    highest_qualification: "",
    captcha: "",
  });

  function onClear() {
    setState({
      name: "",
      contact: "",
      address: "",
      whatsapp_number: "",
      email: "",
      department: "",
      batch_from: "",
      batch_to: "",
      occupation: "",
      highest_qualification: "",
      isSubmitting: false,
      captcha: false,
    });

    setOtpModal(false);
    setOtp("");
    setOtpMessage("");
    setOtpValid(null);
  }

  const [otpModal, setOtpModal] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [otpMessage, setOtpMessage] = React.useState("");
  const [otpValid, setOtpValid] = React.useState(null);

  const [dp, setDp] = React.useState([]);

  function onChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  function handleValidation() {
    let isValid = true;
    let err = [];

    if (state.name == "" || state.name == null) {
      isValid = false;
      err["name"] = "Mandatory Field";
    } else {
      err["name"] = "";
    }

    if (state.address == "" || state.address == null) {
      isValid = false;
      err["address"] = "Mandatory Field";
    } else {
      err["address"] = "";
    }

    if (state.email == "" || state.email == null) {
      isValid = false;
      err["email"] = "Mandatory Field";
    } else {
      err["email"] = "";
    }

    if (state.phone == "" || state.phone == null) {
      isValid = false;
      err["phone"] = "Mandatory Field";
    } else {
      err["phone"] = "";
    }

    if (state.whatsapp_number == "" || state.whatsapp_number == null) {
      isValid = false;
      err["whatsapp_number"] = "Mandatory Field";
    } else {
      err["whatsapp_number"] = "";
    }

    if (state.department == "" || state.department == null) {
      isValid = false;
      err["department"] = "Mandatory Field";
    } else {
      err["department"] = "";
    }

    if (state.batch_from == "" || state.batch_from == null) {
      isValid = false;
      err["batch_from"] = "Mandatory Field";
    } else {
      err["batch_from"] = "";
    }

    if (state.batch_to == "" || state.batch_to == null) {
      isValid = false;
      err["batch_to"] = "Mandatory Field";
    } else {
      err["batch_to"] = "";
    }

    if (state.occupation == "" || state.occupation == null) {
      isValid = false;
      err["occupation"] = "Mandatory Field";
    } else {
      err["occupation"] = "";
    }

    if (
      state.highest_qualification == "" ||
      state.highest_qualification == null
    ) {
      isValid = false;
      err["highest_qualification"] = "Mandatory Field";
    } else {
      err["highest_qualification"] = "";
    }

    setError({ ...err });
    return isValid;
  }

  const [countOtp, setCountOtp] = React.useState(0);

  function sendOtp() {
    if (handleValidation()) {
      const token = captchaRef.current.getValue();
      if (token) {
        // send OTP
        setCountOtp(countOtp + 1);
        setIsSubmitting(true);
        PostData(`/email-verification`, {
          email: state.email,
        }).then((resp) => {
          setOtp("");
          setOtpModal(true);
          setOtpMessage(resp);
          setIsSubmitting(false);
        });
      } else {
        message.error("Captcha not valid");
        setIsSubmitting(false);
      }
    }
  }

  function handleOtpClose() {
    setOtp("");
    setOtpModal(false);
    setOtpMessage("");
    setOtpValid(null);
  }

  function onSubmitOtp() {
    if (otp != "" && state.email != "") {
      setIsSubmitting(true);
      PostData(`/email-verification-otp`, {
        email: state.email,
        otp: otp,
      })
        .then((resp) => {
          setIsSubmitting(false);
          if (resp) {
            setOtpValid(true);
            setOtpMessage(`Email Verified Successfully`);
          } else {
            setOtpValid(false);
            setOtpMessage(`OTP is wrong, try again.`);
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
        });
    }
  }

  function onSubmit() {
    if (handleValidation() && dp.length > 0) {
      setIsSubmitting(true);

      //const token = captchaRef.current.getValue();

      //if(token){

      // POST

      PostData(`/add-alumni-registration`, { ...state, dp: dp })
        .then((resp) => {
          if (resp != false) {
            onClear();
            Modal.success({
              title: "Successfully Submitted",
              content: (
                <>
                  <Result
                    status="success"
                    title="Thank you submitting."
                    subTitle="You will be notified after verification."
                  />
                </>
              ),
            });
            captchaRef.current.reset();
            setIsSubmitting(false);
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
        });

      //}
    }
  }

  return (
    <div>
      <BreadCrumbOwn title="Alumni Registration" />
      <Container style={{ minHeight: "80vh" }}>
        <br />

        <Typography
          variant="h5"
          style={{ margin: "1em 0", fontWeight: 700 }}
          gutterBottom
        >
          Pragjyotish College Alumni Association Registration Form
        </Typography>

        <br />
        <Alert
          type="info"
          banner
          message={
            <>
              Hello Alumni, You can submit the following details to get
              registered with Pragjyotish College Alumni Association. This form
              is subjected to verification by our officals. You will receive
              email updates on verification. Kindly provide the correct details
              in order for us to verify properly.
            </>
          }
        />

        <br />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div style={{ fontWeight: 700 }}>
              Your Full Name (With Salutation){" "}
              <span style={{ color: "red" }}>*</span>
            </div>
            <div style={{ fontSize: 12, color: "red" }}>{error.name}</div>
            <Input
              name="name"
              value={state.name}
              onChange={(e) => onChange("name", e.target.value)}
              placeholder="Full Name"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div style={{ fontWeight: 700 }}>
              Your Email ID for Communication{" "}
              <span style={{ color: "red" }}>*</span>
            </div>
            <div style={{ fontSize: 12, color: "red" }}>{error.name}</div>
            <Input
              type="email"
              name="email"
              value={state.email}
              onChange={(e) => onChange("email", e.target.value)}
              placeholder="Email ID"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div style={{ fontWeight: 700 }}>
              Your Contact Number <span style={{ color: "red" }}>*</span>
            </div>
            <div style={{ fontSize: 12, color: "red" }}>{error.phone}</div>
            <Input
              type="text"
              name="phone"
              value={state.phone}
              onChange={(e) => onChange("phone", e.target.value)}
              placeholder="Contact Number"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div style={{ fontWeight: 700 }}>
              Your WhatsApp Number <span style={{ color: "red" }}>*</span>
            </div>
            <div style={{ fontSize: 12, color: "red" }}>{error.phone}</div>
            <Input
              type="text"
              name="whatsapp_number"
              value={state.whatsapp_number}
              onChange={(e) => onChange("whatsapp_number", e.target.value)}
              placeholder="WhatsApp Number"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div style={{ fontWeight: 700 }}>
              Select Department <span style={{ color: "red" }}>*</span>
            </div>
            <div style={{ fontSize: 12, color: "red" }}>{error.phone}</div>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              name="department"
              onChange={(e) => onChange("department", e)}
              value={state.department}
              style={{ width: "100%" }}
            >
              <Option value="">--Select Department--</Option>
              {Array.isArray(departments) &&
                departments.map((el, index) => (
                  <Option key={index} value={el.dept_code}>
                    {el.dept_name}
                  </Option>
                ))}
            </Select>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div style={{ fontWeight: 700 }}>
              Select Batch Starting Year <span style={{ color: "red" }}>*</span>
            </div>
            <div style={{ fontSize: 12, color: "red" }}>{error.phone}</div>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children?.indexOf(input.toLowerCase()) >= 0
              }
              name="batch_from"
              onChange={(e) => onChange("batch_from", e)}
              value={state.batch_from}
              style={{ width: "100%" }}
            >
              <Option value="">--Select Year--</Option>
              {Array.isArray(years) &&
                years.map((el, index) => (
                  <Option key={index} value={el}>
                    {el.toString()}
                  </Option>
                ))}
            </Select>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div style={{ fontWeight: 700 }}>
              Select Batch Ending Year <span style={{ color: "red" }}>*</span>
            </div>
            <div style={{ fontSize: 12, color: "red" }}>{error.phone}</div>
            <Select
              showSearch
              name="batch_to"
              onChange={(e) => onChange("batch_to", e)}
              value={state.batch_to}
              style={{ width: "100%" }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children?.indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">--Select Year--</Option>
              {Array.isArray(years) &&
                years.map((el, index) => (
                  <Option key={index} value={el}>
                    {el.toString()}
                  </Option>
                ))}
            </Select>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div style={{ fontWeight: 700 }}>
              Present Occupation <span style={{ color: "red" }}>*</span>
            </div>
            <div style={{ fontSize: 12, color: "red" }}>{error.phone}</div>
            <Input
              type="text"
              name="occupation"
              value={state.occupation}
              onChange={(e) => onChange("occupation", e.target.value)}
              placeholder="Occupation"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div style={{ fontWeight: 700 }}>
              Highest Qualification <span style={{ color: "red" }}>*</span>
            </div>
            <div style={{ fontSize: 12, color: "red" }}>{error.phone}</div>
            <Input
              type="text"
              name="highest_qualification"
              value={state.highest_qualification}
              onChange={(e) =>
                onChange("highest_qualification", e.target.value)
              }
              placeholder="Highest Qualification"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div style={{ fontWeight: 700 }}>
              Address <span style={{ color: "red" }}>*</span>
            </div>
            <div style={{ fontSize: 12, color: "red" }}>{error.address}</div>
            <Input.TextArea
              name="address"
              value={state.address}
              onChange={(e) => onChange("address", e.target.value)}
              placeholder="Address"
            />

            <br />
            <br />

            <ReCAPTCHA sitekey={SITE_KEY} ref={captchaRef} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: "right" }}
          >
            <Button
              type="primary"
              loading={isSubmitting}
              onClick={() => sendOtp()}
            >
              Submit
            </Button>
          </Grid>
        </Grid>

        {otpModal && (
          <Modal
            title={<div style={{ fontWeight: 700 }}>Email Verification</div>}
            okText={"Submit"}
            footer={null}
            visible={otpModal}
            confirmLoading={isSubmitting}
            onCancel={() => handleOtpClose()}
          >
            <Alert
              banner
              type={"success"}
              message={
                <>
                  {otpMessage} Please wait for some time to received the mail or
                  you can{" "}
                  <a
                    style={{ color: "blue" }}
                    onClick={() => {
                      if (countOtp < 5) {
                        sendOtp();
                      }
                    }}
                  >
                    <u>Click here</u>
                  </a>{" "}
                  to resend the OTP;
                </>
              }
            />
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Input
                  type="number"
                  disabled={otpValid}
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Insert Your 6 digit OTP received in email"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Button
                  loading={isSubmitting}
                  onClick={() => onSubmitOtp()}
                  disabled={otpValid}
                  type="primary"
                >
                  Verify
                </Button>
              </Grid>
            </Grid>

            <Divider />

            <br />
            {otpValid && (
              <div>
                <div style={{ fontWeight: 700 }}>
                  Upload Passport size photo{" "}
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div style={{ fontSize: 12, color: "red" }}>{error.photo}</div>

                <FileUpload
                  dp={dp}
                  type={`ALUMNI_DP`}
                  setDp={(e) => setDp(e)}
                />

                <br />

                <div style={{ textAlign: "center" }}>
                  <Button
                    loading={isSubmitting}
                    size="large"
                    type="primary"
                    onClick={() => onSubmit()}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </Modal>
        )}
      </Container>
    </div>
  );
}
