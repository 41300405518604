import React, { Component } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Container,
} from "@material-ui/core";
import { Menu, Tabs } from "antd";
import { Button } from "@material-ui/core";
import { PostData } from "../../api/service";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";
import AchievementShort from "../Department/AchievementShort";
import HightlightShort from "../HightlightShort";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { GoverningBody } from "../Alumni/AlumniDescription";
class NssFacilityDescription extends Component {
  state = {
    active: 0,
  };
  render() {
    return (
      <div style={{ marginTop: "" }}>
        <Library title="National Service Scheme" type="NSS_HOME" />

        <Container>
        <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <a onClick={() => this.setState({
              active: 0
            })}>
              <div
                style={{
                  border: "solid thin lightgrey",
                  padding: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginRight: 10,
                  width: 150,
                  backgroundColor: this.state.active == 0 ? "#1976D2" : "white",
                }}
              >
                <div>
                  <LocalActivityIcon style={{ color: this.state.active == 0 ? "white": "black" }} />
                </div>
                <div style={{ fontWeight: 700, color: this.state.active == 0 ? "white": "black" }}>
                  Activities
                </div>
              </div>
            </a>

            <a onClick={() => this.setState({
              active: 1
            })}>
            <div
              style={{
                border: "solid thin lightgrey",
                padding: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginRight: 10,
                width: 150,
                backgroundColor: this.state.active == 1 ? "#1976D2" : "white",
              }}
            >
              <div>
                <LoyaltyIcon  style={{ color: this.state.active == 1 ? "white": "black" }} />
              </div>
              <div style={{ fontWeight: 700, color: this.state.active == 1 ? "white": "black" }}>Achievements</div>
            </div>
            </a>

            <a onClick={() => this.setState({
              active: 2
            })}>
            <div
              style={{
                border: "solid thin lightgrey",
                padding: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginRight: 10,
                width: 150,
                backgroundColor: this.state.active == 2 ? "#1976D2" : "white",
              }}
            >
              <div>
                <PeopleAltIcon  style={{ color: this.state.active == 2 ? "white": "black" }} />
              </div>
              <div style={{ fontWeight: 700, color: this.state.active == 2 ? "white": "black" }}>Members</div>
            </div>
            </a>
          </div>

          <br />

          {this.state.active == 0 && (
            <div>
              <AchievementShort
                dept_code="PRAG"
                type="NSS_ACTIVITIES"
                title="NSS Activities"
              />
            </div>
          )}
          {this.state.active == 1 && (
            <div>
              <AchievementShort
                dept_code="PRAG"
                type="NSS_ACHIEVEMENTS"
                title="NSS Achievements"
              />
            </div>
          )}

          {this.state.active == 2 ? (<GoverningBody title="NSS Members" type="NSS_MEMBERS" />) : null}

          <br />
        </Container>
      </div>
    );
  }
}

export default NssFacilityDescription;

export const Library = (props) => {
  const [data, setData] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    __loadFile();
  }, []);

  function __loadFile() {
    let d = {
      dept_code: "PRAG",
      type: props.type,
      year: "",
    };

    PostData(`/getnoticesbytypedept`, d).then((resp) => {
      setData(resp);
      setIsLoaded(true);
    });
  }

  return (
    <>
      <BreadCrumbOwn title="National Service Scheme" />
      <Container className="mb-5">
        <div className="text-justify">
          <Typography
            variant="h5"
            style={{ margin: "1em 0", fontWeight: 700 }}
            gutterBottom
          >
            {props.title}
          </Typography>

          <HightlightShort type="NSS_BANNER" />

          {isLoaded && (
            <div>
              {Array.isArray(data) &&
                data.map((el, index) => (
                  <div key={index}>
                    <Typography variant="body1" gutterBottom>
                      <p
                        variant="body1"
                        dangerouslySetInnerHTML={{ __html: el.description }}
                      />
                    </Typography>

                    <br />

                    <Grid container spacing={2}>
                      {Array.isArray(el.upload_info) &&
                        el.upload_info.map((el, index) => (
                          <Grid
                            key={index}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                          >
                            {el.fileType != "PDF" && (
                              <img src={el.url} style={{ width: "100%" }} />
                            )}

                            {el.fileType == "PDF" && (
                              <a target="_blank" href={el.url}>
                                {el.path}
                              </a>
                            )}
                          </Grid>
                        ))}
                    </Grid>
                  </div>
                ))}
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export const BoysHostel = (props) => {
  return (
    <div className="text-justify">
      <Typography
        variant="h5"
        style={{ margin: "1em 0", fontWeight: 700 }}
        gutterBottom
      >
        {props.title}
      </Typography>

      <Typography variant="h6" gutterBottom>
        ESTABLISHMENT
      </Typography>

      <Typography variant="body1" gutterBottom>
        Hostels are not just a place where you stay. It is also a destination
        where you socialize and meet different people when you are out of town
        to complete your education or to do a job. Pandu College Boys’ Hostel
        started its journey since 5th September, 2000 for providing the
        backpackers a base to stay, which is the best boys’ hostel in Greater
        Guwahati, Assam. The location is very near to Pandu College and vicinity
        in clean and proper residential with all the aminities. The management
        of the hostel strives to improve the standard of living with utmost
        safety & Security of the residents away from their home. We believe,
        good living conditions are very relevant for the development of the
        students into enlightened citizens for the future of the country.
      </Typography>

      <br />
      <br />

      <Typography variant="h6" gutterBottom>
        BOYS’ HOSTEL ADMISSION
      </Typography>

      <Typography variant="body1" gutterBottom>
        The hostel has accommodation for 60 students. There are 15 well
        furnished rooms having seat capacity of 4 boarders in each. Students
        should apply in the beginning of every academic session (July-August)
        for hostel accommodation. The list of selected students is prepared on
        merit basis and published in the college website.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Application form with one passport size photograph and college admission
        receipt along with the mark sheet of last examination passed will be
        submitted to the hostel superintendent for hostel accommodation.
        Admission form for hostel accommodation has to be endorsed by the
        superintendent.
      </Typography>

      <Typography variant="body1" gutterBottom>
        The hostel admission fees which are to be paid every year are as
        follows:
      </Typography>

      <table className="feestable" border="1">
        <thead>
          <tr>
            <td>Sl No </td>
            <td>HEADS</td>
            <td>FEES</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Hostel Admission</td>
            <td>Rs. 1000/-</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Establishment Charge</td>
            <td>Rs. 2550/-</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Electricity Charge</td>
            <td>Rs. 850/-</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Seat Rent</td>
            <td>Rs. 3000/-</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Maintenance</td>
            <td>Rs. 3000/-</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Caution Money</td>
            <td>Rs. 100/-</td>
          </tr>
          <tr>
            <td colSpan="2">
              <strong>Total</strong>
            </td>
            <td>
              <strong>Rs. 10500/-</strong>
            </td>
          </tr>
        </tbody>
      </table>

      <Typography variant="h6" gutterBottom>
        HOSTEL ADMINISTRATION
      </Typography>

      <img src="/images/Jayanta .jpg" />
      <Typography variant="body1" gutterBottom>
        <i>Superintendent</i>
        <br />
        <strong>Dr. JAYANTA HAZARIKA, Asst. Professor</strong>
        <br />
        Department of Physics
        <br />
        Ph. +91-75779-90511
        <br />
      </Typography>
      <br />
      <Typography variant="body1" gutterBottom>
        <strong>Members of the Mess Committee (Session 2020-21)</strong>
        <ol>
          <li>Monitor:</li>
          <li>Assistant Monitor:</li>
          <li>Mess Manager:</li>
          <li>Mess Auditor:</li>
        </ol>

        <strong>Other Functionaries/In-charge:</strong>

        <ol>
          <li>Computer & Internet:</li>
          <li>Sports:</li>
          <li>Social service:</li>
          <li>Cultural:</li>
          <li>Wall magazine:</li>
          <li>Annual Megazine:</li>
        </ol>
      </Typography>

      <Typography variant="h6" gutterBottom>
        STANDARD OPERATING PROCEDURE FOR HOSTEL
      </Typography>

      <Typography variant="body1" gutterBottom>
        Students/boarders should READ THE RULES before admission (copy of rules
        is available with Hostel superintendent). Rules displayed in the hostel
        also.
        <br />
        <br />
        <ol>
          <li>
            Any misleading or false statement or information in the application
            form shall render the admission for termination
          </li>
          <li>
            Use of MOBILE PHONE strictly prohibited during reading hours in side
            room (7:00 pm to 12:00pm). Violating shall invite strict
            disciplinary action leading to Rs.500/- penalty or rustication from
            the hostel or both.
          </li>
          <li>
            The Hostel Management Committee/superintendent reserves the right to
            terminate the occupancy of the boarder for any willful disobedience
            or defiance of authority, non-observance or frequent violation of
            hostel rules, causing damage to person or property or indulging in
            anti-national or undesirable activities.
          </li>
          <li>
            A boarders shall not change/interchange her/his room with another
            boarder or shift into a vacant room without the previous written
            permission of the superintendent. The superintendent has the right
            to shift a student from her/his room to another room in the hostel
            at any time without assigning any reason.
          </li>
          <li>
            The hostel warden shall provide boarders keys of the allotted room.
            Boarders shall NOT use other lock and key for locking their rooms.
          </li>
          <li>
            The superintendent has the discretion to inspect any room at any
            time.
          </li>
          <li>
            Boarders shall not indulge in any political or communal activity
            which is detrimental to the law and order
          </li>
          <li>
            Boarders residing in the hostel will be staying at their own risk,
            liability and consequences.
          </li>
          <li>
            The boarders should be decently dressed when they are out of rooms.
          </li>
          <li>
            Strict silence shall be observed in hostel from 6.00 am to 9.00 am
            and 6:30pm to 12:00pm. Care should be taken at all times to ensure
            that music/loud talking is NOT audible.
          </li>
          <li>
            Boarders shall not bring, take and/or drink any alcohol/
            intoxicating drink, tobacco, gutka, drug or substance of any kind
            what so ever and/or smoke in the room and/or any part of premises.
            The same shall apply to visitors also. An occurrence of such
            behavior shall invite strict disciplinary action leading to
            rustication from the hostel.
          </li>
          <li>
            RAGGING in any form is BANNED. It is a cognizable offence and
            violation will invite action as per law of the land in addition to
            rustication from the hostel. Being a silent spectator and not
            reporting/stopping others indulging in ragging is also an offence
            and will invite similar disciplinary action.
          </li>
          <li>
            Prayer is mandatory for all at 5:45 pm every day. Attendance will be
            taken by the monitor at 6:15pm every day. After that the student is
            not allowed to go out of the hostel. Going out of hostel will lead
            to fine.
          </li>
          <li>
            Boarders are asked to maintain movement registrar as it is available
            in hostel.
          </li>
          <li>
            Having meals inside the hostel room unless medically advised is
            strictly prohibited.
          </li>
          <li>
            Using of Electric Iron or any appliances other than Laptop, Sticking
            notices or writing on wall strictly prohibited.
          </li>
          <li>
            Boarders shall not bring any extra furniture or other fixture in the
            room. All furniture and fixture in the rooms allotted to students
            must be cared for property. Boarders will be required to pay double
            the original cost of any item found missing from their room.
            Boarders will also be required to pay twice the charges of the
            repair to items that found to have been willfully damaged or have
            been damaged on account of misuse or unfair wear and tear.
          </li>
          <li>
            Attendance during. After that the student is not allowed to go out
            of the hostel. Going out of hostel will lead to fine.
          </li>
          <li>
            Main gate to be closed after 5:40 pm.(Changeable on the basis of
            session)
          </li>
        </ol>
        “Disciplinary action as per conduct shall be taken against boarders
        violating Rules and Regulations.”
      </Typography>

      <Typography variant="h6" gutterBottom>
        HOSTEL MESS
      </Typography>
      <Typography variant="body1" gutterBottom>
        We take into account the mess budget to suit each and every individual.
        Boarders shall pay full mess fee for one month in advance at the time of
        enter to the hostel. It is mandatory to eat in the mess. Boarders are
        requested to pay their monthly mess dues on time to avoid late fee. The
        mess dues are collected by the monitor issuing money receipt to the
        boarders. Boarders are requested not to waste food. Outsiders are not
        allowed in the mess. Guests are allowed in mess with prior permission of
        the Superintendent and charges applicable. Outside food/dabbas are
        strictly not permitted in the dining hall and room.
        <br />
        <br />
        <i>“Meals that meets the best quality standard”</i>
        <br />
        <br />
        <strong>Timings of Dining Hall</strong>
        <br />
        <br />
        <strong>Tea Biscuits : 6:00 am to 7:00 am</strong>
        <br />
        <strong>Breakfast: 8.30 am to 10.00 am</strong>
        <br />
        <strong>Lunch: 1.30 pm to 3.30 pm</strong>
        <br />
        <strong>Tea Biscuits: 6:15 pm to 6:30 pm</strong>
        <br />
        <strong>Dinner : 8.30 pm to 10.00 pm</strong>
        <br />
        <small>
          The food menu changes as per the availability of the session.
        </small>
        <br />
        <br />
      </Typography>

      <Typography variant="h6" gutterBottom>
        <i>These timings shall be strictly followed by the hostellers.</i>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Mess dues and other mess related issues are fixed and discuss by the
        mess committee meeting which is held on the last day of every month.
        Furthermore, the mess audit done by the mess manager, mess auditor and
        monitor under the supervision of hostel superintendent.
      </Typography>

      <Typography variant="h6" gutterBottom>
        FACILITIES
      </Typography>

      <Typography variant="body1" gutterBottom>
        <ul>
          <li>
            Good quality food and hygienic dining facilities with veg.
            and-non-veg. meal
          </li>
          <li>Proper cleanness is taken care of by the housekeeping staff.</li>
          <li>
            Boarders’ grievance Box provided in the hostel to be opened every 15
            days in the presence of monitor
          </li>
          <li>Four persons sharing Bedroom with well equipped furniture’s.</li>
          <li>
            Daily Housekeeping to maintain the cleanliness of the residence
            intact.
          </li>
          <li>Secured with boundary.</li>
          <li>Helpful staff with 24/7 availability.</li>
          <li>Indoor and outdoor games area for refreshing your day.</li>
          <li>
            Free use of Computer and High speed internet (Wi-Fi) facility for
            the boarders.
          </li>
          <li>Printing facilities are available at cheap rate.</li>
          <li>
            Free medical check-up and consultation with physician on the 2nd and
            4th Sunday of every month.
          </li>
          <li>
            Common Dining Hall having capacity of 60 boarders at a time cum
            Meeting Hall
          </li>
          <li>Library cum Visitors’ Waiting Room </li>
          <li>24hours Running water</li>
          <li>Water Purifier (UV-RO)</li>
          <li>Television with cable/satellite connection</li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        OTHER ACADEMIC AND SOCIAL TRICKS
      </Typography>
      <Typography variant="body1" gutterBottom>
        <ul>
          <li>
            “Nabarun” an annual hostel Magazine is being publish by the boarders
            to reflect and develop their hiding quality in reality.
          </li>
          <li>
            A wall magazine published by the boarders regularly to spot their
            thoughts every year.
          </li>
          <li>
            The hostel also annually organizing a “Knowledge Sharing” talk
            programme under the head of different academic topic by eminent
            personality.
          </li>
          <li>
            There will be an annual Parents/Guardians meet where they would get
            an opportunity to interact with the superintendent to put valuable
            suggestions and to raise the issues of boarders from the hostel end.
          </li>
          <li>
            There will be a common induction and farewell programme for the
            hostel boarders where they would get an opportunity to interact with
            each other.
          </li>
          <li>
            Apart from these the boarders are allowed to celebrate Independence
            Day, Republic day, and festivals like holi, diwali etc.
          </li>
          <li>
            On World Environment Day the hostel is being organize plantation
            programme at public place and executed clean drive annually by the
            boarders. On the basis of performance a best annual social worker
            select by the mess committee and awarded certificate of appreciation
            along with prize.
          </li>
        </ul>
        <p>
          An annual <strong>best boarder award</strong> also introduced by the
          hostel on the basis of academic performance which contains an
          appreciation certificate and <strong>cash reward Rs.2000/-</strong>.
        </p>
      </Typography>
    </div>
  );
};

export const GirlsHostel = (props) => {
  return (
    <div className="text-justify">
      <Typography
        variant="h5"
        style={{ margin: "1em 0", fontWeight: 700 }}
        gutterBottom
      >
        {props.title}
      </Typography>

      <Typography variant="h6" gutterBottom>
        ABOUT THE HOSTEL
      </Typography>

      <Typography variant="body1" gutterBottom>
        Pandu College Girls’ Hostel was established on 30th December 2000 to
        meet the demands of the Girls Students of the college coming from
        distant places of the state. Initially the hostel had a single block
        having seat capacity for only 50 boarders. But due to the increasing
        demands from the students, another block of the hostel (named as the
        Women’s Hostel) was started and was completed in the year 2017. The
        hostel is situated within the college campus in a healthy and secured
        environment. It has easy access to Bus, Railway Station and the Airport.
        The Pandu College Girls’ Hostel is unique in the city so far as the
        rules, regulations and discipline is concerned.
        <br />
        <br />
        Recently the first block of the hostel which was earlier called Girls´
        Hostel was renamed as Mamoni Raisom Goswami Girls Hostel (MRGH) and the
        other block which was earlier known as Women´s Hostel was renamed as
        Chandraprabha Saikiani Girls Hostel (CSH).
      </Typography>

      <Typography variant="h6" gutterBottom>
        SEAT CAPACITY
      </Typography>

      <Typography variant="body1" gutterBottom>
        Presently the hostel has accomodation for 98 boarders combining both the
        hostels. Mamoni Raisom Goswami Girls´ Hostel has seat capacity for 50
        boarders and Chandraprabha Saikiani Girls´ Hoatel has a seat capacity
        for 48 boarders. There are 50 well furnished rooms out of which 41 rooms
        are two seater, 3 rooms are three seater, 2 rooms having capaicty for 5
        boarders and 2 single seater rooms.
      </Typography>
    </div>
  );
};

export const CIF = (props) => {
  return (
    <div className="text-justify">
      <Typography
        variant="h5"
        style={{ margin: "1em 0", fontWeight: 700 }}
        gutterBottom
      >
        {props.title}
      </Typography>

      <Typography variant="body1" gutterBottom>
        This Page Is Under Development
      </Typography>
    </div>
  );
};

export const Canteen = (props) => {
  return (
    <div className="text-justify">
      <Typography
        variant="h5"
        style={{ margin: "1em 0", fontWeight: 700 }}
        gutterBottom
      >
        {props.title}
      </Typography>

      <Typography variant="body1" gutterBottom>
        This Page Is Under Development
      </Typography>
    </div>
  );
};

export const ICGC = (props) => {
  return (
    <div className="text-justify">
      <Typography
        variant="h5"
        style={{ margin: "1em 0", fontWeight: 700 }}
        gutterBottom
      >
        {props.title}
      </Typography>

      <Typography variant="body1" gutterBottom>
        The ICGC came into being on 5th September 2001 with a group of teachers.
        Mr. K. K. Bora. President of the College GB formally inaugurated the
        cell on the occasion of College foundation day.
      </Typography>

      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        Why ICGC?
      </Typography>

      <Typography variant="body1" gutterBottom>
        "
        <i>
          Choice always involves knowledge. One cannot choose what one does not
          know….one may stumble into an appropriate occupation by sheer luck, by
          the wise choice of a occupation require accurate information about
          what occupation are available, what they require and what they offer….
        </i>
        " By- Hop pock
      </Typography>

      <Typography variant="body1" gutterBottom>
        <strong>
          No one can deny that a large amount of accurate, up-to-date
          information regarding educational and professional institutions like
          colleges, universities, jobs, training facilities, professional and
          technical courses, newly emerging occupation, jobs specifications,
          change in required skills, employment trends, is a vital for choosing
          any kind of career.
        </strong>
      </Typography>

      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        Aims and Objectives of ICGC:
      </Typography>

      <Typography variant="body1" gutterBottom>
        The Cell was established with the following aims and objectives to
        provide:
        <br />
        <ul>
          <li>Information on job opportunities.</li>
          <li>Information on educational and training facilities.</li>
          <li>
            Information on apprenticeship and on - the job training facilities.
          </li>
          <li>Information on scholarships and fellowships.</li>
          <li>
            Information on trends, pattern and growth of educational, training
            and employments.
          </li>
          <li>
            The Cell tries to perform following functions to achieve its aims
            and objectives:
            <ol>
              <li>
                To hold workshops, career talks, career conference, career
                exhibition, campus recruitment etc.
              </li>
              <li>To provide educational and career oriented information.</li>
              <li>
                To maintain a database or profile on the students who need
                information.
              </li>
            </ol>
          </li>
        </ul>
        <br />
        <p>
          Since its inception a number of different activities like workshops,
          seminars have been organized to provide opportunity of information,
          entrepreneurship career development of the students inside and outside
          the college.
        </p>
        <p>
          Presently a team of members under the co-ordinatorship of Mr. N.J.
          Bora of ICGC has started working in a very dynamic ways to guide and
          help the students in different ways.
        </p>
        <p>
          ICGC has already organised various seminars and workshops during the
          last session for the benifit of the students. Following are some of
          those activities.
        </p>
        <p>
          On 28th Aug 2004 a seminar on "Enterpreneurship Through Computer
          Education" was held . Mr. M.V. Reddy , Hyderabad , Mr. M.K. Agarwal ,
          Calcutta, Mr. Atunu Roy , Guwahati were the speakers in the
          seminar.The seminar was attended by a large number of students and
          teachers.
        </p>
        <p>
          Semianr on "Personality Development Success Unlimited" was held on 18
          Sep 2004. Mr. Sanjay Kedia was the Resource Person in the seminar.
        </p>
        <p>
          On 1st Oct 2004 a workshop on "Management As Carrer" was held with
          Mrs. Chandrabali Banerjee, Somangshu Roy Choudhury , Ms. Abhishikta
          Das as Resource Persons . Large number of students attened the seminar
          .
        </p>
        <p>
          "GIS and Remote Sensing" - A semiar was held on 12th Oct 2004. Dr.
          Rathin Sarma was the Key Speaker in the Seminar.Among all the
          partcipants most of the students were from Computer Science and
          Geography Department.
        </p>
        <p>
          ICGC organised one workshop on "Intensive Job Placement Assistance"
          for all the graduates along with 2nd and 3rd year degree students of
          Pandu College on 6th Nov 2004. Mr. Champak Das , Mr. Ranjoy
          Bhattacharjee were the interviewers .
        </p>
      </Typography>
    </div>
  );
};

const { TabPane } = Tabs;
