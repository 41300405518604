import React, { useState } from "react";
import "./Header.css";
import {
  MailOutlined,
  PhoneOutlined,
  FacebookFilled,
  TwitterOutlined,
  YoutubeFilled,
} from "@ant-design/icons";
import { Typography, useMediaQuery } from "@material-ui/core";
import Announcements from "../Announcements";
import FleshNews from "../Fleshnew";


const Header = () => {
  const [zoomLevel, setZoomLevel] = useState(100);

  const media = useMediaQuery('(max-width: 600px)')

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 10);
  };

  const handleZoomOriginal = () => {
    setZoomLevel(100);
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 10);
  };

  return (
    <header className="header" style={{ fontSize: `${zoomLevel}%` }}>
      <div className="header__left">{media ? 'News' : 'Latest News'}</div>
      <div className="header__right">
        <FleshNews />
      </div>

      {/* <div className="header__left">
    
       <Typography variant="body2">
          <a href="#" className="header__email">
            <MailOutlined className='header__icon' />
            principal@pragjyotishcollege.ac.in
          </a>
        </Typography>
       
      </div> */}
      {/* <div className="header__right">
        
        <input type="button" className='header__button' onClick={handleZoomIn} value="A+" />
        <input type="button" className='header__button' onClick={handleZoomOriginal} value="A" />
        <input type="button" className='header__button' onClick={handleZoomOut} value="A-" />
      </div> */}
    </header>
  );
};

export default Header;
