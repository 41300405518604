import React from 'react'
import { Typography, Container, Grid } from '@material-ui/core'
import { MDBCol, MDBRow } from 'mdbreact'
import TitleStyle from '../TitleStyle'
import { BackTop, Card, Tabs } from 'antd'
import TableView from './TableView'
import { PostData } from '../../api/service'
import { UserOutlined } from '@ant-design/icons'
import { Menu } from 'antd'

const smallTab = [
  {
    title: 'Home',
    active: 0
  },
  {
    title: 'Names of programmes offered',
    value: 'programs_offered',
    active: 1,
  },
  {
    title: 'Courses in collaboration with other universities, industries, foreign institutions, etc.',
    value: 'collaboration_course',
    active: 2,
  },
  {
    title: 'Number of teaching posts sanctioned, filled and actual (Associate Professors/Asst. Professors/others)',
    value: 'teaching_post',
    active: 3,
  },
  // {
  //     title: 'Contact Details',
  //     value: 'contact',
  //     active: 3
  // },
  {
    title: 'Programme-wise Student Teacher Ratio',
    value: 'student_teacher_ratio',
    active: 4,
  },
  {
    title: 'Number of academic support staff (technical)',
    value: 'academic_support_staff_technical',
    active: 5,
  },
  {
    title: 'Number of academic support staff (non-Technical)',
    value: 'academic_support_staff_non_technical',
    active: 6,
  },
  {
    title: 'Ongoing Research Projects',
    value: 'ongoing_research_projects',
    active: 7,
  },
  {
    title: 'Completed Research Projects',
    value: 'completed_research_projects',
    active: 8
  },

  {
    title: 'Departmental/Inter-institutional collaborative projects',
    value: 'collaborative_projects',
    active: 9,
  },
  {
    title: 'Research Publications',
    value: 'research_publications',
    active: 10,
  },
  {
    title: 'Chapter in Books',
    value: 'chapter_books',
    active: 11,
  },
  {
    title: 'Faculty selected nationally / internationally to visit other laboratories/institutions industries in India and abroad',
    value: 'selected_faculty',
    active: 12,
  },
  {
    title: 'Faculty serving',
    value: 'serving_faculty',
    active: 13,
  },
  {
    title: 'Faculty Improvement Programs',
    value: 'involvement_faculty',
    active: 14,
  },
  {
    title: 'Awards/recognitions received by faculties',
    value: 'awards_faculty',
    active: 15,
  },
  {
    title: 'Seminars/Conferences/Workshops organized by the department',
    value: 'seminar_department',
    active: 16,
  },
  {
    title: 'Departmental Publications',
    value: 'publications_department',
    active: 17,
  },
  {
    title: 'Student projects : In-house projects including inter-departmental projects',
    value: 'student_internal',
    active: 18,
  },
  {
    title: 'Student projects : Projects in collaboration with other institutions',
    value: 'student_other',
    active: 19,
  },
  {
    title: 'Student profile programme-wise',
    value: 'student_profile_programme',
    active: 20,
  },

  {
    title: 'Diversity of students',
    value: 'student_diversity',
    active: 21,
  },

  {
    title: 'How many students have cleared',
    value: 'student_cleared',
    active: 22,
  },

  {
    title: 'Student progressions',
    value: 'student_progressions',
    active: 23,
  },
]
const { TabPane } = Tabs

export default function ProfilePage({ data }) {


  const [active, setActive] = React.useState(0);

  let myRef = React.createRef([])

  const [rr, setRr] = React.useState([])

  function executeScroll(active) {

    rr[active].scrollIntoView({ behavior: 'smooth' })
  }

  const [loadedData, setLoadedData] = React.useState([])
  const [title, setTitle] = React.useState('Profile Home')

  React.useEffect(() => {
    loadOtherData()
  }, [])

  function callback(k) {

    executeScroll(k)
    let ac = smallTab.filter(el => el.active == k);

    if (ac.length > 0) {
      // search the data for the key

      setTitle(ac[0].title)
      setLoadedData(data[ac[0].value])

    }
  }

  const [loadedFaculty, setLoadedFaculty] = React.useState([])

  function loadOtherData() {
    let d = {
      dept_code: data.dept_code,
      type: 'FACULTY',
    }

    PostData(`/getemployeebytype`, d).then((resp) => {
      setLoadedFaculty(resp)


    })
  }

  const [fData, setFData] = React.useState([])

  function loadData() {
    PostData(`/employeeprofilewithdeptcode`, {
      dept_code: data.dept_code
    }).then((resp) => {
      setFData(resp)

    })
  }

  React.useEffect(() => {
    //loadData()
  }, [data.dept_code])

  return (
    <div>

      <Container className="mb-5 mt-5">

      

        <Grid container spacing={2}>
          <Grid item lg="9" sm="12" md="9" xs="12" style={{ backgroundColor: 'white' }}>
            <TitleStyle
              title={`Departmental Profile`}
            />



            {smallTab.map((el, index) => <div key={index} ref={(ref) => {
              rr[index] = ref
            }} style={{ marginBottom: 30 }}>
              <TableView fData={fData} dept_code={data.dept_code} active={el.active} title={el.title} data={data[el.value]} wholeData={data} />
            </div>)}

          </Grid>
          <Grid item lg="3" sm="12" md="3" xs="12">


            <div
              style={{
                borderLeft: 'solid 1px black',
                paddingLeft: 10,
                padding: 10,
                marginBottom: 10,
                backgroundColor: '#f9f9f9',
              }}
            >
              Faculty ({loadedFaculty.length})
            </div>
            {Array.isArray(loadedFaculty) &&
              loadedFaculty.map((el, index) => (
                <a
                  href={`/department/${data.dept_code}/faculty/single?id=${el.id}&name=${el.name}`}
                >
                  <div
                    key={index}
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: 12,
                      fontWeight: 700,
                    }}
                  >
                    <UserOutlined /> &nbsp;&nbsp; {el.name}
                  </div>
                </a>
              ))}

            {/* <div style={{ marginTop: 10, width: '300px' }}>

              <Tabs
                
                defaultActiveKey={active}
                activeKey={active}
                type="card"
                tabPosition="left"
                onChange={callback}
                style={{ width: 300, whiteSpace: "wrap" }}
                size="small"
                items={[...smallTab.map((el, index) => {
                  return ({
                    key: index,
                    label: el.title
                  })
                })]}
              />
               
            </div> */}

            <br />
            <br />






          </Grid>


        </Grid>
        <br></br>

      </Container>
    </div>
  )
}
