import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import { GetData } from "../../api/service";
import ResearchDescription from "./ResearchDescription";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";
import { Menu } from "antd";

const list = [
  {
    title: "Library",
    link: "/facility/library",
  },
  {
    title: "Boys Hostel",
    link: "/facility/boyshostel",
  },
  {
    title: "Girls Hostel",
    link: "/facility/girlshostel",
  },
  {
    title: "CIF",
    link: "/facility/cif",
  },
  {
    title: "Canteen",
    link: "/facility/canteen",
  },
  {
    title: "ICGC",
    link: "/facility/icgc",
  },
];

class ResearchContainer extends Component {
  state = {
    link: "",
    name: "",
    data: [],
    isLoaded: false,
  };
  componentDidMount() {
    this.fetchDepartments();
    let link = this.props.match.params.link;
    if (!link) {
      if (this.state.data.length > 0) {
        this.setState({
          link: this.state.data[0].dept_code,
        });
      }
    } else {
      this.setState({
        link: link,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.link != this.props.match.params.link) {
      let link = this.props.match.params.link;
      this.setState({
        link: link,
      });
    }
  }

  fetchDepartments = () => {
    GetData(`/getdepartments`).then((resp) => {
      this.setState(
        {
          data: resp,
          isLoaded: true,
        },
        () => {
          if (Array.isArray(this.state.data) && this.state.data.length > 0) {
            let link = this.props.match.params.link;
            if (!link) {
              this.setState({
                link: this.state.data[0].dept_code,
              });
            }
          }
        }
      );
    });
  };

  render() {
    return (
      <div>
        <BreadCrumbOwn title="Research" />
        <Container style={{ padding: 0, minHeight: 800, marginTop: 10, marginBottom: 10 }}>
          <Grid container spacing={2}>
            {/* <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ paddingTop: "5em" }}
            >
              <Card
                style={{
                  boxShadow: "none",
                  position: "stickey !important",
                  top: "0 !important",
                }}
              >
                <div
                  style={{
                    boxShadow: "none",
                    position: "stickey",
                    top: "0",
                  }}
                >
                  <List component="nav" >
                    {Array.isArray(this.state.data) && this.state.data.map((el, index) => (
                      <ListItem
                        button
                        style={{
                          border: "1px solid #0002",
                          marginBottom: "2px",
                          backgroundColor: el.link === `/research/${this.state.link}` ? '#F5EB3D' : '#f9f9f9'
                        }}

                        key={index}
                        onClick={() => this.props.history.push(`/research/${el.dept_code}`)}
                      >
                        <div
                          style={{
                            fontSize: "0.9em",
                            fontWeight: "500",
                            padding: "0",
                          }}
                        >
                          {el.dept_name}
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Card>
            </Grid> */}

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Menu
                theme="dark"
                mode="vertical"
                style={{ backgroundColor: "#4F58D2" }}
              >
                {this.state.data.map((el, index) => (
                  <Menu.Item
                    button
                    // style={{
                    //     border: "1px solid #0002",
                    //     marginBottom: "2px",
                    //     backgroundColor: el === this.state.com ? '#F5EB3D' : '#f9f9f9'
                    // }}
                    key={index}
                    //onClick={() => this.setCom(el)}
                    onClick={() =>
                      this.props.history.push(`/research/${el.dept_code}`)
                    }
                    style={{ fontWeight: 700, color: "white" }}
                  >
                    {el.dept_name}
                  </Menu.Item>
                ))}
              </Menu>
            </Grid>

            <Grid item xs={12} sm={12} md={9} lg={9}>
              <ResearchDescription
                dept={this.state.data}
                type={this.state.link}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default ResearchContainer;
