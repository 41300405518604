import React, { Component } from "react";
import {Link} from 'react-router-dom'
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  CardContent
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import { Menu, Divider, Button } from "antd";
import CommitteeDescriptionSmall from "./CommitteeDescriptionSmall";
import { GetData } from "../../api/service";



class CommitteeHolder extends Component {
  state = {
    link: "",
    data: [],
    isLoaded: false,
    com: {},
    child: false,
  };

  componentDidMount() {
    
    GetData(`/getcommittee`).then((resp) => {
      this.setState({
        data: resp,
        isLoaded: true,
      });
    });
  }

 


  render() {
    return (
      <div>

      <br />
      <br />
      <br />
       

       {this.state.isLoaded && Array.isArray(this.state.data) && this.state.data.map((el,index) => 
        <Card key={index} style={{ boxShadow: 'none', backgroundColor: '#F3F3F3', border: 'solid 1px #B9B9B9', marginBottom: 10 }}>
        <CardContent>
        <CommitteeDescriptionSmall data={el} /></CardContent>
      

        <Link to={`/committee-description?committeeId=${el.id}`} ><Button style={{width: "100%"}} type="primary" >View Details & Activities</Button></Link>
        </Card>)}
         
    
      </div>
    );
  }
}

export default CommitteeHolder;
