import { Typography, Grid } from "@material-ui/core";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import React, { PureComponent } from "react";

class CommitteeDescriptionSmall extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoaded: false,
    };
  }

  __loadFile = () => {
    this.setState(
      {
        data: this.props.data,
        isLoaded: true,
      },
      () => {
        //console.log(this.state);
      }
    );
  };

  componentDidMount() {
    this.__loadFile();
  }

  // componentDidMount() {
  //     this.setState({
  //         data: this.props.data,
  //         isLoaded: true,
  //     })
  // }

  render() {
    return (
      <>
        {this.state.isLoaded ? (
          <div>
            <Typography variant="h5" style={{ fontWeight: 700 }} gutterBottom>
              {this.state.data.name}
            </Typography>

            <Typography
              variant="subheader"
              style={{ fontWeight: 700 }}
              gutterBottom
            >
              Members of {this.state.data.name}
            </Typography>
            <ul>
              {this.state.data.members.map((el) => (
                <li>
                  <strong>{el.member_name}</strong>, {el.member_designation}
                </li>
              ))}
            </ul>
            <br />

            {/* {this.state.data.objectives != null && (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.data.objectives,
                  }}
                ></div>
              </>
            )}

            {this.state.data.tasks && this.state.data.tasks.length > 0 && (
              <>
              <h5 style={{ fontWeight: 500 }}>
                  Tasks & Activities of {this.state.data.name}
                </h5>
                <Grid container spacing={2}>
                  {Array.isArray(this.state.data.tasks) &&
                    this.state.data.tasks.map((el1, index1) => (
                      <Grid item xs={12} sm={12} md={12} lg={12} key={index1}>
                        <Typography
                          variant="subheader"
                          style={{ fontWeight: 700 }}
                          gutterBottom
                        >
                          {el1.title}
                        </Typography>

                        <div
                          dangerouslySetInnerHTML={{
                            __html: el1.description,
                          }}
                        ></div>

                        <ul>
                          {Array.isArray(el1.uploads) &&
                            el1.uploads.map((el2, index2) => (
                              <li key={index2}>
                                <img
                                  src={
                                    el2.fileType == "PDF"
                                      ? "/images/icons/pdf-icon.png"
                                      : "/images/icons/image-icon.png"
                                  }
                                  style={{ width: 20, marginRight: 10 }}
                                />
                                <a target={"_blank"} href={el2.url}>
                                  Attachment {index2+1}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </Grid>
                    ))}
                </Grid>
              </>
            )}
            {this.state.data.minutes != null &&
              Array.isArray(this.state.data.minutes) &&
              this.state.data.minutes.length > 0 && (
                <>
                  <h5 style={{ fontWeight: 500 }}>
                    Minutes of the meetings of {this.state.data.name}
                  </h5>

                  <ul>
                    {Array.isArray(this.state.data.minutes) &&
                      this.state.data.minutes.map((el, index) => (
                        <li key={index}>
                          <img
                            src={
                              el.fileType == "PDF"
                                ? "/images/icons/pdf-icon.png"
                                : "/images/icons/image-icon.png"
                            }
                            style={{ width: 20, marginRight: 10 }}
                          />
                          <a target={"_blank"} href={el.url}>
                            {el.path}
                          </a>
                        </li>
                      ))}
                  </ul>
                  <br />
                </>
              )} */}
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default CommitteeDescriptionSmall;
