import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import NavigateNext from "@material-ui/icons/NavigateNext";
import IqacDescription from "./IqacDescription";
import BreadCumb from "../Department/BreadCumb";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";
import { Menu } from "antd";
const { SubMenu } = Menu;
const list = [
  {
    title: "About",
    link: "/iqac/about",
  },
  {
    title: "IQAC Core Committees",
    link: "/iqac/core-committes",
  },
  {
    title: "IIQA",
    link: "/iqac/iiqa",
  },
  {
    title: "SSR",
    link: "/iqac/ssr",
  },
  {
    title: "SSR NAAC Matrices",
    link: "/iqac/ssrmatrix",
  },
  {
    title: "DVV",
    link: "/iqac/dvv",
  },
  {
    title: "AQAR",
    link: "/iqac/aqar",
  },
  {
    title: "AQAR NAAC Matrices",
    link: "/iqac/matrix",
  },
  {
    title: "Committees",
    link: "/iqac/units-and-wings",
  },
  {
    title: "AISHE Data",
    link: "/iqac/aishe-data",
  },
  {
    title: "Student Satisfactory Survey",
    link: "/iqac/sss",
  },
  {
    title: "Action Plan",
    link: "/iqac/actionplan",
  },
  {
    title: "Reports",
    link: "##123",
    subLink: [
      {
        title: "Annual Report",
        link: "/iqac/annual-report",
      },
      {
        title: "Audit Report",
        link: "/iqac/auditreport",
      },
    ],
  },

  // {
  //   title: "Annual Report",
  //   link: "/iqac/annual-report",
  // },

  
  {
    title: "Events and Meetings",
    link: "##125",
    subLink: [
      {
        title: "Minutes & Resolutions",
        link: "/iqac/minutes",
      },
      {
        title: "IQAC Event",
        link: "/iqac/event-news",
      },
    ],
  },
  {
    title: "Best Practice",
    link: "/iqac/best-practice",
  },
  {
    title: "Academic Calendar",
    link: "/iqac/academic-calendar",
  },
  {
    title: "Feedbacks",
    link: "##124",
    subLink: [
      {
        title: "Feedback Forms",
        link: "/iqac/feedback",
      },
      {
        title: "Feedback Analysis",
        link: "/iqac/feedback-analysis",
      },
    ],
  },

  

  

  {
    title: "MoUs & Collaborations",
    link: "/iqac/mou-collaborations",
  },

  {
    title: "Handbook & Policy Documents",
    link: "/iqac/handbook",
  },
  {
    title: "Dept. Advisory Committee",
    link: "/iqac/dac",
  },

  {
    title: "Satff Duty Fragmentation",
    link: "/iqac/sdf",
  },

  {
    title: "Codes, Guidelines & Circulars",
    link: "/iqac/cgc",
  },

  {
    title: "Organogram",
    link: "/iqac/organogram",
  },
  {
    title: "Institutional Distinctiveness",
    link: "/iqac/institutional-distingtiveness",
  },

  {
    title: "COs & POs",
    link: "/iqac/co_po",
  },
  {
    title: "NIRF",
    link: "/iqac/nirf",
  },

  {
    title: "DVV (Archives)",
    link: "/iqac/dvv-archives",
  },

  // {
  //   title: "Strategies",
  //   link: "/iqac/strategies",
  // },
  // {
  //   title: "Functions",
  //   link: "/iqac/functions",
  // },
  // {
  //   title: "IQAC Teams",
  //   link: "/iqac/teams",
  // },
  // {
  //   title: "IQAC Self Study Reports",
  //   link: "/iqac/ssr",
  // },

  // {
  //   title: "Action Taken Report",
  //   link: "/iqac/atr",
  // },
  // {
  //   title: "Meeting Minutes",
  //   link: "/iqac/minutes",
  // },
];

class IqacComponent extends Component {
  state = {
    link: "",
  };
  componentDidMount() {
    let link = this.props.match.params.link;
    this.setState({
      link: link,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.link != this.props.match.params.link) {
      let link = this.props.match.params.link;
      this.setState({
        link: link,
      });
    }
  }

  render() {
    return (
      <div>
        <BreadCrumbOwn title="IQAC" />
        <Container className="mb-5">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ paddingTop: "5em" }}
            >
              <Menu mode="inline" style={{ backgroundColor: "#EBEDEF" }}>
                {list.map((el, index) => {
                  if (el.subLink && el.subLink.length > 0) {
                    return (
                      <SubMenu
                        title={el.title}
                        style={{ backgroundColor: "#EBEDEF" }}
                      >
                        {el.subLink.map((el2, index2) => (
                          <Menu.Item
                            key={el2.link}
                            onClick={() => this.props.history.push(el2.link)}
                            style={{
                              backgroundColor:
                                el.link === `/iqac/${this.state.link}`
                                  ? "#F5EB3D"
                                  : "",
                            }}
                          >
                            {el2.title}
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    );
                  } else {
                    return (
                      <Menu.Item
                        button
                        // style={{
                        //     border: "1px solid #0002",
                        //     marginBottom: "2px",
                        //     backgroundColor: el === this.state.com ? '#F5EB3D' : '#f9f9f9'
                        // }}
                        key={index}
                        onClick={() => this.props.history.push(el.link)}
                        style={{
                          backgroundColor:
                            el.link === `/iqac/${this.state.link}`
                              ? "#F5EB3D"
                              : "",
                        }}
                      >
                        {el.title}
                      </Menu.Item>
                    );
                  }
                })}
              </Menu>
              {/* <Card
                style={{
                  boxShadow: "none",
                  position: "stickey !important",
                  top: "0 !important",
                }}
              >
                <div
                  style={{
                    boxShadow: "none",
                    position: "stickey",
                    top: "0",
                  }}
                >
                  <List component="nav">
                    {list.map((el, index) => (
                      <ListItem
                        button
                        style={{
                          border: "1px solid #0002",
                          marginBottom: "2px",
                          backgroundColor: el.link === `/iqac/${this.state.link}` ? '#F5EB3D' :'#f9f9f9'
                        }}
                        key={index}
                        onClick={() => this.props.history.push(el.link)}
                      >
                        <div
                          style={{
                            fontSize: "1.2em",
                            fontWeight: "700",
                            padding: "0",
                          }}
                        >
                          {el.title}
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Card> */}
            </Grid>

            <Grid item xs={12} sm={12} md={9} lg={9}>
              <IqacDescription type={this.state.link} />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default IqacComponent;
