import React, { Component } from 'react';
import { PostData } from '../../api/service';

class ShowImage extends Component {
    state = {
        image: {},
        isImageLoaded: false
    }

    componentDidMount() {
        let files = this.props.files;



        if (Array.isArray(files) && files.length > 0) {
            this.__getImage(files[0])
        }

    }


    __getImage = (data) => {

        this.setState({
            image: data.url,
            isImageLoaded: true
        })


    }
    render() {
        
        return (
            <div>
                {this.state.isImageLoaded ? (<img
                    src={this.state.image}
                    style={{ width: this.props.width, height: this.props.height, objectFit: "contain" }}
                />) : null}
            </div>
        );
    }
}

export default ShowImage;