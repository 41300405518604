import React, { Component } from "react";
import BreadCumb from "./../component/Department/BreadCumb";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Grid, Container, Typography, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import moment from 'moment'

const data = [
    {
     slNo: 1,
     name: "Tirtha Nath Sarma, M.A.",
     designation: "Principal",
     fromDate: "1954-09-01",
     toDate: "1971-03-31"
    },
    {
     slNo: 2,
     name: "Tarini Kanta Barua, M.A.",
     designation: "Principal",
     fromDate: "1971-04-01",
     toDate: "1987-02-28"
    },
    {
     slNo: 3,
     name: "Beda Kanta Baruah, M. Sc.",
     designation: "Principal i\/c",
     fromDate: "1987-03-01",
     toDate: "1987-11-30"
    },
    {
     slNo: 4,
     name: "Bhabananda Deka, M.A.",
     designation: "Principal",
     fromDate: "1987-12-01",
     toDate: "1992-06-30"
    },
    {
     slNo: 5,
     name: "Jagadish Choudhury, M.A.",
     designation: "Principal i\/c",
     fromDate: "1992-07-01",
     toDate: "1993-02-28"
    },
    {
     slNo: 6,
     name: "Dr. Bhagaban Ch. Moral, M.A., Ph.D.",
     designation: "Principal",
     fromDate: "1993-03-01",
     toDate: "1994-02-28"
    },
    {
     slNo: 7,
     name: "Bhupinder Singh, M. Sc.",
     designation: "Principal",
     fromDate: "1994-03-01",
     toDate: "1998-06-30"
    },
    {
     slNo: 8,
     name: "Dipal Kr. Ganguly, M. Sc.",
     designation: "Principal i\/c",
     fromDate: "1996-07-01",
     toDate: "1997-03-31"
    },
    {
     slNo: 9,
     name: "Pradip Mohan Dastidar, M. Sc.",
     designation: "Principal",
     fromDate: "1997-04-01",
     toDate: "2000-01-31"
    },
    {
     slNo: 10,
     name: "Abhay Nath Rai, M.A.",
     designation: "Principal, i\/c",
     fromDate: "2000-02-01",
     toDate: "2001-03-31"
    },
    {
     slNo: 11,
     name: "Dr. Nand Kishore Singh, M.A., Ph.D.",
     designation: "Principal i\/c",
     fromDate: "2001-04-01",
     toDate: "2001-06-18"
    },
    {
     slNo: 12,
     name: "Dr. Dayananda Pathak, M.A., LL.B., Ph.D",
     designation: "Principal",
     fromDate: "2001-06-18",
     toDate: "2011-01-31"
    },
    {
     slNo: 13,
     name: "Gauri Devi, M.A.",
     designation: "Principal i\/c",
     fromDate: "2011-02-01",
     toDate: "2012-07-31"
    },
    {
     slNo: 14,
     name: "Jayashree Devi, M.Sc.",
     designation: "Principal i\/c",
     fromDate: "2012-08-01",
     toDate: "2014-02-28"
    },
    {
     slNo: 15,
     name: "Haladhar Talukdar, M.A., M.Phil.",
     designation: "Principal i\/c",
     fromDate: "2014-03-01",
     toDate: "2015-02-28"
    },
    {
     slNo: 16,
     name: "Pallabi Sarmah, M.Sc., LL.B.",
     designation: "Principal i\/c",
     fromDate: "2015-03-01",
     toDate: "2016-01-31"
    },
    {
     slNo: 17,
     name: "Dr. Paramananda Rajbongshi, M.A., Ph.D.",
     designation: "Principal i\/c",
     fromDate: "2016-02-01",
     toDate: "2018-06-28"
    }
   ]

export default class FormerPrincipalsContainer extends Component {
    render() {
        return (
            <div>

                <BreadCrumbOwn title="Former Principals" />
                <Container style={{ minHeight: '600px' }} className="mb-5">
                    <br></br>
                    <div>
                    <Typography variant="body2">
                        <Table className="table-sm">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Designation</TableCell>
                                    <TableCell>From</TableCell>
                                    <TableCell>To</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((el,index) => <TableRow key={index}>

                                    <TableCell>{index+1}</TableCell>
                                    <TableCell>{el.name}</TableCell>
                                    <TableCell>{el.designation}</TableCell>
                                    <TableCell>{moment(el.fromDate,"YYYY-MM-DD").format("DD-MM-YYYY")}</TableCell>
                                    <TableCell>{moment(el.toDate,"YYYY-MM-DD").format("DD-MM-YYYY")}</TableCell>

                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </Typography>

                    </div>
                </Container>
            </div>
        );
    }
}
