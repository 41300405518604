import React, { Component } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Container,
} from "@material-ui/core";
import { Menu, Tabs } from "antd";
import { Button } from "@material-ui/core";
import { PostData } from "../../api/service";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";
import HightlightShort from "../HightlightShort";
import Achievements from "../Department/Achievements";
import AchievementShort from "../Department/AchievementShort";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { GoverningBody } from "../Alumni/AlumniDescription";
class NccFacilityDescription extends Component {
  state = {
    active: 0,
  };
  render() {
    return (
      <div style={{ marginTop: "" }}>
        <Library title="National Cadet Corps" type="NCC_HOME" />

        <Container>
          

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <a onClick={() => this.setState({
              active: 0
            })}>
              <div
                style={{
                  border: "solid thin lightgrey",
                  padding: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginRight: 10,
                  width: 150,
                  backgroundColor: this.state.active == 0 ? "#1976D2" : "white",
                }}
              >
                <div>
                  <LocalActivityIcon style={{ color: this.state.active == 0 ? "white": "black" }} />
                </div>
                <div style={{ fontWeight: 700, color: this.state.active == 0 ? "white": "black" }}>
                  Activities
                </div>
              </div>
            </a>

            <a onClick={() => this.setState({
              active: 1
            })}>
            <div
              style={{
                border: "solid thin lightgrey",
                padding: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginRight: 10,
                width: 150,
                backgroundColor: this.state.active == 1 ? "#1976D2" : "white",
              }}
            >
              <div>
                <LoyaltyIcon  style={{ color: this.state.active == 1 ? "white": "black" }} />
              </div>
              <div style={{ fontWeight: 700, color: this.state.active == 1 ? "white": "black" }}>Achievements</div>
            </div>
            </a>

            <a onClick={() => this.setState({
              active: 2
            })}>
            <div
              style={{
                border: "solid thin lightgrey",
                padding: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginRight: 10,
                width: 150,
                backgroundColor: this.state.active == 2 ? "#1976D2" : "white",
              }}
            >
              <div>
                <PeopleAltIcon  style={{ color: this.state.active == 2 ? "white": "black" }} />
              </div>
              <div style={{ fontWeight: 700, color: this.state.active == 2 ? "white": "black" }}>Members</div>
            </div>
            </a>
          </div>

          <br />

          {this.state.active == 0 && (
            <div>
              <AchievementShort
                dept_code="PRAG"
                type="NCC_ACTIVITIES"
                title="NCC Activities"
              />
            </div>
          )}
          {this.state.active == 1 && (
            <div>
              <AchievementShort
                dept_code="PRAG"
                type="NCC_ACHIEVEMENTS"
                title="NCC Achievements"
              />
            </div>
          )}


          {this.state.active == 2 ? (<GoverningBody title="NCC Members" type="NCC_MEMBERS" />) : null}

          <br />
        </Container>

        {this.props.type == "ncc_army" && (
          <Library title="NCC Army Wing" type="COLLEGE_NCC_ARMY" />
        )}
        {this.props.type == "ncc_navy" && (
          <Library title="NCC Navy Wing" type="COLLEGE_NCC_NAVY" />
        )}
        {this.props.type == "ncc_air" && (
          <Library title="NCC Air Force Wing" type="COLLEGE_NCC_AIR" />
        )}
        {this.props.type == "ncc_girl" && (
          <Library title="NCC Girl's Wing" type="COLLEGE_NCC_GIRL" />
        )}
      </div>
    );
  }
}

export default NccFacilityDescription;

export const Library = (props) => {
  const [data, setData] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    __loadFile();
  }, []);

  function __loadFile() {
    let d = {
      dept_code: "PRAG",
      type: props.type,
      year: "",
    };

    PostData(`/getnoticesbytypedept`, d).then((resp) => {
      setData(resp);
      setIsLoaded(true);
    });
  }

  return (
    <>
      <BreadCrumbOwn title="National Cadet Corps" />
      <Container className="mb-5">
        <div className="text-justify">
          <Typography
            variant="h5"
            style={{ margin: "1em 0", fontWeight: 700 }}
            gutterBottom
          >
            {props.title}
          </Typography>

          <HightlightShort type="NCC_BANNER" />

          {isLoaded && (
            <div>
              {Array.isArray(data) &&
                data.map((el, index) => (
                  <div key={index}>
                    <Typography variant="body1" gutterBottom>
                      <p
                        variant="body1"
                        dangerouslySetInnerHTML={{ __html: el.description }}
                      />
                    </Typography>

                    <br />

                    <Grid container spacing={2}>
                      {Array.isArray(el.upload_info) &&
                        el.upload_info.map((el, index) => (
                          <Grid
                            key={index}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                          >
                            {el.fileType != "PDF" && (
                              <img src={el.url} style={{ width: "100%" }} />
                            )}

                            {el.fileType == "PDF" && (
                              <a target="_blank" href={el.url}>
                                {el.path}
                              </a>
                            )}
                          </Grid>
                        ))}
                    </Grid>
                  </div>
                ))}
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

const { TabPane } = Tabs;
