import React, { Component } from 'react';
import { withStyles, Card, CardContent, Typography, Divider } from '@material-ui/core';
// import Table from 'react-bootstrap/Table'
import { orange } from '@material-ui/core/colors';
import moment from 'moment'
import { light } from '@material-ui/core/styles/createPalette';
import { LinkOutlined } from '@ant-design/icons';
import { SERVER_UPLOAD, SERVER_UPLOAD_NEW } from '../../api/service';
const styles = theme => ({

})

const periods = [
    {
        period: 1,
        start_time: '09:00',
        end_time: '10:00'
    },
    {
        period: 2,
        start_time: '10:00',
        end_time: '11:00'
    },
    {
        period: 3,
        start_time: '11:00',
        end_time: '12:00'
    },
    {
        period: 4,
        start_time: '12:00',
        end_time: '13:00'
    },
    {
        period: 5,
        start_time: '13:00',
        end_time: '14:00'
    },
    {
        period: 6,
        start_time: '14:00',
        end_time: '15:00'
    },
    {
        period: 7,
        start_time: '15:00',
        end_time: '16:00'
    }
]

class FacultyQReportFromMain extends Component {

    componentDidMount(){
        console.log(this.props)
    }

    
    render() {
        let { classes } = this.props;
        return (
            <div>
                        {this.props.activities?.map((el,index)=><>
                            {el.activity_sub_id=='1.1'?<table className="table table-bordered table-striped" key={index}>
                        <thead>
                            <tr className="bg-light">
                               <td colSpan={5}  align="center">
                                {el.activity_name}
                               </td>
                            </tr>
                            <tr>
                            <td align='center' style={{fontWeight:'bold'}}>
                                Program
                            </td>
                            <td align='center' style={{fontWeight:'bold'}}>
                                Semester/Year
                            </td>
                            <td align='center' style={{fontWeight:'bold'}}>
                                Course No
                            </td>
                            {/* <td align='center' style={{fontWeight:'bold'}}>
                                Topics Covered
                            </td> */}
                            {/* <td align='center' style={{fontWeight:'bold'}}>
                                No. of classes (1 hour duration)
                            </td> */}
                            </tr>
                        </thead>
                        <tbody>
                           {Array.isArray(el.activity_data) && el.activity_data.length > 0?el.activity_data?.map((el1,idx1)=>
                           <tr key={idx1}>
                             <td align='center' >
                                {el1.label}
                                
                             </td>
                             <td align='center' >
                                {el1.semester}
                             </td>
                             <td align='center' >
                                {el1.paper_code} <br />
                                {el1.paper_name}
                             </td>
                             {/* <td align='center' >
                             {Array.isArray(el1.topics) && el1.topics.length > 0?el1.topics?.map((el2,idx1)=>
                            <>
                             {el2?.topic_title}
                             {idx1<el1.topics.length-1?<Divider light={false} />:null}

                             </>
                             ):'NA'}
                             </td> */}
                             {/* <td align='center' >
                                {el1.no_of_classes} 
                             </td> */}
                           </tr>
                           ):<tr><td colSpan={5} align='center' >NA</td></tr>}
                            </tbody>
                            </table>:null}

                            {el.activity_sub_id=='1.2'?<table className="table table-bordered table-striped" key={index}>
                        <thead>
                            <tr className="bg-light">
                               <td colSpan={2}  align="center">
                                {el.activity_name}
                               </td>
                            </tr>
                           
                        </thead>
                        <tbody>
                           {Array.isArray(el.activity_data) && el.activity_data.length > 0?el.activity_data?.map((el1,idx1)=>
                           <tr key={idx1}>
                             <td align='center' style={{fontWeight:'bold'}}>
                                {el1.section_name}
                             </td>
                            
                             <td align='center' >
                             {Array.isArray(el1.section_data) && el1.section_data.length > 0?el1.section_data?.map((el2,idx2)=>
                            <>
                            Activity : {el2?.activity}
                             <br />
                            Remarks : {el2?.remark}
                             <br />
                             Student Count : {el2?.student_count}
                             <br />
                             {Array.isArray(el2.file) && el2.file.map((el12, index12) => <div key={index12}>{el12.mime != 'link' ? <a target="_blank" href={`${SERVER_UPLOAD_NEW}/${el12.type}/${el12.path}`} style={{ color: 'blue', fontSize: 10 }}><LinkOutlined /> Attachment {index12 + 1}</a> : <a target="_blank" href={`${el12.path}`} style={{ color: 'blue', fontSize: 10 }}><LinkOutlined /> Attachment {index12 + 1}</a>}</div>)}
                             {idx2<el1.section_data.length-1?<Divider light={false} />:null}
                             </>
                             ):'NA'}
                             </td>
                            
                           </tr>
                           ):<tr><td colSpan={2} align='center' >NA</td></tr>}
                            </tbody>
                            </table>:null}

                            {el.activity_sub_id=='1.3'?<table className="table table-bordered table-striped" key={index}>
                        <thead>
                            <tr className="bg-light">
                               <td colSpan={4}  align="center">
                                {el.activity_name}
                               </td>
                           </tr>
                           <tr>
                               <td style={{fontWeight:'bold'}} align="center">
                                Title of Meet
                               </td>
                            
                               <td style={{fontWeight:'bold'}} align="center">
                                Category
                               </td>

                               <td style={{fontWeight:'bold'}} align="center">
                                Name (s) of Organizer
                               </td>

                               <td style={{fontWeight:'bold'}} align="center">
                               Dates (From - To)
                               </td>
                            </tr>
                           
                        </thead>
                        <tbody>
                           {Array.isArray(el.activity_data) && el.activity_data.length > 0?el.activity_data?.map((el1,idx1)=>
                            
                            
                            <tr>
                                <td align='center' >
                                    {el1.title}
                                  
                                {Array.isArray(el1.file) && el1.file.map((el12, index12) => <div key={index12}>{el12.mime != 'link' ? <a target="_blank" href={`${SERVER_UPLOAD_NEW}/${el12.type}/${el12.path}`} style={{ color: 'blue', fontSize: 10 }}><LinkOutlined /> Attachment {index12 + 1}</a> : <a target="_blank" href={`${el12.path}`} style={{ color: 'blue', fontSize: 10 }}><LinkOutlined /> Attachment {index12 + 1}</a>}</div>)}
                                </td>
                                <td align='center' >
                                    {el1.category}
                                </td>
                                <td align='center' >
                                    {el1.organizer}
                                </td>
                                <td align='center' >
                                    {el1.from} to {el1.till}
                                </td>
                            </tr>
                             
                            
                           ):<tr><td colSpan={4} align='center' >NA</td></tr>}
                            </tbody>
                            </table>:null}

                            {el.activity_sub_id=='1.4'?<table className="table table-bordered table-striped" key={index}>
                        <thead>
                            <tr className="bg-light">
                               <td colSpan={6}  align="center">
                                {el.activity_name}
                               </td>
                           </tr>
                           <tr>
                               <td style={{fontWeight:'bold'}} align="center">
                                Title (s) of Event Organized
                               </td>
                            
                               <td style={{fontWeight:'bold'}} align="center">
                                Types
                               </td>

                               <td style={{fontWeight:'bold'}} align="center">
                               Dates (From - To)
                               </td>

                               <td style={{fontWeight:'bold'}} align="center">
                              Collaborating Unit
                               </td>
                               <td style={{fontWeight:'bold'}} align="center">
                            Category
                               </td>
                               <td style={{fontWeight:'bold'}} align="center">
                            No of Participants
                               </td>
                            </tr>
                           
                        </thead>
                        <tbody>
                           {Array.isArray(el.activity_data) && el.activity_data.length > 0?el.activity_data?.map((el1,idx1)=>
                            
                            
                            <tr>
                                <td align='center' >
                                    {el1.title}
                                    {Array.isArray(el1.file) && el1.file.map((el12, index12) => <div key={index12}>{el12.mime != 'link' ? <a target="_blank" href={`${SERVER_UPLOAD_NEW}/${el12.type}/${el12.path}`} style={{ color: 'blue', fontSize: 10 }}><LinkOutlined /> Attachment {index12 + 1}</a> : <a target="_blank" href={`${el12.path}`} style={{ color: 'blue', fontSize: 10 }}><LinkOutlined /> Attachment {index12 + 1}</a>}</div>)}
                                </td>
                                <td align='center' >
                                    {el1.type}
                                </td>
                                <td align='center' >
                                    {el1.from} to {el1.till}
                                </td>
                                <td align='center' >
                                   {el1.collaborating_unit}
                                </td>
                                <td align='center' >
                                   {el1.category}
                                </td>
                                <td align='center' >
                                   {el1.participant}
                                </td>
                            </tr>
                             
                            
                           ):<tr><td colSpan={6} align='center' >NA</td></tr>}
                            </tbody>
                            </table>:null}

                            {el.activity_sub_id=='2'?<table className="table table-bordered table-striped" key={index}>
                        <thead>
                            <tr className="bg-light">
                               <td colSpan={6}  align="center">
                                {el.activity_name}
                               </td>
                           </tr>
                           <tr>
                               <td style={{fontWeight:'bold'}} align="center">
                                Title (s) of Activity Organized
                               </td>
                            
                               <td style={{fontWeight:'bold'}} align="center">
                                Primary Unit
                               </td>

                               <td style={{fontWeight:'bold'}} align="center">
                              Collaborating Unit
                               </td>

                              
                               <td style={{fontWeight:'bold'}} align="center">
                            No of Participants
                               </td>

                               <td style={{fontWeight:'bold'}} align="center">
                               Dates (From - To)
                               </td>
                            </tr>
                           
                        </thead>
                        <tbody>
                           {Array.isArray(el.activity_data) && el.activity_data.length > 0?el.activity_data?.map((el1,idx1)=>
                            
                            
                            <tr>
                                <td align='center' >
                                    {el1.title}
                                    {Array.isArray(el1.file) && el1.file.map((el12, index12) => <div key={index12}>{el12.mime != 'link' ? <a target="_blank" href={`${SERVER_UPLOAD_NEW}/${el12.type}/${el12.path}`} style={{ color: 'blue', fontSize: 10 }}><LinkOutlined /> Attachment {index12 + 1}</a> : <a target="_blank" href={`${el12.path}`} style={{ color: 'blue', fontSize: 10 }}><LinkOutlined /> Attachment {index12 + 1}</a>}</div>)}
                                </td>
                                <td align='center' >
                                    {el1.primary_unit}
                                </td>
                                <td align='center' >
                                   {el1.collaborating_unit}
                                </td>
                                
                                <td align='center' >
                                   {el1.participant}
                                </td>

                                <td align='center' >
                                    {el1.from} to {el1.till}
                                </td>
                            </tr>
                             
                            
                           ):<tr><td colSpan={6} align='center' >NA</td></tr>}
                            </tbody>
                            </table>:null}

                            {el.activity_sub_id=='3'?<table className="table table-bordered table-striped" key={index}>
                        <thead>
                            <tr className="bg-light">
                               <td colSpan={2}  align="center">
                                {el.activity_name}
                               </td>
                            </tr>
                           
                        </thead>
                        <tbody>
                           {Array.isArray(el.activity_data) && el.activity_data.length > 0?el.activity_data?.map((el1,idx1)=>
                           <tr key={idx1}>
                             <td align='center' style={{fontWeight:'bold'}}>
                                {el1.section_name}
                             </td>
                            
                             <td align='center' >
                             {Array.isArray(el1.section_data) && el1.section_data.length > 0?el1.section_data?.map((el2,idx2)=>
                            <>
                            Activity : {el2?.activity}

                             <br />
                            Remarks : {el2?.remark}
                             <br />
                            {Array.isArray(el2.file) && el2.file.map((el12, index12) => <div key={index12}>{el12.mime != 'link' ? <a target="_blank" href={`${SERVER_UPLOAD_NEW}/${el12.type}/${el12.path}`} style={{ color: 'blue', fontSize: 10 }}><LinkOutlined /> Attachment {index12 + 1}</a> : <a target="_blank" href={`${el12.path}`} style={{ color: 'blue', fontSize: 10 }}><LinkOutlined /> Attachment {index12 + 1}</a>}</div>)}
                             {idx2<el1.section_data.length-1?<Divider light={false} />:null}
                             </>
                             ):'NA'}
                             </td>
                            
                           </tr>
                           ):<tr><td colSpan={2} align='center' >NA</td></tr>}
                            </tbody>
                            </table>:null}

                            {el.activity_sub_id=='4.1'?<table className="table table-bordered table-striped" key={index}>
                        <thead>
                            <tr className="bg-light">
                               <td colSpan={9}  align="center">
                                {el.activity_name}
                               </td>
                            </tr>
                            <tr>
                                <td style={{fontStyle:'bold'}}>
                                Type of Project
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Funding Agency
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Duration of the Project
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Amount Sanctioned (in Lakhs)
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Amount received during the period
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Co-investigator (if any)
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Status
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Date of Commencement
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Date of Completion
                                </td>
                            </tr>
                           
                        </thead>
                        <tbody>
                           {Array.isArray(el.activity_data) && el.activity_data.length > 0?el.activity_data?.map((el1,idx1)=>
                           <tr key={idx1}>
                             <td align='center' >
                                {el1.title}
                                {Array.isArray(el1.file) && el1.file.map((el12, index12) => <div key={index12}>{el12.mime != 'link' ? <a target="_blank" href={`${SERVER_UPLOAD_NEW}/${el12.type}/${el12.path}`} style={{ color: 'blue', fontSize: 10 }}><LinkOutlined /> Attachment {index12 + 1}</a> : <a target="_blank" href={`${el12.path}`} style={{ color: 'blue', fontSize: 10 }}><LinkOutlined /> Attachment {index12 + 1}</a>}</div>)}
                             </td>
                             <td align='center' >
                                {el1.sponsored_by}
                             </td>
                             <td align='center' >
                                {el1.duration}
                             </td>
                             <td align='center' >
                                {el1.fund}
                             </td>
                             <td align='center' >
                                {el1.fund_received}
                             </td>
                             <td align='center' >
                                {el1.co_researcher}
                             </td>
                             <td align='center' >
                                {el1.status}
                             </td>
                             <td align='center' >
                                {el1.commencement_date}
                             </td>
                             <td align='center' >
                                {el1.submission_date}
                             </td>
                            
                           </tr>
                           ):<tr><td colSpan={9} align='center' >NA</td></tr>}
                            </tbody>
                            </table>:null}
                            {el.activity_sub_id=='4.2'?<table className="table table-bordered table-striped" key={index}>
                        <thead>
                            <tr className="bg-light">
                               <td colSpan={10}  align="center">
                                {el.activity_name}
                               </td>
                            </tr>
                            <tr>
                                <td style={{fontStyle:'bold'}}>
                                Title of Publication
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Name of Journal/Volume	
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                UGC-CARE listed
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Impact Factor (if any)
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Citation Index (if any)
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                h-index (if any)
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Category of Publication
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Page No
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Volume
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                ISSN/ISBN
                                </td>
                                
                            </tr>
                           
                        </thead>
                        <tbody>
                           {Array.isArray(el.activity_data) && el.activity_data.length > 0?el.activity_data?.map((el1,idx1)=>
                           <tr key={idx1}>
                             <td align='center' >
                                {el1.title}
                                {Array.isArray(el1.file) && el1.file.map((el12, index12) => <div key={index12}>{el12.mime != 'link' ? <a target="_blank" href={`${SERVER_UPLOAD_NEW}/${el12.type}/${el12.path}`} style={{ color: 'blue', fontSize: 10 }}><LinkOutlined /> Attachment {index12 + 1}</a> : <a target="_blank" href={`${el12.path}`} style={{ color: 'blue', fontSize: 10 }}><LinkOutlined /> Attachment {index12 + 1}</a>}</div>)}
                             </td>
                             <td align='center' >
                                {el1.journal_name}
                             </td>
                             <td align='center' >
                                {el1.ugc_care}
                             </td>
                             <td align='center' >
                                {el1.impact_factor}
                             </td>
                             <td align='center' >
                                {el1.citation_index}
                             </td>
                             <td align='center' >
                                {el1.h_index}
                             </td>
                             <td align='center' >
                                {el1.category}
                             </td>
                             <td align='center' >
                                {el1.page_no}
                             </td>
                             <td align='center' >
                                {el1.volume}
                             </td>
                             <td align='center' >
                                {el1.isbn}
                             </td>
                            
                           </tr>
                           ):<tr><td colSpan={10} align='center' >NA</td></tr>}
                            </tbody>
                            </table>:null}
                            {el.activity_sub_id=='4.3'?<table className="table table-bordered table-striped" key={index}>
                        <thead>
                            <tr className="bg-light">
                               <td colSpan={7}  align="center">
                                {el.activity_name}
                               </td>
                            </tr>
                            <tr>
                                <td style={{fontStyle:'bold'}}>
                                Title of Publication
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Name of Journal/Volume	
                                </td>
                                
                                <td style={{fontStyle:'bold'}}>
                                Category of Publication
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Date of Publication
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Page No
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                Volume
                                </td>
                                <td style={{fontStyle:'bold'}}>
                                ISSN/ISBN
                                </td>
                                
                            </tr>
                           
                        </thead>
                        <tbody>
                           {Array.isArray(el.activity_data) && el.activity_data.length > 0?el.activity_data?.map((el1,idx1)=>
                           <tr key={idx1}>
                             <td align='center' >
                                {el1.title}
                                {Array.isArray(el1.file) && el1.file.map((el12, index12) => <div key={index12}>{el12.mime != 'link' ? <a target="_blank" href={`${SERVER_UPLOAD_NEW}/${el12.type}/${el12.path}`} style={{ color: 'blue', fontSize: 10 }}><LinkOutlined /> Attachment {index12 + 1}</a> : <a target="_blank" href={`${el12.path}`} style={{ color: 'blue', fontSize: 10 }}><LinkOutlined /> Attachment {index12 + 1}</a>}</div>)}
                             </td>
                             <td align='center' >
                                {el1.journal_name}
                             </td>
                             
                             <td align='center' >
                                {el1.category}
                             </td>
                             <td align='center' >
                                {el1.publication_date}
                             </td>
                             <td align='center' >
                                {el1.page_no}
                             </td>
                             <td align='center' >
                                {el1.volume}
                             </td>
                             <td align='center' >
                                {el1.isbn}
                             </td>
                            
                           </tr>
                           ):<tr><td colSpan={7} align='center' >NA</td></tr>}
                            </tbody>
                            </table>:null}

                            </>
                        )}
                   
            </div>
        );
    }
}

export default withStyles(styles)(FacultyQReportFromMain);