import React, { Component } from "react";
import BreadCumb from "./../component/Department/BreadCumb";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Grid, Container, Typography } from "@material-ui/core";
import { LibraryOwnWithoutAnything } from "./KkhsouContainer";
import NotificationDescription from "../component/Notifications/NotificationDescription";
import { Tabs } from "antd";

const { TabPane } = Tabs;
export default class IdolContainer extends Component {
  render() {
    return (
      <div>
        <BreadCrumbOwn title="GUCDOE" />
        <Container style={{ minHeight: "600px" }} className="mb-5">
          <br></br>
          <div>
            <LibraryOwnWithoutAnything title="" type="COLLEGE_IDOL" />

            <div style={{ backgroundColor: "#eaecee", padding: 10 }}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="News & Notifications" key="1">
                  <NotificationDescription
                    code="COLLEGE_IDOL_NEWS"
                    title="News & Notifications"
                  />
                </TabPane>
                <TabPane tab="Documents" key="2">
                  <NotificationDescription
                    code="COLLEGE_IDOL_DOCUMENTS"
                    title="Documents"
                  />
                </TabPane>
              </Tabs>
            </div>

            {/* <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={8} lg={8} style={{ backgroundColor: "#F2F4F4" }}>

                                <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                                    Programms Under INSTITUTE OF DISTANCE AND OPEN LEARNING (IDOL), GAUHATI UNIVERSITY
                                </Typography>


                                <Typography variant="h6" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                                P.G. Degree Programs
                                </Typography>
                                <Typography variant="body1">

                                    <ul>
                                        <li>M.A. (Assamese)</li>
                                        <li>M.A. (Bengali)</li>
                                        <li>M.A. (Economics)</li>
                                        <li>M.A. (Education)</li>
                                        <li>M.A. (English)</li>
                                        <li>M.A. (History)</li>
                                        <li>M.A. (Mathematics)</li>
                                        <li>M.A. (Philosophy)</li>
                                        <li>M.A. (Political Science)</li>
                                        <li>M.Com.</li>
                                        <li>M.Sc. (Economics)</li>
                                        <li>M.Sc. Mathematics</li>
                                    </ul>

                                </Typography>

                                <Typography variant="h6" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                                U.G. Degree Programs
                                </Typography>
                                <Typography variant="body1">

                                    <ul>
                                        <li>B.A. (Regular Course)</li>
                                        <li>B.Com. (Regular Course)</li>
                                        <li>B.C.A.</li>
                                        <li>B.Sc. (I.T.)</li>
                                    </ul>

                                </Typography>


                                <Typography variant="h6" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                                P.G. Diploma Programs
                                </Typography>
                                <Typography variant="body1">

                                    <ul>
                                       <li>Computer Application (PGDCA)</li>
                                       <li>Business Management (PGDMB)</li>
                                       <li>Sales & Marketing Management (PGDSMM)</li>
                                       <li>Financial Management (PGDFM)</li>
                                       <li>Human Resource Management (PGDHRM)</li>
                                       <li>Banking & Financial Services (PGDBFS)</li>
                                       <li>Insurance Management (PGDIM)</li>
                                    </ul>

                                </Typography>


                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>

                                <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                                    COORDINATOR
                                </Typography>
                               
                                <Typography variant="body1">
                                    <strong>Pradip Chandra Kalita</strong>
                                    <br />
                                    Associate Professor
                                    <br />
                                    Geography Department
                                    <br />

                                    Cell:{" "}
                                    <a href={`tel:98640 42577`}>
                                    98640 42577
                                    </a>{" "}
                                    <br />
                                    Office:{" "}
                                    <a href={`tel:60009 06912`}>
                                    60009 06912
                                    </a>{" "}
                                    <br />

                                </Typography>

                            </Grid>

                        </Grid> */}
          </div>
        </Container>
      </div>
    );
  }
}
