import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import { GetData, PostData } from "../api/service";
import CommitteeDescription from "../component/Committee/CommitteeDescription";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Menu,Spin } from "antd";
import queryString from "query-string";

// // import FacilityDescription from "./FacilityDescription";

// const list = [
//     {
//         title: "Library",
//         link: "/facility/library",
//     },
//     {
//         title: "Boys Hostel",
//         link: "/facility/boyshostel",
//     },
//     {
//         title: "Girls Hostel",
//         link: "/facility/girlshostel",
//     },
//     {
//         title: "CIF",
//         link: "/facility/cif",
//     },
//     {
//         title: "Canteen",
//         link: "/facility/canteen",
//     },
//     {
//         title: "ICGC",
//         link: "/facility/icgc",
//     },
// ];

class CommitteesDescriptionContainer extends Component {
  state = {
    link: "",
    committee_id: "",
    data: [],
    isLoaded: false,
    com: {},
    child: false,
  };

  componentDidMount() {
    let queryData = queryString.parse(this.props.location.search);

    this.setState({
      committee_id: queryData.committeeId,
    });
    PostData(`/getcommitteebyid`, {
      committee_id: queryData.committeeId,
    }).then((resp) => {
      this.setState({
        data: resp,
        isLoaded: true,
      });
    });
  }

  render() {
    return (
      <div>
      {!this.state.isLoaded && <BreadCrumbOwn title={`Committees`} />}
        {this.state.isLoaded && <BreadCrumbOwn title={this.state.data.name} />}

        <Container style={{ paddingTop: 20 }}>
          {this.state.isLoaded && (
            <CommitteeDescription data={this.state.data} />
          )}

          {!this.state.isLoaded && (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: 400}}>
              <Spin />
            </div>
          )}
        </Container>
      </div>
    );
  }
}

export default CommitteesDescriptionContainer;
