import React, { Component } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import PrincipalContainer from "./PrincipalContainer";
import GoverningBody from "./GoverningBody";

import { MDBContainer } from "mdbreact";
import { GetData, PostData } from "../../api/service";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";
import FormerPrincipalsContainer from "../../containers/FormerPrincipalsContainer";
import NotificationDescription from "../Notifications/NotificationDescription";

class AdministrationDescription extends Component {
  render() {
    return (
      <div style={{ marginTop: "" }}>
        {this.props.type == "principal" ? <PrincipalContainer /> : null}
        {this.props.type == "gb" ? <GoverningBody /> : null}
        {this.props.type == "officestaff" ? <OfficeStaff /> : null}
        {this.props.type == "formerprincipals" ? (
          <FormerPrincipalsContainer />
        ) : null}
        {this.props.type == "organogram" ? (
          <>
            <BreadCrumbOwn title="Organogram" />

            <Container className="mb-5">
              <NotificationDescription
                type="streategies"
                code="IQAC_ORGANOGRAM"
                title="Organogram"
              />
            </Container>
          </>
        ) : null}
      </div>
    );
  }
}

export default AdministrationDescription;

export const OfficeStaff = () => {
  const [state, setState] = React.useState({
    data: [],
    isDataLoaded: false,
  });

  function __getDeptData() {
    let d = {
      dept_code: "PRAG",
      type: "OFFICE_STAFF",
    };

    PostData(`/getemployeebytype`, d).then((resp) => {
      setState((prevState) => {
        return {
          ...prevState,
          data: resp,
          isDataLoaded: true,
        };
      });
    });
  }

  React.useEffect(() => {
    __getDeptData();
  }, []);

  return (
    <div>
      <BreadCrumbOwn title="Office Staff" />

      <Container className="mb-5">
        <Typography
          variant="h5"
          style={{ margin: "1em 0", fontWeight: 700 }}
          gutterBottom
        >
          Office Staff
        </Typography>

        <Table className="table table-sm table-striped">
          <TableHead>
            <TableRow>
              <TableCell>Photo</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Designation</TableCell>
              <TableCell>Email & Phone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.data.map((el, index) => (
              <TableRow key={index}>
                <TableCell>
                  {Array.isArray(el.dp) &&
                    el.dp.map((el1) => <img src={el1} style={{ width: 150 }} />)}
                </TableCell>
                <TableCell>
                  <Typography variant="body1" style={{ fontWeight: 700 }}>
                    {el.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{el.designation}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {el.email} <br />
                    {el.phone}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    </div>
  );
};
