import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  CardActions,
  Button,
  CardContent,
} from "@material-ui/core";

import DepertmentContainer from "./DepertmentContainer";
import history from "../history";
import { GetData } from "../api/service";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const list = [
  {
    title: "NSS",
    path: "/facility/nss",
    image:
      "/images/new/nss-pc.jpg",
  },
  {
    title: "NCC Army Wing",
    path: "/facility/ncc_army",
    image:
      "/images/new/ncc-pc.png",
  },
  {
    title: "NCC Navy Wing",
    path: "/facility/ncc_navy",
    image:
      "/images/new/ncc-pc.png",
  },
  {
    title: "NCC Air Force Wing",
    path: "/facility/ncc_air",
    image:
      "/images/new/ncc-pc.png",
  },
  {
    title: `NCC GIrls' Wing`,
    path: "/facility/ncc_girl",
    image:
      "/images/new/ncc-pc.png",
  },
  {
    title: `Scoute & Guides`,
    path: "/facility/scoute_guides",
    image:
      "/images/new/sg-pc.png",

  },
  {
    title: `Indian Red Cross`,
    path: "/facility/red_cross",
    image:
      "/images/new/irc-pc.png",
  },
];

class StudentWingsContainer extends Component {
  state = {
    isLoaded: false,
  };

  render() {
    return (
      <div>
        <BreadCrumbOwn title="Student Wings" />
        <Container style={{ marginBottom: 30 }}>
          <Grid container spacing={2}>
            {list.map((el, index) => (
              <Grid
                item
                key={index}
                xs={12}
                sm={12}
                md={3}
                lg={3}
                style={{ paddingTop: "1em" }}
              >
                <Card style={{ maxWidth: 345 }}>
                 
                  <img src={el.image} style={{width: "100%", height: 200, objectFit: "contain"}} />

                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{ fontWeight: 700 }}
                    >
                      {el.title}
                    </Typography>
                  </CardContent>

                  <CardActions>
                    <Link to={el.path}>
                      <Button size="small" color="primary">
                        Visit
                      </Button>
                    </Link>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    );
  }
}

export default StudentWingsContainer;
