import React, { Component } from "react";
import { GetData } from "../../api/service";
import { IconButton, Typography } from "@material-ui/core";
import { Drawer, Menu, message } from "antd";
import {
  DownOutlined,
  MenuOutlined,
  DownCircleOutlined,
  CaretDownOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
const { SubMenu } = Menu;

const data = [
  {
    title: "Home",
    path: "/",
    hasSubnav: false,
    subnav: [],
  },
  // {
  //   title: "About",
  //   path: "/aboutus/about",
  //   hasSubnav: true,
  //   subnav: [
  //     {
  //       title: "About",
  //       path: "/aboutus/about",
  //       hasSubnav: false,
  //       subnav: [],
  //     },
  //     // {
  //     //   title: 'History',
  //     //   path: '/aboutus/history',
  //     //   hasSubnav: false,
  //     //   subnav: []
  //     // },
  //     {
  //       title: "Vision & Mission",
  //       path: "/aboutus/vision",
  //       hasSubnav: false,
  //       subnav: [],
  //     },
  //     {
  //       title: "Emblem",
  //       path: "/aboutus/emblem",
  //       hasSubnav: false,
  //       subnav: [],
  //     },
  //     // {
  //     //   title: 'Aims & Objectives',
  //     //   path: '/aboutus/icv',
  //     //   hasSubnav: false,
  //     //   subnav: []
  //     // },
  //   ],
  // },

  // {
  //   title: 'EVENTS',
  //   path: '/events',
  //   hasSubnav: false,
  //   subnav: []
  // },
  {
    title: "Administration",
    path: "/administrations/principal",
    hasSubnav: true,
    subnav: [
      {
        title: `Principal's Message`,
        path: "/administrations/principal",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Governing Body",
        path: "/administrations/gb",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Former Principals",
        path: "/administrations/formerprincipals",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Office Staff",
        path: "/administrations/officestaff",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Committees",
        path: "/committees",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Organogram",
        path: "/administrations/organogram",
        hasSubnav: false,
        subnav: [],
      },
      // {
      //   title: "Recruitment",
      //   path: "/recruitment",
      //   hasSubnav: false,
      //   subnav: [],
      // },
    ],
  },
  {
    title: "Academics",
    path: "##",
    hasSubnav: true,
    subnav: [
      {
        title: "Departments & Centres",
        path: "/departments",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Admission Procedure",
        path: "/admissionprocedure",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Examination",
        path: "/examination",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Rules & Regulations",
        path: "/rulesandregulations",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Downloads",
        path: "/students/downloads-general",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Teachers Feedback",
        path: "/iqac/feedback",
        hasSubnav: false,
        subnav: [],
      },
    ],
  },
  // {
  //   title: 'Notices',
  //   path: '/notifications/administrative',
  //   hasSubnav: true,
  //   subnav: [
  //     {
  //       title: 'Administrative Notices',
  //       path: '/notifications/administrative',
  //       hasSubnav: false,
  //       subnav: []
  //     },
  //     {
  //       title: 'Academic Notices',
  //       path: '/notifications/academic',
  //       hasSubnav: false,
  //       subnav: []
  //     },
  //     {
  //       title: 'Recent Achievements',
  //       path: '/notifications/achievements',
  //       hasSubnav: false,
  //       subnav: []
  //     },
  //     {
  //       title: 'Student Achievements',
  //       path: '/notifications/sachievements',
  //       hasSubnav: false,
  //       subnav: []
  //     },
  //     {
  //       title: 'Employee Achievements',
  //       path: '/notifications/eachievements',
  //       hasSubnav: false,
  //       subnav: []
  //     },
  //     {
  //       title: 'Downloads',
  //       path: '/notifications/downloads',
  //       hasSubnav: false,
  //       subnav: []
  //     },
  //     {
  //       title: 'Tender Notices',
  //       path: '/notifications/tender',
  //       hasSubnav: false,
  //       subnav: []
  //     },
  //     {
  //       title: 'Events',
  //       path: '/events',
  //       hasSubnav: false,
  //       subnav: []
  //     },
  //   ]
  // },

  {
    title: `Students' Corner`,
    path: "/students/notification",
    hasSubnav: true,
    subnav: [
      {
        title: "Apply for Certificates",
        path: "/students/certificates",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "PCSU",
        path: "/facility/students_union",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Routines",
        path: "/students/routines",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Results",
        path: "/students/results",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Exam Schedules",
        path: "/students/exam-schedule",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Student Wings/Clubs",
        path: "/students/clubs",
        hasSubnav: true,
        subnav: [
          {
            title: "NCC",
            path: "/ncc/home",
            hasSubnav: false,
            subnav: [],
          },
          {
            title: "NSS",
            path: "/nss/home",
            hasSubnav: false,
            subnav: [],
          },
          // {
          //   title: "NCC Army Wing",
          //   path: "/facility/ncc_army",
          //   hasSubnav: false,
          //   subnav: [],
          // },
          // {
          //   title: "NCC Navy Wing",
          //   path: "/facility/ncc_navy",
          //   hasSubnav: false,
          //   subnav: [],
          // },
          // {
          //   title: "NCC Air Force Wing",
          //   path: "/facility/ncc_air",
          //   hasSubnav: false,
          //   subnav: [],
          // },
          // {
          //   title: `NCC GIrls' Wing`,
          //   path: "/facility/ncc_girl",
          //   hasSubnav: false,
          //   subnav: [],
          // },
          {
            title: `Scoute & Guides`,
            path: "/facility/scoute_guides",
            hasSubnav: false,
            subnav: [],
          },
          {
            title: `Indian Red Cross`,
            path: "/facility/red_cross",
            hasSubnav: false,
            subnav: [],
          },
        ],
      },
      // {
      //   title: "Grievances",
      //   path: "/students/grievances",
      //   hasSubnav: false,
      //   subnav: [],
      // },
      {
        title: "Student Downloads",
        path: "/students/downloads",
        hasSubnav: false,
        subnav: [],
      },
     
      {
        title: "Notifications",
        path: "/students/notification",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Antiragging",
        path: "/facility/antiragging",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Syllabi & Courses",
        path: "/syllabus-course",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Scholarships & Student Welfare",
        path: "/facility/scholarship_and_stipen",
        hasSubnav: false,
        subnav: [],
      },
      
      {
        title: "Students Feedback",
        isLink: true,
        link: "https://docs.google.com/forms/d/e/1FAIpQLScMH3R_IPFboH66P2OMyT7p5Rq8iMElir9U1vkYOqM_elsQOw/viewform",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Student Message",
        path: "/student-message",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Open Educational Resources (OER)",
        isLink: true,
        link: "http://103.68.53.214:8080/jspui/library/resource.html",
        hasSubnav: false,
        subnav: [],
      },
    ],
  },
  {
    title: "Alumni",
    path: "/alumni/committee",
    hasSubnav: true,
    subnav: [
      {
        title: "Alumni Association",
        path: "/alumni/committee",
        hasSubnav: false,
        subnav: [],
      },
      
      {
        title: "Alumni Support",
        path: "/alumni/support",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Alumni Events",
        path: "/alumni/events",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Alumni Achievements",
        path: "/alumni/achievements",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Prominent Alumni",
        path: "/alumni/prominent",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Alumni Members",
        path: "/alumni/list",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Alumni Registration",
        path: "/alumni-registration",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Alumni Message",
        path: "/alumni-message",
        hasSubnav: false,
        subnav: [],
      },
      
    ],
  },
  {
    title: "Library",
    path: "/facility/library",
    hasSubnav: false,
    subnav: [],
  },
  {
    title: "Resource/Facilities",
    path: "/facility/library",
    hasSubnav: true,
    subnav: [
      {
        title: "Academic & Research Facilities",
        path: "###",
        hasSubnav: true,
        subnav: [
          {
            title: "ICT Enabled Digital Classrooms",
            path: "/facility/ict_enabled_digital_classrooms",
            hasSubnav: false,
            subnav: [],
          },
          {
            title: "Central Instrumentation Facility",
            path: "/facility/central_instrumentation_facility",
            hasSubnav: false,
            subnav: [],
          },
          
          {
            title: "SWAYAM-NPTEL Local Chapter",
            path: "/nptel",
            hasSubnav: false,
            subnav: [],
          },
          { 
            title: "GUCDOE",
            path: "/gucdoe",
            hasSubnav: false,
            subnav: [],
          },
          {
            title: "KKHSOU",
            path: "/kkhsou",
            hasSubnav: false,
            subnav: [],
          },
          {
            title: "IGNOU",
            path: "/ignou",
            hasSubnav: false,
            subnav: [],
          },
          {
            title: "Research Laboratory",
            path: "/facility/research_laboratory",
            hasSubnav: false,
            subnav: [],
          },
          
        ],
      },
      // {
      //   title: 'Students Welfare Fund',
      //   path: '/facility/studentsupport',
      //   hasSubnav: false,
      //   subnav: []
      // },
      // {
      //   title: 'Scholarship & Stipen Facilities',
      //   path: '/facility/cif',
      //   hasSubnav: false,
      //   subnav: []
      // },
      {
        title: "Hostel Facility",
        path: "/facility/girlshostel",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Healthcare Facilities",
        path: "/facility/healthcare_facility",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Sports Facilities",
        path: "/facility/sports_facility",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Scholarships & Student Welfare Facilities",
        path: "/facility/scholarship_and_stipen",
        hasSubnav: false,
        subnav: [],
      },

      {
        title: "Library & Digital Resources",
        path: "/facility/library",
        hasSubnav: false,
        subnav: [],
      },
      // { // content needed
      //   title: "Academic Resources",
      //   path: "####",
      //   hasSubnav: false,
      //   subnav: [],
      // },
      // {
      //   title: "Syllabi & Courses",
      //   path: "/syllabus-course",
      //   hasSubnav: false,
      //   subnav: [],
      // },
      {
        title: "Disability Support",
        path: "/facility/disability_support",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Heritage Corner",
        path: "/facility/heritage_corner",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Canteen Facility",
        path: "/facility/canteen_facility",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Parking Facility",
        path: "/facility/parking_facility",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Other Facilties",
        path: "/facility/other_facility",
        hasSubnav: false,
        subnav: [],
      },
      // {
      //   title: "Information Career Guidance",
      //   path: "/facility/Career_Placement_Cell",
      //   hasSubnav: false,
      //   subnav: [],
      // },
      // {
      //   title: "Scholarship & Stipen Facilties",
      //   path: "/facility/scholarship_and_stipen",
      //   hasSubnav: false,
      //   subnav: [],
      // },
      // {
      //   title: "Student Welfare Facilties",
      //   path: "/facility/student_welfare",
      //   hasSubnav: false,
      //   subnav: [],
      // },
      // {
      //   title: `Student Grievance Cell`,
      //   path: "/facility/student_grievance_cell",
      //   hasSubnav: false,
      //   subnav: [],
      // },
      // {
      //   title: `Antiragging`,
      //   path: "/facility/antiragging",
      //   hasSubnav: false,
      //   subnav: [],
      // },

      // {
      //   title: 'CGPC',
      //   path: '/facility/cgpc',
      //   hasSubnav: false,
      //   subnav: []
      // },
    ],
  },
  {
    title: "IQAC",
    path: "/iqac/about",
    hasSubnav: false,
    subnav: [
      {
        title: "About",
        path: "/iqac/about",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Action Plan",
        path: "/iqac/actionplan",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Annual Report",
        path: "/iqac/annual-report",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Units & wings",
        path: "/iqac/units-and-wings",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Minutes & Resolutions",
        path: "/iqac/minutes",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Best Practice",
        path: "/iqac/best-practice",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Academic Calendar",
        path: "/iqac/academic-calendar",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "IQAC Core Committees",
        path: "/iqac/core-committes",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Feedback Forms",
        path: "/iqac/feedback",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Feedback Analysis",
        path: "/iqac/feedback-analysis",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Student Satisfactory Survey",
        path: "/iqac/sss",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "IQAC Event",
        path: "/iqac/event-news",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "AISHE Data",
        path: "/iqac/aishe-data",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "MoUs & Collaborations",
        path: "/iqac/mou-collaborations",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "AQAR",
        path: "/iqac/aqar",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Handbook",
        path: "/iqac/handbook",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "DAC",
        path: "/iqac/dac",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "IIQA",
        path: "/iqac/iiqa",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "SDF",
        path: "/iqac/sdf",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "SSR",
        path: "/iqac/ssr",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "DVV",
        path: "/iqac/dvv",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Codes, Guidelines & Circulars",
        path: "/iqac/cgc",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Audit Report",
        path: "/iqac/auditreport",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Organogram",
        path: "/iqac/organogram",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Institutional Distingtiveness",
        path: "/iqac/institutional-distingtiveness",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "AQR NAAC Matrices",
        path: "/iqac/matrix",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "COs & POs",
        path: "/iqac/co_po",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "NIRF",
        path: "/nirf",
        hasSubnav: false,
        subnav: [],
      },
    ],
  },
  {
    title: "Research",
    path: "/research",
    hasSubnav: true,
    subnav: [
      {
        title: "Research Publications",
        path: "/research",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Research Projects",
        path: "/research-projects",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Research Notifications",
        path: "/research-notifications",
        hasSubnav: false,
        subnav: [],
      },
    ],
  },
  {
    title: "Publications",
    path: "/publications",
    hasSubnav: true,
    subnav: [
      {
        title: "In-house Publications",
        path: "/publications",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Wall-magazine",
        path: "/wallmagazines",
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "Newsletters",
        path: "/newsletter",
        hasSubnav: false,
        subnav: [],
      },
    ],
  },
  {
    title: "NCC & NSS",
    path: "/ncc",
    hasSubnav: true,
    subnav: [
      
      {
        title: "National Cadet Corps",
        path: "/ncc/home",
        hasSubnav: false,
        subnav: [
          {
            title: "NCC Home",
            path: "/ncc/home",
            hasSubnav: false,
            subnav: [],
          },
          {
            title: "NCC Army Wing",
            path: "/ncc/ncc_army",
            hasSubnav: false,
            subnav: [],
          },
          {
            title: "NCC Navy Wing",
            path: "/ncc/ncc_navy",
            hasSubnav: false,
            subnav: [],
          },
          {
            title: "NCC Air Force Wing",
            path: "/ncc/ncc_air",
            hasSubnav: false,
            subnav: [],
          },
          {
            title: `NCC Girls' Wing`,
            path: "/ncc/ncc_girl",
            hasSubnav: false,
            subnav: [],
          },
        ],
      },
      
      {
        title: "National Service Scheme",
        path: "/nss/home",
        hasSubnav: false,
        subnav: [],
      },
    ],
  },
  {
    title: "NIRF",
    path: "/nirf",
    hasSubnav: false,
    subnav: [],
  },

  
  // {
  //   title: "Visitors` Note",
  //   path: "/visitors-note",
  //   hasSubnav: false,
  //   subnav: [],
  // },

  // {
  //   title: "RTI",
  //   path: "/rti/home",
  //   hasSubnav: true,
  //   subnav: [
  //     {
  //       title: "RTI",
  //       path: "/rti/home",
  //       hasSubnav: false,
  //       subnav: [],
  //     },
  //     {
  //       title: "Officers",
  //       path: "/rti/officers",
  //       hasSubnav: false,
  //       subnav: [],
  //     },
  //   ],
  // },
  {
    title: "Grievance Redressal",
    path: "###",
    isLink: true,
    link: "http://grievance.pragjyotishcollege.ac.in/",
    hasSubnav: false,
    subnav: [],
  },

  {
    title: "Contact us",
    path: "/contactus",
    hasSubnav: false,
    subnav: [],
  },
  {
    title: "Login",
    path: "###",
    hasSubnav: true,
    subnav: [
      {
        title: "Student Login",
        path: "/rti/home",
        hasSubnav: false,
        isLink: true,
        link: "http://student.pragjyotishcollege.ac.in/",
        subnav: [],
      },
      {
        title: "Teacher Login",
        path: "/rti/home",
        hasSubnav: false,
        isLink: true,
        link: "http://user.pragjyotishcollege.ac.in/",
        subnav: [],
      },
      {
        title: "Department Login",
        path: "/rti/home",
        hasSubnav: false,
        isLink: true,
        link: "http://webadmin.pragjyotishcollege.ac.in/",
        subnav: [],
      },
      {
        title: "Committee Login",
        path: "/rti/home",
        hasSubnav: false,
        isLink: true,
        link: "http://webadmin.pragjyotishcollege.ac.in/",
        subnav: [],
      },
    ],
  },
];

const pos = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  zIndex: 1000,
  justifyContent: "end",
};

class MultiNavBar extends Component {
  state = {
    departments: [],
    isLoaded: false,
    data: data,
    current: "/",
    openMenu: false,
  };

  componentDidMount() {
    // GetData(`/getdepartments`)
    //   .then((resp) => {
    //     //console.log(resp)
    //     // arrange them
    //     let arts = resp.filter(el => el.stream == 'ARTS');
    //     let science = resp.filter(el => el.stream == 'SCIENCE');
    //     let arts_arr = [];
    //     arts.map((el, index) =>
    //       arts_arr.push({
    //         title: el.dept_name,
    //         path: `/department/${el.dept_code}`,
    //         hasSubnav: false,
    //         subnav: []
    //       })
    //     )
    //     let science_arr = [];
    //     science.map((el, index) =>
    //       science_arr.push({
    //         title: el.dept_name,
    //         path: `/department/${el.dept_code}`,
    //         hasSubnav: false,
    //         subnav: []
    //       })
    //     )
    //     let arts_d = {
    //       title: 'Arts Departments',
    //       path: '',
    //       hasSubnav: true,
    //       subnav: arts_arr
    //     }
    //     let science_d = {
    //       title: 'Science Departments',
    //       path: '',
    //       hasSubnav: true,
    //       subnav: science_arr
    //     }
    //     let d = [arts_d, science_d];
    //     let data = this.state.data;
    //     data[4].subnav = d;
    //     // for people
    //     let teaching_people = resp.map((el, index) => {
    //       return ({
    //         title: el.dept_name,
    //         path: `/department/${el.dept_code}/faculty`,
    //         hasSubnav: false,
    //         subnav: []
    //       })
    //     }
    //     );
    //     //data[5]['subnav'][1]['subnav'] = teaching_people
    //     //console.log(data)
    //     this.setState({
    //       data: data,
    //       isLoaded: true,
    //       current: this.props.history.location.pathname
    //     }, () => {
    //       // console.log(this.state)
    //     })
    //   })
  }

  closeMenu = () => {
    this.setState({
      openMenu: false,
    });
  };

  handleClick = (e) => {
    if (e.key != 0) {
      this.props.history.push(e.key);
      this.setState(
        {
          current: e.key,
        },
        () => {
          this.closeMenu();
        }
      );
    }else{
      message.success('Link opend in a new tab')
    }
  };

  render() {
    return (
      <div>
        <div className="desk-menu">
          <div
            style={
              this.props.sticky
                ? {
                    ...pos,
                  }
                : null
            }
            className={`navStyle-new`}
          >
            <Menu
              onClick={this.handleClick}
              overflowedIndicator={<MenuOutlined />}
              style={{
                background: "#4F58D2",
                border: "none",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                //  color: "#9DA0B3"
              }}
              selectedKeys={[this.state.current]}
              //triggerSubMenuAction="click"
              // overflowedIndicator={<MenuUnfoldOutlined />}

              mode="horizontal"
            >
              {this.state.data.map((el, index) => {
                if (el.hasSubnav) {
                  return (
                    <SubMenu title={<div style={{display: "flex", alignItems: "center"}}>{el.title} &nbsp;<CaretDownOutlined style={{fontSize: 12}} /></div>} className="navItemPandu" >
                      
                      {el.subnav.map((el1, index1) => {
                        if (el1.hasSubnav) {
                          return (
                            <SubMenu
                              title={el1.title}
                              className="navItemPandu1"
                            >
                              {el1.subnav.map((el2, index2) => (
                                <Menu.Item
                                  className="navItemPandu1"
                                  key={el2.path}
                                >
                                  {el2.title}
                                </Menu.Item>
                              ))}
                            </SubMenu>
                          );

                          
                        } else {
                          if (el1.isLink && el1.isLink == true) {
                            return (
                              <Menu.Item className="navItemPandu1" key={0}>
                                <a
                                  className="navItemPandu1"
                                  target="_blank"
                                  href={el1.link}
                                >
                                  {el1.title}{" "}
                                </a>
                              </Menu.Item>
                            );
                          } else {
                            return (
                              <Menu.Item
                                className="navItemPandu1"
                                key={el1.path}
                              >
                                {el1.title}
                              </Menu.Item>
                            );
                          }
                        }
                      })}
                    </SubMenu>
                  );
                } else {
                  if (el.isLink && el.isLink == true) {
                    return (
                      <Menu.Item key={0} className="navItemPandu">
                        <a
                          style={{ color: "white" }}
                          target="_blank"
                          href={el.link}
                        >
                          {el.title}{" "}
                        </a>
                      </Menu.Item>
                    );
                  } else {
                    return (
                      <Menu.Item key={el.path} className="navItemPandu">
                        {el.title}
                      </Menu.Item>
                    );
                  }
                }
              })}
            </Menu>
          </div>
        </div>

        <div className="mobile-menu-div">
          <IconButton onClick={() => this.setState({ openMenu: true })}>
            <MenuOutlined style={{ fontWeight: 700 }} />
          </IconButton>
        </div>

        <Drawer
          title=""
          placement="right"
          onClose={() => this.closeMenu()}
          open={this.state.openMenu}
        >
          <Menu
            onClick={this.handleClick}
            selectedKeys={[this.state.current]}
            overflowedIndicator={<MenuUnfoldOutlined />}
            mode="inline"
          >
            {this.state.data.map((el, index) => {
              if (el.hasSubnav) {
                return (
                  <SubMenu title={el.title}>
                    
                    {el.subnav.map((el1, index1) => {
                      if (el1.hasSubnav) {
                        return (
                          <SubMenu title={el1.title} >
                            
                            {el1.subnav.map((el2, index2) => (
                              <Menu.Item key={el2.path}>{el2.title}</Menu.Item>
                            ))}
                          </SubMenu>
                        );
                      } else {
                        return (
                          <Menu.Item key={el1.path}>{el1.title}</Menu.Item>
                        );
                      }
                    })}
                  </SubMenu>
                );
              } else {
                return <Menu.Item key={el.path}>{el.title}</Menu.Item>;
              }
            })}
          </Menu>
        </Drawer>
      </div>
    );
  }
}

export default MultiNavBar;

// class OneNavBar extends Component {
//   state = {
//     hover: false
//   }

//   onMouseEnter = () => {
//     this.setState({
//       hover: true
//     })
//   }

//   onMouseLeave = () => {
//     this.setState({
//       hover: false
//     })
//   }

//   render() {

//     return (
//       <div
//         style={{
//           padding: '10px 10px 10px 10px',
//           cursor: 'pointer',
//           position: 'relative',
//           color: this.props.color

//         }}

//         onMouseEnter={this.onMouseEnter}
//         onMouseLeave={this.onMouseLeave}
//         onMouseOver={this.onMouseEnter}
//         onClick={() => !this.props.hasSubnav ? this.props.history.push(this.props.path) : null}
//       >

//         <Typography
//           variant="subtitle2"
//         >
//           {this.props.title}
//         </Typography>

//         {this.props.hasSubnav && this.state.hover ? (<div
//           style={{
//             backgroundColor: this.props.backgroundColor,
//             position: 'absolute',
//             minWidth: this.props.width,
//             zIndex: 999,
//             display: 'grid',
//             gridTemplateRows: '1fr',
//             boxShadow: '1px 1px 3px rgba(0,0,0,0.3)'
//           }}
//         >

//           {this.props.subnav.map((el, index) =>
//             <OneSideNav
//               key={index}
//               {...el}
//               width={this.props.width}
//               color={this.props.color}
//               backgroundOfNav={this.props.backgroundOfNav}
//               backgroundColor={this.props.backgroundColor}
//               textColor={this.props.textColor}
//               history={this.props.history}
//             />
//           )}

//         </div>) : null}
//       </div>
//     )
//   }
// }

// class OneSideNav extends Component {
//   state = {
//     hover: false
//   }
//   onMouseEnter = () => {
//     this.setState({
//       hover: true
//     })
//   }

//   onMouseLeave = () => {
//     this.setState({
//       hover: false
//     })
//   }
//   render() {
//     return (
//       <div
//         style={{
//           color: this.props.textColor,
//           backgroundColor: this.props.backgroundColor,
//           position: 'relative',
//           minWidth: this.props.width,
//           padding: '10px',
//           borderBottom: 'solid thin lightgrey',
//           borderRight: 'solid thin lightgrey',
//           boxShadow: '1px 2px 3px rgba(0,0,0,0.3)',

//         }}
//         onClick={() => !this.props.hasSubnav ? this.props.history.push(this.props.path) : null}

//         onMouseEnter={this.onMouseEnter}
//         onMouseLeave={this.onMouseLeave}
//         onMouseOver={this.onMouseEnter}
//       >
//         <font style={{ fontSize: '0.8em' }}>{this.props.title}</font>  {this.props.hasSubnav ? (<span
//           className="fa fa-angle-right"
//           style={{
//             fontSize: '0.6em',
//             float: 'right',
//             marginTop: '8px'

//           }}></span>) : null}

//         {this.props.hasSubnav && this.state.hover ? (
//           <div
//             style={{
//               position: "absolute",
//               zIndex: 999,
//               left: 0,
//               marginLeft: this.props.width,
//               top: 0,
//               backgroundColor: this.props.backgroundColor
//             }}
//           >
//             {this.props.subnav.map((el, index) =>
//               <OneSideNav
//                 key={index}
//                 {...el}
//                 width={this.props.width}
//                 color={this.props.color}
//                 backgroundOfNav={this.props.backgroundOfNav}
//                 backgroundColor={this.props.backgroundColor}
//                 history={this.props.history}
//               />
//             )}
//           </div>
//         ) : null}

//       </div>
//     )
//   }
// }
