import React, { Component } from "react";
import BreadCumb from "./../component/Department/BreadCumb";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Grid, Container, Typography } from "@material-ui/core";
import { Library } from "../component/Ncc/NccFacilityDescription";
import { PostData } from "../api/service";
import { Tabs } from "antd";
import NotificationDescription from "../component/Notifications/NotificationDescription";

const { TabPane } = Tabs;
export default class KkhsouContainer extends Component {
  render() {
    return (
      <div>
        <BreadCrumbOwn title="KKHSOU" />
        <Container style={{ minHeight: "600px" }} className="mb-5">
          <br></br>
          <div>
            <LibraryOwnWithoutAnything title="" type="COLLEGE_KKHSOU" />

            <div style={{ backgroundColor: "#eaecee", padding: 10 }}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="News & Notifications" key="1">
                  <NotificationDescription
                    code="COLLEGE_KKHSOU_NEWS"
                    title="News & Notifications"
                  />
                </TabPane>
                <TabPane tab="Documents" key="2">
                  <NotificationDescription
                    code="COLLEGE_KKHSOU_DOCUMENTS"
                    title="Documents"
                  />
                </TabPane>
              </Tabs>
            </div>

            {/* <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                style={{ backgroundColor: "#F2F4F4" }}
              >
                <Typography
                  variant="h5"
                  style={{ margin: "1em 0", fontWeight: 700 }}
                  gutterBottom
                >
                  Programms Under Krishna Kanta Handiqui State Open University
                  (KKHSOU)
                </Typography>

                <Typography
                  variant="h6"
                  style={{ margin: "1em 0", fontWeight: 700 }}
                  gutterBottom
                >
                  Post Graduate Programs
                </Typography>
                <Typography variant="body1">
                  <ul>
                    <li>M.A. (Assamese)</li>
                    <li>M.A. (English)</li>
                    <li>M.A. (Sociology)</li>
                  </ul>
                </Typography>

                <Typography
                  variant="h6"
                  style={{ margin: "1em 0", fontWeight: 700 }}
                  gutterBottom
                >
                  Under Graduate Programs
                </Typography>
                <Typography variant="body1">
                  <ul>
                    <li>B.A.</li>
                    <li>M.Com.</li>
                    <li>B.B.A</li>
                    <li>B.C.A</li>
                  </ul>
                </Typography>

                <br />

                <Typography
                  variant="h6"
                  style={{ margin: "1em 0", fontWeight: 700 }}
                  gutterBottom
                >
                  Documents
                </Typography>

                <ul>
                  <li>
                    <a
                      target="_blank"
                      href={`/pdf/kkhsou/1502 PROGRAMME LIST.pdf`}
                    >
                      1502 PROGRAMME LIST
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={`/pdf/kkhsou/1502 COURSE ALLOTMENT.pdf`}
                    >
                      1502 COURSE ALLOTMENT
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={`/pdf/kkhsou/1502 COUNSELLOR LIST (UG, DIP).pdf`}
                    >
                      1502 COUNSELLOR LIST (UG, DIP)
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={`/pdf/kkhsou/1502 COUNSELLOR LIST (PG).pdf`}
                    >
                      1502 COUNSELLOR LIST (PG)
                    </a>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography
                  variant="h5"
                  style={{ margin: "1em 0", fontWeight: 700 }}
                  gutterBottom
                >
                  COORDINATOR
                </Typography>
                
                <Typography variant="body1">
                  <strong>Dr. Manjit Kr. Mazumdar</strong>
                  <br />
                  Associate Professor
                  <br />
                  Geology Department
                  <br />
                  Phone: <a href={`tel:9706433118`}>9706433118</a> <br />
                </Typography>
              </Grid>
            </Grid> */}
          </div>
        </Container>
      </div>
    );
  }
}

export const LibraryOwnWithoutAnything = (props) => {
  const [data, setData] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    __loadFile();
  }, []);

  function __loadFile() {
    let d = {
      dept_code: "PRAG",
      type: props.type,
      year: "",
    };

    PostData(`/getnoticesbytypedept`, d).then((resp) => {
      setData(resp);
      setIsLoaded(true);
    });
  }

  return (
    <>
      <div className="text-justify">
        {isLoaded && (
          <div>
            {Array.isArray(data) &&
              data.map((el, index) => (
                <div key={index}>
                  <Typography
                    variant="h5"
                    style={{ margin: "1em 0", fontWeight: 700 }}
                    gutterBottom
                  >
                    {el.title}
                  </Typography>

                  <Typography variant="body1" gutterBottom>
                    <p
                      variant="body1"
                      dangerouslySetInnerHTML={{ __html: el.description }}
                    />
                  </Typography>

                  <br />

                  <Grid container spacing={2}>
                    {Array.isArray(el.upload_info) &&
                      el.upload_info.map((el, index) => (
                        <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
                          {el.fileType != "PDF" && (
                            <img src={el.url} style={{ width: "100%" }} />
                          )}

                          {el.fileType == "PDF" && (
                            <a target="_blank" href={el.url}>
                              {el.path}
                            </a>
                          )}
                        </Grid>
                      ))}
                  </Grid>
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  );
};
