import React, { Component } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Tabs } from "antd";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";
import { Container } from '@material-ui/core'

class AboutDescription extends Component {
  render() {
    return (
      <div style={{ marginTop: "" }}>
        {this.props.type == "about" ? (
          <About title="About Pragjyotish College" />
        ) : null}
        {this.props.type == "vision" ? <Vission title="Vision" /> : null}
        {this.props.type == "emblem" ? <Emblem title="THE EMBLEM" /> : null}
        {/* {this.props.type == "history" ? <History title="History" /> : null}
        
        {this.props.type == "mission" ? <History title="Mission" /> : null}
        {this.props.type == "icv" ? <ICV title="Aims & Objectives" /> : null} */}
        {/* {this.props.type == 'mission' ? (<Seat title="Mission" />) : null}} */}
      </div>
    );
  }
}

export default AboutDescription;

export const About = (props) => {
  return (
    <div>
      <BreadCrumbOwn title="About Pragjyotish College" />
      <Container className="mb-5">
        <Typography variant="h4" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
          {props.title}
        </Typography>

        {/* <Typography
                variant="h6"
                gutterBottom
            >
                History
            </Typography> */}

        <Typography variant="body1" gutterBottom>
        Pragjyotish College, founded on 1st September 1954, is the first college established in Guwahati, the largest metropolis in Northeast India, after Independence. Guided by the motto “तेजस्विनावधीतमस्” (May our study make us illumined) over several decades, the institute, at present, is a well-known premier postgraduate college imparting higher education in as many as 27 departments which includes 7 postgraduate programmes and has around 4000 regular students making it one of the largest educational institutes of the region in terms of number of admissions. The college believes in all-round development of its students. Apart from excellent academic pursuits, great emphasis is also laid on a range of cocurricular activities, discipline and character building of the students. Pragjyotish College has a unique distinction - it has a full-fledged NCC unit with three wings – Army (with both boys and girls units), Navy and Air. In addition, the NSS, Red Ribbon Club, Bharat Scouts and Guides and the Youth Red Cross of the college provides our students a platform and ample opportunities for social involvement. Over the years, with the active involvement of IQAC, the college has also signed a number of MoUs with national and international institutes resulting in different types of collaborative activities for the ultimate benefit of the students. Students from all the Northeastern states flock to this institute. In its glorious existence of seven decades, the college, as alma mater, has produced a galaxy of eminent personalities from all walks of life. The college celebrated its Diamond Jubilee in 2014. The institute is recognised under Sections 2(f) and 12(B) of UGC and has been accredited by NAAC (2004-09, 2011-16, 2021-26).
        </Typography>
        <br />
        {/* <Typography variant="body1" gutterBottom>
          Situated on the western bank of the Bharalu, a rivulet, in the western part of Guwahati, Pragjyotish College is about 1 kilometre away from its confluence with the mighty Brahmaputra. It is about 4 kilometres from the Guwahati Railway Station and at a distance of about 20 kilometres from the Lokapriya Gopinath Bordoloi International Airport. At the backdrop of the college is a beautiful panoramic view of Nilachal Hills, the famous abode of Mother Goddess Kamakhya.
        </Typography>
        <br />
        <Typography variant="body1" gutterBottom>
          In the emblem of the college, is ingrained the motto “तेजस्विनावधीतमस्तु” (May our study make us illumined)and a conch shell at the centre on an eight-petalled full-blown beautiful-lotus, which symbolizes pure knowledge and the relationship between the teacher and the learner, praying to the Almighty for energy, protection, maintenance that ultimately leads to peace and bliss.
        </Typography>
        <br />
        <Typography variant="body1" gutterBottom>
          In its glorious existence of six decades, Pragjyotish College, as alma mater, has produced a galaxy of eminent personalities in all walks of life. The college celebrated its Diamond Jubilee Year during 2013–14.
        </Typography> */}
      </Container>
    </div>
  );
};

export const History = (props) => {
  return (
    <div>
      <Typography variant="h4" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
        {props.title}
      </Typography>

      <div style={{ textAlign: 'center' }}>

        <img
          src={`/images/thongnokbe.jpeg`}
          style={{ width: '500px', height: '400px' }}
        />
      </div>
      <br />

      {/* <Typography
                variant="h6"
                gutterBottom
            >
                History
            </Typography> */}

      <Typography variant="body1" gutterBottom>
        THONG NOKBE lived during the medieval period fighting against Khasi feudal oppression and restored dignity and freedom to his fellow Karbis.
      </Typography>
      <Typography variant="body1" gutterBottom>
        His exploits are glorified in folklores and legends and is adored by all Karbis as the first of their national heroes.
      </Typography>
    </div>
  );
};

export const Vission = (props) => {
  return (
    <div>
      <BreadCrumbOwn title="Motto, Vision & Mission" />
      <Container className="mb-5">
        <Typography variant="h4" style={{ margin: "1em 0", fontWeight: 700 }}>
          Motto
        </Typography>


        <Typography variant="body1" gutterBottom>
          तेजस्विनावधीतमस्तु<br />
          Tejasvinavadhitamastu<br />
          (May our study make us illumined)
        </Typography>


        <Typography variant="h4" style={{ margin: "1em 0", fontWeight: 700 }}>
          Vision
        </Typography>


        <Typography variant="body1" gutterBottom>
          To fulfill the visionary aspirations of the regional youth segment through a process of vibrant and continuous innovations and initiatives in multiple spheres of academic as well as professional development, leading to the fullest realisation of the potential of the students.
        </Typography>



        <Typography variant="h4" style={{ margin: "1em 0", fontWeight: 700 }}>
          Mission
        </Typography>


        <Typography variant="body1" gutterBottom>
          <p>
            <ul>
              <li>To make teachers and the taught partners in the learning process.</li>
              <li>To promote a student friendly atmosphere for encouraging them to be self-reliant and self-employable.</li>
              <li>To promote extra-curricular activities simultaneously with curricular activities.</li>
              <li>To mould the students into socially conscious human beings.</li>

              <li>To encourage students to think globally and act locally as productive citizens, through the promotion of scientific temper and action.</li>
              <li>To foster and inculcate moral and spiritual accomplishments amongst the students.</li>
              <li>To develop a transparent and responsive administration.</li>
              <li>To undergo self-analysis and self-discovery leading to elimination of bottlenecks in the context of a holistic framework</li>
            </ul>
          </p>

        </Typography>


      </Container>
    </div>
  );
};

const { TabPane } = Tabs;



export const ICV = (props) => {
  return (
    <div>
      <Typography variant="h4" style={{ margin: "1em 0", fontWeight: 700 }}>
        {props.title}
      </Typography>

      <Typography variant="body1" gutterBottom>
        <p>
          <ol>
            <li>The Primary aim of the college is to prepare students academically for Higher Secondary and Degree Examination. </li>
            <li>To produce efficient human resource for serving for the betterment of the district in particular and to act as a conscious citizen in general. </li>

            <li>To spread higher education in the district.</li>


          </ol>
        </p>

      </Typography>


    </div>
  )
}



export const Emblem = (props) => {
  return (
    <div>
      <BreadCrumbOwn title="Emblem" />
      <Container className="mb-5">
        <Typography variant="h4" style={{ margin: "1em 0", fontWeight: 700 }}>
          {props.title}
        </Typography>

        <div>
          <img
            src="/images/prag-logo-small.png"
            style={{width: 200, marginBottom: 20}}
          />
        </div>

        <Typography variant="body1" gutterBottom>
          The conch on the eight-petaloid full-bloomed graceful lotus symbolises pure knowledge.तेजस्विनावधीतमस्तु (Tejasvinavadhitamastu), the inspiring Motto of our college, speaks: May our study make us illumined. The aims, objectives and ideals of education are epitomised in this great saying of Taittiriya Upanishad (Ch. II Brahmananda Valli, Section 1, Invocation).

        </Typography>

      </Container>


    </div>
  )
}