import React from 'react'
import { GetData } from '../../api/service'

export function Counter() {

    const [count, setCount] = React.useState('')

    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()
    React.useEffect(() => {

        let dd = localStorage.getItem('cr_date')
        
        if(dd == null){
            GetData(`/initialisation/setcount`)
            .then((resp) => {
                setCount(resp)
                localStorage.setItem('cr_date', JSON.stringify(date))
            })
        }else{
       
            if(dd === JSON.stringify(date)){
                
                GetData(`/initialisation/getcount`)
                .then((resp) => {
                    setCount(resp)
                })
            }else{
                GetData(`/initialisation/setcount`)
                .then((resp) => {
                    setCount(resp)
                    localStorage.setItem('cr_date', JSON.stringify(date))
                })
            }
            
        }

        
    }, [])

    return (
        <div style={{}}>
            TOTAL VISITS <div style={{ fontSize: 20, fontWeight: 700}}>{count}</div>
        </div>
    )
}

export const WebCounter = React.memo(Counter)