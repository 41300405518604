import React, { Component } from "react";
import BreadCumb from "./../component/Department/BreadCumb";
import BreadCrumbOwn from "../component/Essentials/BreadCrumbOwn";
import { Grid, Container, Typography } from "@material-ui/core";
import { LibraryOwnWithoutAnything } from "./KkhsouContainer";
import { Tabs } from "antd";
import NotificationDescription from "../component/Notifications/NotificationDescription";
const { TabPane } = Tabs;
export default class IgnouContainer extends Component {
  render() {
    return (
      <div>
        <BreadCrumbOwn title="IGNOU" />
        <Container style={{ minHeight: "600px" }} className="mb-5">
          <br></br>
          <div>
            <LibraryOwnWithoutAnything title="" type="COLLEGE_IGNOU" />

            <div style={{ backgroundColor: "#eaecee", padding: 10 }}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="News & Notifications" key="1">
                  <NotificationDescription
                    code="COLLEGE_IGNOU_NEWS"
                    title="News & Notifications"
                  />
                </TabPane>
                <TabPane tab="Documents" key="2">
                  <NotificationDescription
                    code="COLLEGE_IGNOU_DOCUMENTS"
                    title="Documents"
                  />
                </TabPane>
              </Tabs>
            </div>

            {/* <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={8} lg={8} style={{ backgroundColor: "#F2F4F4" }}>

                                <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                                    Programms Under Indira Gandhi National Open University(IGNOU)
                                </Typography>


                                
                                <Typography variant="body1">

                                    <ul>
                                        <li>B.Sc. General</li>
                                        <li>B.Sc. in Geography</li>
                                        <li>B.Sc. in Geology</li>
                                        <li>Tourism Management</li>
                                        <li>Disaster Management</li>
                                    </ul>

                                </Typography>

                               


                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>

                                <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
                                    COORDINATOR
                                </Typography>
                                
                                <Typography variant="body1">
                                    <strong>Dr. Amarjyoti Dutta</strong>
                                    <br />
                                    Associate Professor
                                    <br />
                                    Mathematics Department
                                    <br />

                                    Phone:{" "}
                                    <a href={`tel:9864305333`}>
                                    9864305333
                                    </a>{" "}
                                    <br />
                                    Email: <a href={`mailto:amar@pragjyotishcollege.ac.in`}>amar@pragjyotishcollege.ac.in</a>

                                </Typography>

                            </Grid>

                        </Grid> */}
          </div>
        </Container>
      </div>
    );
  }
}
