import { LinkOutlined } from '@ant-design/icons'
import { Grid } from '@material-ui/core'
import React from 'react'
import { PostData, SERVER_UPLOAD } from '../../api/service'
import { getQuarterList } from '../../Helper/helperFunctions'
import TitleStyle from '../TitleStyle'





export default function TableView({
  fData,
  data,
  title,
  active,
  wholeData,
  dept_code,
}) {
  let names = []

  const [dr, setDr] = React.useState([])

  if (Array.isArray(data) && data.length > 0) {
    let d = Object.keys(data[0])
    names = d
  }

  // we will fetch data for 7, 8, 10, 11, 15
  // link from the faculty profile to departmental profile

  const [research, setResearch] = React.useState([]) // 7 & 8
  const [reasearch_paper, setReasearchPaper] = React.useState([]) // 10
  const [text_book, setTextBook] = React.useState([]) // 11
  const [awards, setAwards] = React.useState([]) // 15
  const [workshop, setWorkshop] = React.useState([]) // 14

  React.useEffect(() => {
    if (Array.isArray(fData) && fData.length > 0) {
      // we will saggrigate the data
      let rr1 = []
      let rr2 = []
      let rr3 = []
      let rr4 = []
      let rr5 = []

      fData.map((el) => {
        let r = {
          title: 'Ongoing/Completed Research projects by faculties',
          name: el.name,
          designation: el.designation,
          data: el.research,
        }

        rr1.push(r) // reasearch

        let r2 = {
          title: 'Research Publications by Faculties',
          name: el.name,
          designation: el.designation,
          data: el.research_paper,
        }

        rr2.push(r2) // reasearch publications

        let r3 = {
          title: 'Chapter in Books by Faculties',
          name: el.name,
          designation: el.designation,
          data: el.text_book,
        }

        rr3.push(r3) // reasearch publications

        let r4 = {
          title: 'Awards/Recognitions received by faculties',
          name: el.name,
          designation: el.designation,
          data: el.awards,
        }

        rr4.push(r4) // reasearch publications


        let r5 = {
          title: 'Orientation programme/Refresher Course/Short term course by Faculties',
          name: el.name,
          designation: el.designation,
          data: el.workshop,
        }

        rr5.push(r5) // Faculty Improvement programme
      })

      setResearch(rr1)
      setReasearchPaper(rr2)
      setTextBook(rr3)
      setAwards(rr4)
      setWorkshop(rr5)
    }
  }, [fData])

  return (
    <div>
      {active == 0 && wholeData.estd != null && (
        <div>
          <TitleStyle title={title} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div>
                <div
                  style={{ textAlign: 'center', fontSize: 20, fontWeight: 700 }}
                >
                  {wholeData.estd}
                </div>
                <div
                  style={{ textAlign: 'center', fontSize: 12, fontWeight: 700 }}
                >
                  Year of Establishment
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div>
                <div
                  style={{ textAlign: 'center', fontSize: 20, fontWeight: 700 }}
                >
                  {wholeData.examination_system}
                </div>
                <div
                  style={{ textAlign: 'center', fontSize: 12, fontWeight: 700 }}
                >
                  Examination System
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}

      {Array.isArray(data) && data.length > 0 && (
        <>
          <TitleStyle title={title} />
          <table
            width="100%"
            className="table table-sm table-bordered table-new-color"
          >
            <thead>
              <tr>
                <td colSpan={names.length + 1} align="center">
                  {title}
                </td>
              </tr>
              <tr>
                <td>#</td>
                {names.map((el, index) => (
                  <td key={index}>{el}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(data) &&
                data.map((el, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    {names.map((el1, index1) => {
                      if(el1 != 'file'){
                        return <td key={index1}>{el[el1]}</td>
                      }else{
                        return <td>
                        {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}><a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a></div>)}</td>
                      }
                    }
                      
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )}

      {active == 8 && (
        <div>
          {Array.isArray(research) && research != null && research.length > 0 && (
            <div>
              <TitleStyle
                title={`Ongoing/Completed Research projects by faculties`}
              />
              {research != null &&
                research.map((el1, index1) => (
                  <div key={index1} style={{ marginBottom: 10 }}>
                    {el1.data != null && el1.data.filter(el => getQuarterList().includes(el.quarter)).length > 0 && (
                      <table
                        width="100%"
                        className="table table-sm table-bordered"
                      >
                        <thead>
                          <tr>
                            <td colSpan="9">
                              {el1.name}, {el1.designation}
                            </td>
                          </tr>
                          <tr>
                            <td>#</td>
                            <td>Title of the project</td>
                            <td>Funding Agency</td>
                            <td>Duration of the Project</td>
                            <td>Project Cost (in Lakh)</td>
                            <td>Co-investigator (if any)</td>
                            <td>Status</td>
                            <td>Date of Commencement</td>
                            <td>Date of Completion </td>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(el1.data.filter(el => getQuarterList().includes(el.quarter))) &&
                            el1.data.filter(el => getQuarterList().includes(el.quarter)).map((el, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{el.title}
                                <br />
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}><a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a></div>)}</td>
                                <td>{el.sponsored_by}</td>
                                <td>{el.duration}</td>
                                <td>{el.fund}</td>
                                <td>{el.co_researcher}</td>
                                <td>{el.status}</td>
                                <td>{el.commencement_date}</td>
                                <td>{el.submission_date} {el.quarter}</td>
                                {/* <td>{el.year}</td> */}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      )}

      {active == 10 && (
        <div>
          {Array.isArray(reasearch_paper) &&
            reasearch_paper != null &&
            reasearch_paper.length > 0 && (
              <div>
                <TitleStyle title={`Research Publications by Faculties`} />
                {reasearch_paper != null &&
                  reasearch_paper.map((el1, index1) => (
                    <div key={index1} style={{ marginBottom: 10 }}>
                      {el1.data != null && el1.data.filter(el => getQuarterList().includes(el.quarter)).length > 0 && (
                        <table
                          width="100%"
                          className="table table-sm table-bordered"
                        >
                          <thead>
                            <tr>
                              <td colSpan="9">
                                {el1.name}, {el1.designation}
                              </td>
                            </tr>
                            <tr>
                              <td>#</td>

                              <td>Title of the paper</td>

                              <td>Name of the Book/Journal</td>
                              <td>Published By</td>
                              <td>ISBN/ISSN</td>
                              <td>Volume</td>
                              <td>Page Nos</td>
                              <td>Date / Year</td>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(el1.data.filter(el => getQuarterList().includes(el.quarter))) &&
                              el1.data.filter(el => getQuarterList().includes(el.quarter)).map((el, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>

                                  <td>{el.title_paper}
                                  <br />
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}><a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a></div>)}</td>
                                  <td>{el.title_book}</td>
                                  <td>{el.published_by}</td>
                                  <td>{el.isbn_no}</td>
                                  <td>{el.volume}</td>
                                  <td>{el.page_nos}</td>
                                  <td> {el.date_year}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  ))}
              </div>
            )}
        </div>
      )}

      {active == 11 && (
        <div>
          {Array.isArray(text_book) &&
            text_book != null &&
            text_book.length > 0 && (
              <div>
                <TitleStyle title={`Chapter in Books by Faculties`} />
                {text_book != null &&
                  text_book.map((el1, index1) => (
                    <div key={index1} style={{ marginBottom: 10 }}>
                      {el1.data != null && el1.data.filter(el => getQuarterList().includes(el.quarter)).length > 0 && (
                        <table
                          width="100%"
                          className="table table-sm table-bordered"
                        >
                          <thead>
                            <tr>
                              <td colSpan="9">
                                {el1.name}, {el1.designation}
                              </td>
                            </tr>
                            <tr>
                              <td>#</td>

                              <td>Name of the Book/Text Book</td>
                              <td>Published By</td>
                              <td>ISBN</td>
                              <td>Volume</td>
                              {/* <td>Page Nos</td> */}
                              <td>Edition</td>
                              <td>Name of authors</td>
                              <td>Year</td>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(el1.data.filter(el => getQuarterList().includes(el.quarter))) &&
                              el1.data.filter(el => getQuarterList().includes(el.quarter)).map((el, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>

                                  <td>{el.title_book}
                                  <br />
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}><a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a></div>)}</td>
                                  <td>{el.published_by}</td>
                                  <td>{el.isbn_no}</td>
                                  <td>{el.volume}</td>
                                  {/* <td>{el.page_nos}</td> */}
                                  <td>{el.edition}</td>
                                  <td>{el.name_of_authors}</td>
                                  <td>{el.date_year}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  ))}
              </div>
            )}
        </div>
      )}

      {active == 15 && (
        <div>
          {Array.isArray(awards) && awards != null && awards.length > 0 && (
            <div>
              <TitleStyle title={`Awards/Recognitions received by faculties`} />
              {awards != null &&
                awards.map((el1, index1) => (
                  <div key={index1} style={{ marginBottom: 10 }}>
                    {el1.data != null && el1.data.length > 0 && (
                      <table
                        width="100%"
                        className="table table-sm table-bordered"
                      >
                        <thead>
                          <tr>
                            <td colSpan="9">
                              {el1.name}, {el1.designation}
                            </td>
                          </tr>
                          <tr>
                            <td>#</td>
                            <td>Name of the Award</td>
                            <td>Given By</td>
                            <td>Year</td>
                            <td>Other Information</td>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(el1.data.filter(el => getQuarterList().includes(el.quarter))) &&
                            el1.data.filter(el => getQuarterList().includes(el.quarter)).map((el, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td> {el.area}
                                <br />
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}><a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a></div>)}</td>
                                <td>{el.achievement}</td>
                                <td>{el.award}</td>
                                <td>{el.other_info}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      )}


{active == 14 && (
        <div>
          {Array.isArray(workshop) &&
            workshop != null &&
            workshop.length > 0 && (
              <div>
                <TitleStyle title={`Orientation programme/Refresher Course/Short term course by Faculties`} />
                {workshop != null &&
                  workshop.map((el1, index1) => (
                    <div key={index1} style={{ marginBottom: 10 }}>
                      {el1.data != null && el1.data.length > 0 && (
                        <table
                          width="100%"
                          className="table table-sm table-bordered"
                        >
                          <thead>
                            <tr>
                              <td colSpan="9">
                                {el1.name}, {el1.designation}
                              </td>
                            </tr>
                            <tr>
                            <td>#</td>
              <td>Date & Year</td>
              <td>Organized by</td>
              <td>Title</td>
              <td>Duration</td>

              <td>Sponsored by</td>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(el1.data.filter(el => getQuarterList().includes(el.quarter))) &&
                              el1.data.filter(el => getQuarterList().includes(el.quarter)).map((el, index) => (
                                <tr key={index}>
                                 <td>{index+1}</td>
                <td>{el.date_year}
                </td>
                <td>{el.organised_by}</td>
                <td>{el.theme}
                <br />
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}><a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{color: 'blue', fontSize: 10}}><LinkOutlined /> Attachment {index1+1}</a></div>)}
                  </td>
                <td>{el.duration}</td>
                <td>{el.sponsored_by}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  ))}
              </div>
            )}
        </div>
      )}
    </div>
  )
}
