import React from "react";
import { Navbar } from "reactstrap";
import "./PanduNav.css";
import SubNav3 from "./SubNav3";
import { NavLink, MDBBtn } from "mdbreact";
import { Link } from "react-router-dom";
import { Typography, Grid, Button, Container } from "@material-ui/core";
import Header1 from "./Header";
import { Layout, Divider } from "antd";
const { Header } = Layout;

export default class PanduNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      headerHidden: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // handleScroll = () => {
  //   if (window.scrollY < 150) {
  //     this.setState({
  //       sticky: false
  //     })
  //   } else if (window.scrollY > 150) {
  //     this.setState({
  //       sticky: true
  //     })
  //   }
  // }

  handleScroll = () => {
    if (window.scrollY < 150) {
      this.setState({
        sticky: false,
        headerHidden: false,
      });
    } else if (window.scrollY > 150) {
      this.setState({
        sticky: true,
        headerHidden: true,
      });
    }
  };

  render() {
    return (
      <div>
        <Header1 />
        <Layout className="main-header-class">
          <Header
            style={{
              backgroundColor: "white",
              display: "flex",
              flexDirection: "row",
              minHeight: 150,
              marginTop: 40,
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                display: "flex",
                flexDirection: "row",
                minHeight: 150,
                alignItems: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={1}
                  lg={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flexBasis: "100%" }}>
                    <a href="/">
                      <img
                        className="logo-img"
                        src={"/images/prag-logo-n.png"}
                        style={{ width: "100%" }}
                        alt="logo"
                      />
                    </a>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={9}
                  sm={9}
                  md={5}
                  lg={5}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <a href="/">
                      <img
                        className="logo-img"
                        src={"/images/prag-text.png"}
                        style={{ width: "100%" }}
                        alt="logo"
                      />
                    </a>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={7}
                  sm={7}
                  md={3}
                  lg={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    
                    className="iso-text"
                  >
                  Accredited by NAAC since 2004 (3rd Cycle)<br />
                  Recognised under sections 2(f) and 12(B) of UGC<br />
                  Affiliated to Gauhati University<br />
                  ISO 9001:2015 Certified<br />
                  SDG Accord Certified

                   
                  </div>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="logo-img-container">
                    <img
                      src={"/images/ISO-Logo.jpg"}
                      style={{ width: "100%" }}
                      alt="iso-logo"
                      className="logo-img-1"
                    />

<img
                      src={"/images/sdg-logo.png"}
                      style={{ width: "100%" }}
                      alt="naac-logo"
                      className="logo-img-1 mobile-none"
                    />

                    <img
                      src={"/images/pc_naac_logo.png"}
                      style={{ width: "100%" }}
                      alt="naac-logo"
                      className="logo-img-1"
                    />

                   
                  </div>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  md={1}
                  lg={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      
                      textAlign: "center",
                    }}
                  >
                    <a target="_blank" href="/iqac/about">
                    <img
                      src={"/images/iqac-logo-pc.png"}
                      style={{ width: "60%" }}
                      alt="iso-logo"
                      className="logo-img-11"
                    />

                    
                      <div className="new-alert-text">VISIT IQAC</div>
                    </a>
                    
                  </div>
                </Grid>
              </Grid>

              {/* <div style={{flexBasis: "30%", lineHeight: 1}}>
           
              

               {!this.state.headerHidden && <SubNav3
                // sticky={this.state.sticky}
                width={`200px`}
                color={`#1E3760`}
                backgroundOfNav={`white`}
                backgroundColor={`#1E3760`}
                textColor={`white`}
                history={this.props.history}
              />}
            </div> */}
            </div>
          </Header>
        </Layout>

        <div style={{ backgroundColor: "#D6DBDF" }}>
          <SubNav3
            // sticky={this.state.sticky}
            width={`200px`}
            color={`#1E3760`}
            backgroundOfNav={`white`}
            backgroundColor={`#D6DBDF`}
            textColor={`white`}
            history={this.props.history}
          />
        </div>

        {/* {!this.state.headerHidden && <SubNav3
          // sticky={this.state.sticky}
          width={`200px`}
          color={`#1E3760`}
          backgroundOfNav={`white`}
          backgroundColor={`#1E3760`}
          textColor={`white`}
          history={this.props.history}
        />} */}
        {/* <div className="m-none">
          <Navbar expand="md"
          // style={{position:'fixed', width:'100%', top:'0', left:'0', zIndex:'1'}} 
          //className={`main-nav-class ${this.state.sticky ? 'sticky' : ''}`}
          >
            <Container className="main-nav-class">
              <div style={{ flexBasis: '30%' }}><a href="/">
                <img
                  className="logo-img"
                  src={
                    "/images/prag-logo-new.png"
                  }
                  style={{ width: 250 }}
                  alt="logo"
                />
              </a>
              </div>

              <div
                className="navbar-div-text"
                style={{
                  flexBasis: "auto",
                  margin: "15px",
                  padding: "0 10px",
                  borderRight: "3px solid #251d25",
                }}
              >
                <Typography className="nav-bar-div-text-h" variant="h3" style={{ fontWeight: 900 }}>
                {this.props.data != undefined ? <span>{this.props.data.dept_name_as}</span> : 'PRAGJYOTISH COLLEGE'}


              </Typography>
              <Typography className="nav-bar-div-text-h" variant="h3" style={{ fontWeight: 500 }} gutterBottom>
                {this.props.data != undefined ? <span>{this.props.data.dept_name}</span> : <img src="/images/tnctext.png" style={{ width: '8em' }} alt="tnc" />}
              </Typography>
              <Typography variant="h6" style={{ fontWeight: 500, fontSize: 12 }}>
                Recently accrediated by NAAC with B Grade (3rd Cycle)<br />under Revised Accreditation Framework (RAF)
              </Typography>

              </div>
              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "top", height: "100%", flexBasis: '70%' }}>
                {!this.state.headerHidden && <SubNav3
                  // sticky={this.state.sticky}
                  width={`200px`}
                  color={`#1E3760`}
                  backgroundOfNav={`white`}
                  backgroundColor={`#1E3760`}
                  textColor={`white`}
                  history={this.props.history}
                />}
                <div>
              <h6 className="mrinh6">KARBI ANGLONG -782441 , Assam, India.</h6>
              <h6 className="mrinh6">+91 6026020188</h6>
              <h6 className="mrinh6">principaltnc@gmail.com</h6>
            </div>
              </div>
              <br />
            </Container>
          </Navbar>


        </div> */}

        {/* for mobile */}
        {/* <div className="m-display">
          <Navbar expand="md" className="main-nav-class m-display">
            <a href="/">
              <img
                className="logo-img"
                src={
                  "/images/tnclogo.png"
                }
                alt="logo"
              />
            </a>
            <div
              className="navbar-div-text"
              style={{
                flexBasis: "auto",
                margin: "15px",
                padding: "0 10px",
              }}
            >
              <Typography className="nav-bar-div-text-h" variant="h1">
                {this.props.data != undefined ? <span>{this.props.data.dept_name_as}</span> : 'THONG NOKBE COLLEGE'}
              </Typography>
              <Typography className="nav-bar-div-text-h" variant="h4">
                {this.props.data != undefined ? <span>{this.props.data.dept_name}</span> : <img src="/images/tnctext.png" style={{ width: '6em' }} alt="" />}
              </Typography>
            </div>
          </Navbar>
        </div> */}
      </div>
    );
  }
}
