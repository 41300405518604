export const getQuarterList = () => {
  const d = new Date();
  const year = d.getFullYear();

  let years = [];

  for (let i = year; i >= 1996; i--) {
    years.push(i);
  }

  let y = [];
  years.map((el) => {
    y.push(`${el}-1st`);
    y.push(`${el}-2nd`);
    y.push(`${el}-3rd`);
    y.push(`${el}-4th`);
  });

  return y;
};
