import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";
import NccFacilityDescription from "./NccFacilityDescription";



class NccFacility extends Component {
  state = {
    link: "",
  };
  componentDidMount() {
    let link = this.props.match.params.link;
    this.setState({
      link: link,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.link != this.props.match.params.link) {
      let link = this.props.match.params.link;
      this.setState({
        link: link,
      });
    }
  }

  render() {
    return (
      <div>
        

        <NccFacilityDescription type={this.state.link} {...this.props} />
      </div>
    );
  }
}

export default NccFacility;
